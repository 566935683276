import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Tooltip, useMediaQuery, Theme, Menu, ListItemIcon } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Switch, FormControlLabel } from '@mui/material';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQueryClient } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AutomationLogo from '../../assets/automation-icon.svg';
import CreateProPayIcon from '../../assets/create-propay-icon.svg';
import { PageAlertContextProvider } from '../../components/page-alerts/PageAlerts';
import { handleDeleteAutomation, handleErrorAndSuccessToast, handleStartAutomation } from '../../layout/AutomationListActions';
import MultiSelectInputWithInfiniteScroll from '../../layout/CustomMultiSelectInputWithInfiniteScroll';
import SelectInputWithInfiniteScroll from '../../layout/SelectInputWithInfiniteScroll';
import {
    ALERT_ERROR_AUTOMATION,
    AUTO_SAVE_ALERT,
    DESCENDING_ARROW,
    FILLED_PLUS_ICON,
    SELECT_JOB_ARROW
} from '../../utils/Constants/ClassName';
import { capitalizeFirstLetter } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { post } from '../onboard/PaymentProvider';
import AutomationCreateSelectionModal from './AutomationCreateSelectionModal';
import AutomationCreateView, { GroupPropayAutomationCreateView } from './AutomationCreateView';
import AutomationEditView, { GrouppropayAutomationEditView } from './AutomationEditView';
import AutomationList from './AutomationList';
import {
    branchesOptions,
    createPropaysForOptions,
    devideBonusByOptions,
    distributeBudgetOptions,
    divisionsOptions,
    increaseDecreaseOptions,
    invoiceTypeOptions,
    isAndIsNotOptions,
    jobStateOptions,
    propayBudgetOption,
    specificJobOptions
} from './SelectConstatntOptions';
import { SingleSelect } from './SingleSelect';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { useIdentityContext } from '../../components/identity';
import { Helmet } from 'react-helmet-async';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: theme.palette.mode === 'light' ? '#FC6E45' : '#FC6E45',
    },
}));

const Automations = () => {
    const navigate = useNavigate();
    const identity = useIdentityContext();
    const [open, setOpen] = React.useState(false);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const OpenCreateAutomation = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    React.useEffect(()=>{
        if(identity?.company && (!identity?.company?.is_integrated)){
            navigate('/')
        }
    }, [identity?.company?.is_integrated])

    return (
        <>
        <ToastContainer/>
            <>
            {!isSmall ?
                <div className="automation-head">
                    <Typography variant="h4">Automations</Typography>
                    <Button
                        className="create-automation-btn"
                        onClick={() => handleClickOpen()}
                    >
                        <img src={AutomationLogo} alt="Create Automation" />
                        Create Automation
                    </Button>
                    {open && <AutomationCreateSelectionModal open={open} handleClose={handleClose}/>}
                </div>
                :
                <div className="automation-head am-mobile-head">
                    <Typography variant="h4">Automations</Typography>
                <div className='on-list-dropdown card-dropdown'>
                    <IconButton
                    className="am-hd-menu"
                    size="small"
                    sx={{ ml: 2 }}
                    id="basic-button"
                    aria-controls={OpenCreateAutomation ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={OpenCreateAutomation ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <DropdownIcon />
                </IconButton>
                </div>

    <Menu
        id="basic-menu"
        className="bonus-action-menu team-menu"
        open={OpenCreateAutomation}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
        }}
        transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
        }}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
        }}
    >
        {
            <>
            <MenuItem onClick={() => handleClickOpen()} className='create-propay-item'>
            {FILLED_PLUS_ICON()}
            Create Automation
            </MenuItem>
            </>
        }
    </Menu>
    {open && <AutomationCreateSelectionModal open={open} handleClose={handleClose}/>}
                </div>
                }

                <PageAlertContextProvider>
                    <AutomationList />
                </PageAlertContextProvider>
            </>
            <Routes>
                <Route path="/create" element={<AutomationCreateView />} />
                <Route path="/create/group" element={<GroupPropayAutomationCreateView />} />
                <Route path="/:id/edit/group" element={<GrouppropayAutomationEditView />} />
                <Route path="/:id/edit" element={<AutomationEditView />} />
            </Routes>
            <Helmet>
                <title>Automations</title>
            </Helmet>
        </>
    );
};
export default Automations;

interface ConnectorWithData {
    id: string;
    name: string;
    service: string;
    status: string;
    support_options: {
        budget_type_options: string[];
    };
}

const keysInRefOfbackend = {
    title: 'title',
    jobType: 'propay_for',
    jobState: 'job_type_ids',
    filters:'filters',
    jobStateType: 'job_selection_option',
    crewMembers: '',
    crewLead: 'crew_lead_id',
    jobCondition: '',
    jobFromConnector: 'connection_id',
    automationStartDate: 'filter_date',
    includeMilestones: 'is_import_milestones_enabled',
    dateCondition: '',
    budgetCondition: '',
    propayBudgetOption: 'budget_type',
    propayBudgetCalc: '',
    propayBudgetPercentage: 'budget_percent',
    propayDefaulManagerselectFrom: 'select_manager_option',
    propayDefaultManager: 'manager_id',
    thatIs: '',
    increaseDecrease: 'budget_operator',
    dvideBonusBy: 'divide_bonus_by',
    dvideBonusFormat: 'distribute_bonus_rate',
    propayTitle: '',

}

const initialCreateAutomatioFields = {
    title: '',
    jobType: '',
    jobState: [],
    jobStateType:'job_type',
    filters:'[]',
    crewMembers: '',
    crewLead: '',
    jobCondition: '',
    jobFromConnector: '',
    automationStartDate: '',
    includeMilestones: '',
    dateCondition: '',
    budgetCondition: '',
    propayBudgetOption: '',
    propayBudgetCalc: '',
    propayBudgetPercentage: '',
    propayDefaulManagerselectFrom: 'selected_from_user_list',
    propayDefaultManager: '',
    thatIs: '',
    increaseDecrease: '',
    dvideBonusBy: '',
    dvideBonusFormat: '',
    propayTitle: '',
}

const conditionFilter = [
    {
        "job_selection_option": "job_type",
        "value": []
    },
    {
        "job_selection_option": "invoice_type",
        "value": []
    },
    {
        "job_selection_option": "specific_jobs",
        "value": []
    },
    {
        "job_selection_option": "branches",
        "value": []
    },
    {
        "job_selection_option": "divisions",
        "value": []
    }
]

const filterItemsByValue = (obj) => {
    const filteredItems = {};
    Object.keys(obj).forEach(key => {
        if (obj[key]) {
            filteredItems[keysInRefOfbackend[key]] = (keysInRefOfbackend[key] === 'job_create_date' || keysInRefOfbackend[key] === 'filter_date' || keysInRefOfbackend[key] === 'first_check_in_date') ? moment(obj[key]).format('YYYY-MM-DD') : obj[key];
        }
    });
    return filteredItems;
}

export const selectedManagerVal = {
    label: '',
    value: 0,
    status: '',
    class: 'rsuite-menu-parent',
}


export const AutomationForm = props => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const identity = useIdentityContext();
    const [formFields, setFormFields] = React.useState<any>(initialCreateAutomatioFields);
    const [automationId, setAutomationId] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState({errorText:'', errorFound:1});
    const [automationNameError, setAutomationNameError] = React.useState('');
    const [connectorList,setConnectorList]= React.useState([]);
    const [automationNameRequired,setAutomationNameRequired]= React.useState(false);
    const [managerAndLead,setManagerAndLead]= React.useState({manager:selectedManagerVal, crewLead:selectedManagerVal});
    const [connectorCheck, setConnectorCheck]= React.useState(false);
    const [crewleadEmployeeExits, setCrewleadEmployeeExist] = React.useState([]);
    const [filterConditions, setFiltersCondiition] = React.useState(conditionFilter);

    const handleCrewLeadEmployeeListApiCall = async () => {
        const payloadData = { limit: 1, skip: 0 }
        const res = (await post(`/api/${formFields?.jobFromConnector}/employees`, payloadData)) as any;
        if (res?.status === 'success') {
            setCrewleadEmployeeExist(res?.employees);
        } else {
            setCrewleadEmployeeExist([]);
        }
    };

    React.useEffect(()=>{
        if(formFields.jobFromConnector && connectorList?.length){
            const connectorData = connectorList?.filter((item)=> item?.id === formFields?.jobFromConnector)[0];
            setConnectorCheck(connectorData?.support_options?.import_entities?.includes('job_type'));
        }
    },[formFields.jobFromConnector, connectorList?.length, connectorList]);

    React.useEffect(()=>{
        if(formFields.jobFromConnector){
            handleCrewLeadEmployeeListApiCall();
        }
    },[formFields.jobFromConnector]);

    React.useEffect(()=>{
        if(identity?.company?.is_integrated){
            const getConnection = async()=>{
                const data= {
                    jsonrpc: '2.0',
                    params: {},
                }
                const res = (await post('/api/protiv/connections',data)) as any;
                if (res?.length > NUMBER.ZERO) {
                    const modifiedResponse = res.map((item)=>({...item, name: item?.name ? item?.name : capitalizeFirstLetter(item?.service)}))
                    setConnectorList(modifiedResponse);
                    if(res?.length === 1 && _.isEmpty(props?.record)){
                        setAutomationNameRequired(true);
                        setFormFields((prev)=>({...prev, jobFromConnector:res[0]?.id}));
                    }
                } else {
                    handleErrorAndSuccessToast('error', 'Unable to fetch connector list.')
                }
            }
            getConnection();
        }
    },[identity?.company?.is_integrated]);

    React.useEffect(() => {
        if (!_.isEmpty(props?.record)) {
            const { record } = props;
            const updatedFormFields = {automationStartDate:''};
            for (const key in keysInRefOfbackend) {
                const backendKey = keysInRefOfbackend[key];
                updatedFormFields[key] = props.record[backendKey] && props.record[backendKey] !== 'false' ? props.record[backendKey] : '';
            }
            setManagerAndLead({manager:{label:record.manager_id_obj?.display_name, value:record.manager_id, status:record.manager_id_obj?.status, class: 'rsuite-menu-parent'},
                crewLead:{label:record.crew_lead_id_obj?.display_name, value:record.crew_lead_id, status:record.crew_lead_id_obj?.status, class: 'rsuite-menu-parent'}});
            setAutomationId(props.record.uuid);
            let cleanedString = record?.filters !== 'false' ? record?.filters : conditionFilter;
            setFiltersCondiition(JSON.parse(cleanedString));
            setFormFields(prev => ({ ...prev, ...updatedFormFields,
                automationStartDate: updatedFormFields?.automationStartDate ? new Date(updatedFormFields?.automationStartDate) : updatedFormFields?.automationStartDate}));
        }
    }, [props?.record]);

    const handleChangeFormFields = async (fieldName, value) => {
        const updateFormAndCallAPI = (updates) => {
            setFormFields(previous => ({ ...previous, ...updates }));
            debouncedApiCallWithBulkData(filterItemsByValue(updates));
        };

        switch (fieldName) {
            case 'jobType':
                const connectorData = formFields.jobFromConnector && connectorList?.filter((item)=> item.id === formFields.jobFromConnector)[0];
                const connectorChecknew = connectorData && connectorData?.support_options?.import_entities?.includes('job_type');
                if (value === 'all_jobs') {
                    setFormFields(previous => ({ ...previous, crewLead: '', jobState:[], jobStateType:'job_type' }));
                }
                if(value === 'crew_leads'){
                    setFormFields(previous => ({ ...previous, jobState:[], jobStateType:'job_type' }));
                    handleChangeConditionFilters('branches', []);
                    setTimeout(()=>handleChangeConditionFilters('divisions', []), 300);
                }
                if((value === 'all_jobs' || value === 'crew_leads') && connectorChecknew){
                    setTimeout(()=>debouncedApiCall('jobStateType', 'job_type'),200);
                }
                setTimeout( ()=> handleAutomationExist(fieldName, value), 300);
                break;
            case 'dvideBonusBy':
                if (value === 'set_percentage') {
                    updateFormAndCallAPI({ [fieldName]: value, dvideBonusFormat: '' });
                }else{
                    handleAutomationExist(fieldName, value);
                }
                break;
            case 'increaseDecrease':
                if (value === 'keep_the_same') {
                    setFormFields(previous => ({ ...previous, propayBudgetPercentage: '' }));
                    handleAutomationExist(fieldName, value);
                }else{
                    handleAutomationExist(fieldName, value);
                }
                break;
            case 'title':
                if (value && automationNameRequired) {
                    const updatedData = { ...filterItemsByValue(formFields), [fieldName]: value };
                    const resp = await handleCreateAutomationApiCall({...updatedData, filters:conditionFilter});
                    handleApiResponse(resp);
                }else{
                    handleAutomationExist(fieldName, value);
                }
                break;
            default:
                handleAutomationExist(fieldName, value);
                break;
        }
    };

    const handleChangeConditionFilters = async (fieldName, value) => {
        setFiltersCondiition(previousFilters => {
            const updatedFilters = [...previousFilters];
            const filterToUpdate = updatedFilters.find(filter => filter?.job_selection_option === fieldName);
            if (filterToUpdate) {
                filterToUpdate.value = value;
            } else {
                updatedFilters.push({ job_selection_option: fieldName, value });
            }
            debouncedApiCall('filters', updatedFilters);
            return updatedFilters;
        });
    };

    const handleAutomationExist = (fieldName, value) => {
        if(fieldName === 'propayBudgetOption' && value === 'revenue'){
            setFormFields(previous => ({ ...previous, [fieldName]: value, increaseDecrease: 'multiply' }));
            setTimeout(()=>debouncedApiCall('increaseDecrease', 'multiply'),500);
            debouncedApiCall(fieldName, value);
        }else{
            setFormFields(previous => ({ ...previous, [fieldName]: value }));
            debouncedApiCall(fieldName, value);
        }
    }

    const debouncedApiCall = _.debounce(async (fieldName, value) => {
        const dateFields = ['automationStartDate']
        const conditionCheck = dateFields?.includes(fieldName) && value;
        try {
            let resp;
            if (automationId) {
                const formattedvalue = conditionCheck ? moment(value).format('YYYY-MM-DD') : value
                resp = await handleUpdateAutomationApiCall({ [keysInRefOfbackend[fieldName]]: formattedvalue }, automationId);
            } else {
                const formattedvalue = conditionCheck ? moment(value).format('YYYY-MM-DD') : value
                resp = await handleCreateAutomationApiCall({ [keysInRefOfbackend[fieldName]]: formattedvalue });
            }
            handleApiResponse(resp);
        } catch (error) {
            console.error("Error occurred during API call:", error);
        }
    }, NUMBER.HUNDRED);

    const debouncedApiCallWithBulkData = async(data) => {
        try {
            let resp;
            if (automationId) {
                resp = await handleUpdateAutomationApiCall(data, automationId);
            } else {
                resp = await handleCreateAutomationApiCall(data);
            }
            handleApiResponse(resp);
        } catch (error) {
            console.error("Error occurred during API call:", error);
        }
    };

    const handleApiResponse = (resp) => {
        if (resp?.status === 'success') {
            setAutomationId(resp.id);
            setAutomationNameRequired(false);
            setErrorMessage({errorFound:0, errorText:''});
        } else {
            setAutomationId(automationId);
            setErrorMessage({errorFound:1, errorText:resp?.error});
            setAutomationNameError(resp?.error)
            if(resp?.error === 'Title is required'){
                setAutomationNameRequired(true);
            }
        }
        setTimeout(() => setErrorMessage({errorFound:1, errorText:''}), NUMBER.THREE_THOUSAND);
    };

    const handleStartLater = () => {
        navigate('/propayAutomations');
        queryClient.invalidateQueries(['propayAutomations', 'getList']);
    };
    const connectorDetail: ConnectorWithData[] = connectorList?.length && formFields?.jobFromConnector ?
    connectorList.filter(item => item.id === formFields?.jobFromConnector) :
    [{ id: "", name: "", service: "", status: "", support_options: { budget_type_options: [] } }];

    const visibleFields = {
        title: true,
        jobType: true,
        jobState: formFields.jobType === 'job_type',
        jobStateType:false,
        crewMembers: formFields.jobType === 'crew',
        crewLead: formFields.jobType === 'crew_leads',
        jobFromConnector: connectorList?.length > NUMBER.ONE,
        automationStartDate: true,
        dateCondition: false,
        propayBudgetOption: (connectorDetail[0]?.support_options?.budget_type_options?.length),
        propayBudgetPercentage: (connectorDetail[0]?.support_options?.budget_type_options?.length && formFields?.increaseDecrease !== 'keep_the_same'),
        propayDefaulManagerselectFrom: false,
        propayDefaultManager: true,
        increaseDecrease: (connectorDetail[0]?.support_options?.budget_type_options?.length && formFields?.propayBudgetOption !== 'revenue'),
        dvideBonusBy: true,
        dvideBonusFormat: formFields.dvideBonusBy === 'hours_worked',
        propayTitle: false,
    };

    const totalVisibleFields = Object.values(visibleFields).filter(visible => visible).length;

    const calculateProgress = () => {
        const filledVisibleFields = Object.entries(formFields).filter(([key, value]) => {
            return Array.isArray(value) ? visibleFields[key] && value.length > 0 : visibleFields[key] && value;
        }
        ).length;
        return Math.round((filledVisibleFields / totalVisibleFields) * NUMBER.HUNDRED);
    };

    // TODO - Need to change the approach here.
      const handleChangeConnector = (fieldName, value) => {
        const connectorData = value && connectorList?.filter((item)=> item.id === value)[0];
        const connectorCheckforForm = connectorData && connectorData?.support_options?.import_entities?.includes('job_type');
        const handleCheckDivisions = connectorData && connectorData?.support_options?.import_entities?.includes('branches');
        const handleCheckBranches = connectorData && connectorData?.support_options?.import_entities?.includes('divisions');

        if(!handleCheckDivisions){
            setTimeout(()=>handleChangeConditionFilters('branches', []), 380)
        }

        if(!handleCheckBranches){
            setTimeout(()=>handleChangeConditionFilters('divisions', []), 280)
        }

        setTimeout(() => checkBudgetType(value),740);
        handleChangeFormFields(fieldName, value);
        if(!connectorCheckforForm){
            setTimeout(() => handleChangeFormFields('jobState', []),340);
            setTimeout(() => handleChangeFormFields('jobType', ''),500);
            handleChangeConditionFilters('job_type', []);
            setTimeout(()=>handleChangeConditionFilters('invoice_type', []), 200)
        }else{
            setTimeout(() => handleChangeFormFields('jobStateType', 'job_type'),350);
        }
      }
      const checkBudgetType = (value) => {
        const connectorData = connectorList?.filter((item)=>item?.id === value);
        if(connectorData?.length){
            const connector = connectorData[0];
            if(formFields?.propayBudgetOption && !connector?.support_options.budget_type_options?.includes(formFields?.propayBudgetOption)){
                setTimeout(()=>handleChangeFormFields('propayBudgetOption', ''), 100);
            }
        }
      }

    const createPropayForOptions = () => {
        const jobtypeFilter = connectorCheck ? createPropaysForOptions : createPropaysForOptions.filter((item)=>item.value !== 'job_type');
        const crewLeadFilter = crewleadEmployeeExits?.length ? jobtypeFilter : jobtypeFilter.filter((item)=>item.value !== 'crew_leads');
        return crewLeadFilter
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={() => handleStartLater()}
                aria-labelledby="create-propay-dialog-title"
                aria-describedby="create-propay-dialog-description"
                className="create-propay-modal"
            >
                <ToastContainer/>
                <DialogTitle id="group-propay-dialog-title">
                    <Stack
                        className="create-propay-automation-title"
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <span className='span-automation-title'>
                            {props?.content?.title}</span>

                        <div className='automation-right-head'>
                            {errorMessage.errorFound === 0 && !errorMessage?.errorText && <div className="auto-saved-alert">
                                {AUTO_SAVE_ALERT()}
                                Autosaved just now!
                            </div>}
                            {errorMessage.errorFound === 1 && errorMessage?.errorText &&
                                <div className="auto-saved-alert not-auto-saved">
                                    {AUTO_SAVE_ALERT()}
                                    Not Autosaved : {errorMessage?.errorText === "Title is required" ? 'Automation name is required.' : errorMessage?.errorText}
                                </div>}
                            <IconButton
                                sx={{ top: -2, padding: 0 }}
                                className="automation-modal-close"
                                color="primary"
                                aria-label="automation modal"
                                onClick={() => handleStartLater()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Stack>
                    <Box
                            className="automation-progress"
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <BorderLinearProgress
                                variant="determinate"
                                value={calculateProgress()}
                            />
                            <div className="active-progress-value">
                                Progress: <div className='no-translate pg-value-inr'>{calculateProgress()}%</div>
                            </div>
                    </Box>
                </DialogTitle>
                <DialogContent>

                        <div className="group-propay-head create-propay-automation-head">
                            <img src={CreateProPayIcon} alt="Create ProPay" />{' '}
                            Create ProPay
                        </div>

                        <div className={`${automationNameError === "Title is required" ?  'automation-write-name automation-alert-name' : 'automation-write-name'}`}>
                            <div className="create-propay-sub-head">
                                Automation name
                            </div>
                            <TextField
                                id="automation-name"
                                fullWidth
                                value={formFields.title}
                                error={automationNameError === "Title is required"}
                                helperText={automationNameError === "Title is required" ? 'Add an automation name' : ''}
                                onBlur={() => {
                                    setAutomationNameError(formFields.title ? '' : 'Title is required.');
                                    handleChangeFormFields('title', formFields.title);
                                }
                                }
                                onChange={(e) => {
                                    const formattedValue = e.target.value.replace(/^\s+/, '');
                                    setAutomationNameError(formattedValue ? '' : 'Title is required.');
                                    setFormFields(previous => ({
                                        ...previous,
                                        'title': formattedValue
                                    }));
                                }}
                                placeholder="Write name *"
                                variant="standard"
                            />
                        </div>

                        <div className="create-propay-sub-head">
                            Select jobs to:
                        </div>
                    <div className="automation-job-to-row">
                        Create ProPays &nbsp;
                        {connectorList?.length > 1 ? <>
                            <span className="am-text-space">for connector</span>
                            <SingleSelect
                                options={connectorList}
                                handleChange={handleChangeConnector}
                                value={formFields.jobFromConnector}
                                fieldName={'jobFromConnector'}
                            /></> : <></>}
                        {formFields.jobFromConnector && <>
                            <span className="create-propays-for am-text-space">
                                for
                            </span>
                            <SingleSelect
                                options={createPropayForOptions()}
                                handleChange={handleChangeFormFields}
                                value={formFields.jobType}
                                fieldName={'jobType'}
                            />
                            {formFields.jobType === 'crew' && (
                                <MultiSelect
                                    // options={crewMembersOptions}
                                    options={[]}
                                    handleChange={handleChangeFormFields}
                                    value={formFields.crewMembers}
                                    fieldName={'crewMembers'}
                                />
                            )}
                            {formFields.jobType === 'crew_leads' && (
                                <SelectInputWithInfiniteScroll hideLabel={true} useControllerApiCall={true} connectionId={formFields?.jobFromConnector} selectedUser={managerAndLead.crewLead} userTypeFilter={['worker', 'manager', 'admin']} handleChangeFormFields={handleChangeFormFields} selectedValue={formFields?.crewLead} setPrevious={true} fieldName={'crewLead'} />
                            )}
                            {connectorCheck && formFields?.jobType && formFields?.jobType === 'job_type' ? <>
                                <span className="am-text-space">that is</span>
                                <MultiSelectInputWithInfiniteScroll resource={'JobType'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} handleChangeFormFields={handleChangeFormFields} selectedValue={props?.record?.job_type_ids} val={formFields?.jobState} setPrevious={true} fieldName={'jobState'} />
                            </> : <></>}
                        </>}
                    </div>

                        {filterConditions.map((item)=>conditionHtml(props, item,connectorList, formFields,handleChangeConditionFilters))}

                        {/* Decending arrow icon html */}
                        <DecendingArrowHtml />

                        <div className="create-propay-sub-head">
                            The automation should start
                        </div>
                        <div className="automation-job-to-row automation-date-picker">
                            <div className="am-text-space">
                                Creating ProPays on or after
                            </div>
                            <CustomDatePicker
                                handleChange={handleChangeFormFields}
                                value={formFields.automationStartDate}
                                fieldName={'automationStartDate'}
                            />
                        </div>
                        <div className="labour-budget-notification info-budget-notification">
                            {ALERT_ERROR_AUTOMATION()}
                            Date set for scheduled visits, clock-in, and new jobs
                        </div>

                        {/* Decending arrow icon html */}
                        <DecendingArrowHtml />

                        <div className={`${connectorDetail?.length && connectorDetail[0]?.support_options?.budget_type_options?.length ? '' : 'disabled-sub-head'} create-propay-sub-head`}>
                            How should we calculate the ProPay budget?
                        </div>

                        <div className={`${connectorDetail?.length && connectorDetail[0]?.support_options?.budget_type_options?.length ? '' : 'automation-job-disabled'} automation-job-to-row`}>
                            <span className="am-text-space">
                                Create budget by using the job
                            </span>
                            <BudgetSelectDropdown
                                connector={connectorList?.filter((con)=>con?.id === formFields?.jobFromConnector)}
                                options={propayBudgetOption}
                                handleChange={handleChangeFormFields}
                                value={formFields.propayBudgetOption}
                                fieldName={'propayBudgetOption'}
                            />

                            {formFields.propayBudgetOption !== 'revenue' && formFields.propayBudgetOption && (
                                <>
                                    <span className="am-text-space">and</span>
                                    <SingleSelect
                                        options={increaseDecreaseOptions}
                                        handleChange={handleChangeFormFields}
                                        value={formFields.increaseDecrease}
                                        fieldName={'increaseDecrease'}
                                    />
                                    {(formFields.increaseDecrease !== 'multiply') && formFields.increaseDecrease !== 'keep_the_same' &&
                                        <><span className="am-text-space">by</span>
                                            <CustomPercentInput handleChangeFormFields={handleChangeFormFields} value={formFields.propayBudgetPercentage} fieldName={'propayBudgetPercentage'} setFormFields={setFormFields} />
                                        </>}
                                </>
                            )}
                            {formFields.propayBudgetOption === 'revenue' && formFields.propayBudgetOption && (
                                <>
                                    <span className="am-text-space">
                                        and multiply
                                    </span>
                                    <CustomPercentInput handleChangeFormFields={handleChangeFormFields} value={formFields.propayBudgetPercentage} fieldName={'propayBudgetPercentage'} setFormFields={setFormFields} />
                                </>
                            )}
                            {/* <span className="am-text-space">
                                for labor budget
                            </span> */}
                        </div>

                        {connectorDetail?.length && connectorDetail[0]?.support_options?.budget_type_options?.length ? <div className="labour-budget-notification">
                            {ALERT_ERROR_AUTOMATION()}
                            ProPay Budget should exclude Burden Cost
                        </div> :
                        <div className="labour-budget-notification info-budget-notification">
                            {ALERT_ERROR_AUTOMATION()}
                            Budget data not {connectorDetail?.length && connectorDetail[0]?.service ? `supported with ${connectorDetail[0]?.name ? connectorDetail[0]?.name : capitalizeFirstLetter(connectorDetail[0]?.service)}.` : 'supported.'}  Automation will still create ProPay, but manual input of budget will be required
                        </div>}
                        {identity.company.is_import_milestones_enabled && (
                            <>
                                <div className="create-propay-sub-head">
                                    Include Milestones
                                </div>
                                <div className="automation-milestones-toggle">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!formFields.includeMilestones}
                                                onChange={(e) => handleChangeFormFields('includeMilestones', e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Add milestones automatically?"
                                        labelPlacement="start"
                                    />
                              </div>
                          </>
                        )}
                        <DecendingArrowHtml />

                        <div className="create-propay-sub-head">
                            Select a Manager
                        </div>

                        <div className="automation-job-to-row">
                            <span className="am-text-space">
                                ProPay default manager should be
                            </span>
                            <SelectInputWithInfiniteScroll selectedUser={managerAndLead.manager} hideLabel={true} userTypeFilter={['manager', 'admin']} handleChangeFormFields={handleChangeFormFields} selectedValue={formFields?.propayDefaultManager} setPrevious={true} fieldName={'propayDefaultManager'} />
                        </div>

                        {/* Decending arrow icon html */}
                        <DecendingArrowHtml />

                        <div className="create-propay-sub-head">
                            Divide Bonus
                        </div>

                        <div className="automation-job-to-row">
                            <span className="am-text-space">
                                Divide Bonus by
                            </span>
                            <SingleSelect
                                options={devideBonusByOptions}
                                handleChange={handleChangeFormFields}
                                value={formFields.dvideBonusBy}
                                fieldName={'dvideBonusBy'}
                            />
                            {formFields.dvideBonusBy === 'hours_worked' && (
                                <>
                                    Distribute bonus&nbsp; <span className="am-text-space">rate</span>
                                    <SingleSelect
                                        options={distributeBudgetOptions}
                                        handleChange={handleChangeFormFields}
                                        value={formFields.dvideBonusFormat}
                                        fieldName={'dvideBonusFormat'}
                                    />
                                </>
                            )}
                        </div>
                        {formFields.dvideBonusBy === 'set_percentage' && <div className="labour-budget-notification">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12.0001 8.99993V12.7499M2.69707 16.1259C1.83107 17.6259 2.91407 19.4999 4.64507 19.4999H19.3551C21.0851 19.4999 22.1681 17.6259 21.3031 16.1259L13.9491 3.37793C13.0831 1.87793 10.9171 1.87793 10.0511 3.37793L2.69707 16.1259ZM12.0001 15.7499H12.0071V15.7579H12.0001V15.7499Z"
                                    stroke="#FC6E45"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Set percentage will require manual input of percentage for every ProPay
                        </div>}
                        {/* Decending arrow icon html */}
                        {/* <DecendingArrowHtml /> */}

                        {/* <div className="create-propay-sub-head">
                            Set ProPay Title
                        </div> */}

                        {/* <div className="automation-job-to-row automation-tag-picker-row">
                            <span className="title-should-be-nowrap">
                                Title should be
                            </span>
                            <CustomisedDraggableTagPicker options={propayTitleOptions} setSelected={setFormFields} selectedValue={formFields?.propayTitle} setPrevious={true} fieldName={'propayTitle'}/>
                        </div> */}

                </DialogContent>
                <DialogActions className="button-justified">
                    {automationId && <Button className="cancel-button" onClick={() => handleDeleteAutomation(automationId, queryClient, navigate, true)} disabled={!automationId}>Delete</Button>}
                    <div className="automation-button-right">
                        {props?.record?.status !== 'running' && <Button className="save-draft-button" onClick={() => handleStartLater()}  disabled={calculateProgress() < NUMBER.ONE}>
                            Start Later
                        </Button>}
                        {props?.record?.status !== 'running' && <Button className="update-button" onClick={() => handleStartAutomation(automationId, queryClient,navigate, true)} disabled={calculateProgress() < NUMBER.HUNDRED || !automationId} >Start Now</Button>}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

const conditionHtml = (props, optionType, connectorList, formFields,handleChangeConditionFilters) => {
    const connectorCheck = formFields.jobFromConnector && connectorList?.length ? connectorList?.filter((item)=> item?.id === formFields?.jobFromConnector)[0] : false;
    let cleanedString = props?.record?.filters && props?.record?.filters !== 'false' ? props?.record?.filters : conditionFilter;
    cleanedString = props?.record?.filters ? JSON.parse(cleanedString) : conditionFilter;

    const value = cleanedString?.filter((item)=> item?.job_selection_option === optionType?.job_selection_option)[0];
    switch (optionType?.job_selection_option) {
        case 'job_type': {
            return (
                <>
                {connectorCheck && connectorCheck?.support_options?.import_entities?.includes('job_type') && formFields?.jobType && formFields?.jobType !== 'job_type' &&  (
                    <div className="automation-job-action-row">
                        {SELECT_JOB_ARROW()}{' '}
                        <span className="color-text-orange">AND</span>{' '}
                        <span className="optional-text">
                            (optional)
                        </span>
                        <SingleSelect
                            options={jobStateOptions}
                            handleChange={handleChangeConditionFilters}
                            value={optionType?.job_selection_option}
                            fieldName={'job_type'}
                        />
                        <span className="am-text-space">that is not</span>
                        <MultiSelectInputWithInfiniteScroll resource={'JobType'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'job_type'} />
                    </div>
                )}
                </>
            );
        }
        case 'invoice_type': {
            return (
                <>
                {connectorCheck && connectorCheck?.support_options?.import_entities?.includes('invoice_type') && formFields?.jobType &&  (
                    <div className="automation-job-action-row">
                        {SELECT_JOB_ARROW()}{' '}
                        <span className="color-text-orange">AND</span>{' '}
                        <span className="optional-text">
                            (optional)
                        </span>
                        <SingleSelect
                            options={invoiceTypeOptions}
                            handleChange={handleChangeConditionFilters}
                            value={optionType?.job_selection_option}
                            fieldName={'invoice_type'}
                        />
                        <span className="am-text-space">that is not</span>
                        <MultiSelectInputWithInfiniteScroll resource={'InvoiceType'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'invoice_type'} />
                    </div>
                )}
                </>
            );
        }
        case 'specific_jobs': {
            return (
                <>
                {formFields?.jobType && (
                    <div className="automation-job-action-row">
                        {SELECT_JOB_ARROW()}{' '}
                        <span className="color-text-orange">AND</span>{' '}
                        <span className="optional-text">
                            (optional)
                        </span>
                        <SingleSelect
                            options={specificJobOptions}
                            handleChange={handleChangeConditionFilters}
                            value={optionType?.job_selection_option}
                            fieldName={'specific_jobs'}
                        />
                        <span className="am-text-space">that is not</span>
                        <MultiSelectInputWithInfiniteScroll resource={'Job__DropdownList'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'specific_jobs'} />
                    </div>
                )}
                </>
            );
        }
        case 'divisions': {
            return (
                <>
                {connectorCheck && connectorCheck?.support_options?.import_entities?.includes('divisions') && formFields?.jobType && formFields?.jobType !== 'crew_leads' && (
                    <div className="automation-job-action-row">
                        {SELECT_JOB_ARROW()}{' '}
                        <span className="color-text-orange">AND</span>{' '}
                        <span className="optional-text">
                            (optional)
                        </span>
                        <SingleSelect
                            options={divisionsOptions}
                            handleChange={handleChangeConditionFilters}
                            value={optionType?.job_selection_option}
                            fieldName={'divisions'}
                        />
                        <span className="am-text-space">that is not</span>
                        <MultiSelectInputWithInfiniteScroll resource={'Branch__DropdownList'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} filterQuery={{type:{_eq:'division'}}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'divisions'} />
                    </div>
                )}
                </>
            );
        }
        case 'branches': {
            return (
                <>
                {connectorCheck && connectorCheck?.support_options?.import_entities?.includes('branches') && formFields?.jobType && formFields?.jobType !== 'crew_leads' && (
                    <div className="automation-job-action-row">
                        {SELECT_JOB_ARROW()}{' '}
                        <span className="color-text-orange">AND</span>{' '}
                        <span className="optional-text">
                            (optional)
                        </span>
                        <SingleSelect
                            options={branchesOptions}
                            handleChange={handleChangeConditionFilters}
                            value={optionType?.job_selection_option}
                            fieldName={'branches'}
                        />
                        <span className="am-text-space">that is not</span>
                        <MultiSelectInputWithInfiniteScroll resource={'Branch__DropdownList'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} filterQuery={{type:{_eq:'branch'}}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'branches'} />
                    </div>
                )}
                </>
            );
        }
        default:
            return <></>;
    }
}

export const handleCreateAutomationApiCall = async (data, addParam?:boolean) => {
    if(!data.hasOwnProperty('select_manager_option') && !addParam){
      data = {...data,'select_manager_option':'selected_from_user_list'}
    }
    const response = (await post('/api/propay-automation', data)) as any;
    return response;
}

export const handleUpdateAutomationApiCall = async (data, automationUuid) => {
    const response = (post(`/api/propay-automation/${automationUuid}`, data)) as any;
    return response;
}
export const CustomPercentInput = ({ handleChangeFormFields, value, fieldName, setFormFields }) => {
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === "" || (/^\d*$/.test(inputValue) && parseInt(inputValue) >= 0 && parseInt(inputValue) <= 100)) {
            setFormFields(previous => ({
                ...previous,
                [fieldName]: inputValue === "" ? "" : parseInt(inputValue)
            }));
        }
    };
    return (
        <TextField
            id="budget-percent-input"
            type='number'
            value={value}
            onBlur={() => handleChangeFormFields(fieldName, value)}
            onChange={handleInputChange}
            label=""
            variant="standard"
            className="job-to-input no-translate"
            inputProps={{
                min: 0,
                max: 100
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
        />
    )
}

export const CustomDatePicker = ({ value, handleChange, fieldName }) => {
    return (
        <DatePicker
            selected={value}
            onChange={e => handleChange(fieldName, e)}
            placeholderText="Date"
            className='automation-date no-translate'
        />
    );
};

const AddConditionButton = ({ handleClick, fieldName }) => {
    return (
        <>
            <Button
                onClick={() => handleClick(fieldName, 'true')}
                className="add-condition-plus"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M10 3.75V16.25M16.25 10H3.75"
                        stroke="#0391BA"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <span className="am-text-space">Add Condition</span>
            </Button>
        </>
    );
};

const DecendingArrowHtml = () => {
    return (
        <div className="select-then-row">
            <div className="select-then-text">{DESCENDING_ARROW()} THEN</div>
        </div>
    );
};

const RemoveConditionButton = ({ handleClick, fieldName }) => {
    return (
        <>
            <Button
                className="job-bin-icon"
                onClick={() => handleClick(fieldName, '')}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                >
                    <path
                        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                        fill="#C6C6C6"
                    />
                </svg>
            </Button>
        </>
    );
};

export const BudgetSelectDropdown = ({ connector, options, value, handleChange, fieldName }) => {
    return (
        <>
            <FormControl
                variant="standard"
                className="job-to-select last-margin-select no-translate"
            >
                <Select
                    id="propay-budget-percent"
                    labelId="automation-job-select"
                    value={value}
                    onChange={event =>
                        handleChange(fieldName, event.target.value ? event.target.value :'')
                    }
                    displayEmpty
                >
                    {options?.length > 0
                        ? options.map(item => {
                            const optionValue = item?.value ? item?.value : item?.id
                            const connectorData = connector?.length ? connector[0] : {name:'',support_options:{budget_type_options:[]}}
                            return (
                                    connectorData && connectorData?.support_options?.budget_type_options?.includes(optionValue) ?
                                        <MenuItem value={item?.value ? item?.value : item?.id} >
                                            {item?.label ? item?.label : item?.name}
                                        </MenuItem>
                                         :
                                        <div className='disable-automation-menu-item'>
                                            <Tooltip id='unique-tooltip' className='unique-tooltip' title={`Budget option not supported ${connectorData && connectorData?.service ? `with ${connectorData?.name ? connectorData?.name : capitalizeFirstLetter(connectorData?.service)}` : ''}`} arrow>
                                                <span>
                                                    <Button className='disabled-tooltip' disabled>{item?.label ? item?.label : item?.name}</Button>
                                                </span>
                                            </Tooltip>
                                        </div>

                            )
                        })
                        : 'No Result Found'}
                </Select>
            </FormControl>
        </>
    );
};

const MultiSelect = ({ options, value, handleChange, fieldName }) => {
    const [touched, setTouched] = React.useState(false);
    return (
        <>
            <FormControl
                variant="standard"
                className="job-to-select last-margin-select no-translate"
            >
                <Select
                    id="propay-budget-percent"
                    labelId="automation-job-select"
                    value={value}
                    onBlur={() => setTouched(true)}
                    onChange={event =>
                        handleChange(fieldName, event.target.value)
                    }
                    displayEmpty
                >
                    {options?.length > 0
                        ? options.map(item => (
                            <MenuItem value={item?.value}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={item?.label}
                                />
                            </MenuItem>
                        ))
                        : 'No Result Found'}
                </Select>
                {/* {touched && !value?.length && <FormHelperText className='red-text'>Required</FormHelperText>} */}
            </FormControl>
        </>
    );
};

