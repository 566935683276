import { useNotify } from 'react-admin';
import { Fragment, useContext } from 'react';
import { Confirm } from '../../components/Confirm';
import { POST } from '../../services/HttpService';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { BONUS_BULK_ACTION_API, GENERAL_INFO } from '../../constants';

const BulkMarkPendingModal = ({
    isOpen,
    onClose,
    refreshData,
    selectedIds,
}) => {
    const notify = useNotify();
    const bonusContext = useContext(CustomFilterContext);
    const handleBulkMarkPending = () => {
        const data = {
            statement_ids:selectedIds
        };
        POST(BONUS_BULK_ACTION_API.mark_pending,data).then((response:any[])=>{
            bonusContext.setShowToast(true);
            bonusContext.setShowToastAction(true)
            const pendingItems = response.filter(item=>item.is_success);
            bonusContext.setCustomMessage(`${pendingItems.length}/${selectedIds.length} Statements successfully pending`);
            bonusContext.setBulkActionResponse(response);
            refreshData();   
        }).catch(error=>{
            notify(`Failure ! ${error.message}`);
        })
        onClose();
        notify(GENERAL_INFO.soonNotify);
    };
    return (
        <Fragment>
            <Confirm
                isOpen={isOpen}
                title={'Mark as pending'}
                content={
                    'Are you sure you want to mark pending selected bonus statements '
                }
                onConfirm={handleBulkMarkPending}
                onClose={onClose}
            />
        </Fragment>
    );
};
export default BulkMarkPendingModal;
