import { TEAM_ACTIONS, TEAM_BULK_ACTIONS } from '../../utils/Constants/ConstantData';
import { useEffect, useState } from 'react';
import { Button, ListItemIcon, MenuItem, TextField } from '@mui/material';
import { InputPicker } from 'rsuite';
import { useDataProvider, useListContext, useUnselectAll} from 'react-admin';
import { Confirm } from '../../components/Confirm';
import useArchiveEmployee from './useArchiveEmployee';
import useActivateEmployee from './useUnarchiveEmployee';
import { USER_ACTIVATE_ICON, USER_DISABLE_ICON } from '../../utils/Constants/ClassName';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { GENERAL_INFO, TOAST_MESSAGES } from '../../constants';
import { useSnackbar } from 'notistack';

export const DropdownBulkActions = props => {
    const { selectedIds = [], total,onToggleItem,data } = useListContext();
    const [selectedAction, setSelectedAction] = useState('');
    const {bulkAction,hideAction,setHideAction,setBulkAction,filterQuery}=props
    const unselectAll = useUnselectAll();
    const [isOpen, setIsOpen] = useState(false);
    const dataProvider = useDataProvider();
    const { archive } = useArchiveEmployee({
        selectedIds,
        onSuccess: ()=>{},
    });
    const { activate } = useActivateEmployee({
        selectedIds,
        onSuccess: ()=>{},
    });
    const [allEntriesSelected, setAllEntriesSelected] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(()=>{
        if(selectedIds.length > NUMBER.ZERO){
            setHideAction(false);
        }
        if(!selectedIds.length && !hideAction){
            setBulkAction('');
        }
        return()=>{
            setAllEntriesSelected(false)
        }
    },[selectedIds])

    const renderMenuItem = (label, item) => {
        return (
            <MenuItem className={item.class}>
                <ListItemIcon>{item.icon}</ListItemIcon> {item.label}{' '}
            </MenuItem>
        );
    };
    const handleClose = () => {
        setSelectedAction('');
        setIsOpen(false);
    };
    const handleAction = ()=>{
        unselectAll('employees');
        handleClose();
        enqueueSnackbar(
            GENERAL_INFO.soonNotify,
            { variant: 'info', autoHideDuration: TOAST_MESSAGES.autoHideDuration  }
        );
    }
    const onConfirm = (action) => {
        if (selectedAction === 'Activate') {
            activate();
            handleAction()
            return;
        }
        archive();
        handleAction()
    };
    const handleSelectAllEntry = () => {
        setAllEntriesSelected(true);  
        data.forEach(item=>{if (!selectedIds.includes(item.id)) {
            onToggleItem(item.id);
        }})
        getAllIds();
    };

    const getAllIds = async()=>{
        let {data} = await dataProvider.getList('Employee__FilterList',{pagination: { page: NUMBER.ONE, perPage: total },
            sort:{field: 'id', order: 'ASC'},filter:filterQuery});
           const ids = data.map(item=>(item.id))
           ids.forEach(id => {
            if (!selectedIds.includes(id)) {
              //  onToggleItem(id);
            }
        });
    }


    return (
        <>
            <div className="close-statement-toolbar team-toolbar-inr">
                <>
                    {allEntriesSelected ? (
                        <span>All {total} users are selected</span>
                    ) : (
                        <>
                            <span>{selectedIds.length} users are selected. </span>{' '}
                            {total > selectedIds.length && (
                                <div className='team-total-entry' onClick={() => handleSelectAllEntry()}>
                                    {' '}
                                    Select all {total} users
                                </div>
                            )}
                        </>
                    )}
                </>

            
<>
{bulkAction ? <CustomButton bulkAction={bulkAction} setShowConfirm={setIsOpen}/> : <>{selectedIds.length > 0 ?<InputPicker
                    value={selectedAction}
                    placeholder="Bulk Action"
                    data={TEAM_BULK_ACTIONS}
                    onSelect={value => {
                        setSelectedAction(value);
                        setIsOpen(true);
                    }}
                    renderMenuItem={renderMenuItem}
                    placement="autoVerticalStart"
                    className="bulk-action-rs-picker-input"
                    menuClassName="bulk-action-dropdown"
                /> :<></>}</>}
</>
                
            </div>
            <Confirm
                isOpen={isOpen}
                title={`${selectedAction} Team Member`}
                content={`Are you sure you want to ${selectedAction.toLowerCase()} ${
                    selectedIds.length
                } selected team member `}
                onConfirm={() => onConfirm(bulkAction ? bulkAction:selectedAction)}
                onClose={() => handleClose()}
            />
        </>
    );
};

// export const BulkActionButton = (props) => {
//     const {bulkAction,hideAction,setHideAction,setBulkAction}=props
//     const { selectedIds = [], total } = useListContext();
//     const [showConfirm,setShowConfirm]= useState(false)
//     const unselectAll = useUnselectAll();
//     const { archive } = useArchiveEmployee({
//         selectedIds,
//         onSuccess: unselectAll,
//     });
//     const { activate } = useActivateEmployee({
//         selectedIds,
//         onSuccess: unselectAll,
//     });

//     useEffect(()=>{
//         if(selectedIds.length > NUMBER.ZERO){
//             setHideAction(false);
//         }
//         if(!selectedIds.length && !hideAction){
//             setBulkAction('');
//         }
//     },[selectedIds])

//     const close = ()=>{
//         setShowConfirm(false)
//     }

//     const handelConfirm = ()=>{
//         if (bulkAction === 'Activate') {
//             activate();
//             return;
//         }
//         archive();
//     }
//     return (<>
//     <div className='close-statement-toolbar'>
//         <CustomButton bulkAction={bulkAction} setShowConfirm={setShowConfirm}/>
//         </div>
//         <Confirm
//                 isOpen={showConfirm}
//                 title={`${bulkAction} Team Member`}
//                 content={`Are you sure you want to ${bulkAction.toLowerCase()} ${
//                     selectedIds.length
//                 } selected team member `}
//                 onConfirm={() => handelConfirm()}
//                 onClose={() => close()}
//             />
//     </>)
// };

export const CustomButton = ({bulkAction,setShowConfirm}) => {
 
    switch(bulkAction){
        case TEAM_ACTIONS.activate:{
            return <Button className={'bulk-pending-button'} onClick={()=>setShowConfirm(true)} >{USER_ACTIVATE_ICON()} {bulkAction}</Button>
        }
        case TEAM_ACTIONS.disable:{
            return <Button className={'bulk-pending-button'} onClick={()=>setShowConfirm(true)}>{USER_DISABLE_ICON()}  {bulkAction}</Button>
        }
    }
    
}
