import { useState ,useEffect, useContext, useCallback} from "react";
import { setFilter, useDataProvider } from "react-admin"
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    DialogActions,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogTitle,
    IconButton,
    Typography,
    FormGroup,
    Switch,
    Badge
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { COMPARISON_OPTIONS,SEARCH_PLACEHOLDER,STATEMENT_FILTER_OPTION } from "../../utils/Constants/ConstantData";
import { TagPicker } from 'rsuite';
import { NUMBER } from "../../utils/Constants/MagicNumber";
import _ from 'lodash';
import { DateRangeInputFilter } from "../../components/fields/DateRangeInputFilter";
import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { Input } from "@mui/material";
import { getBonusCustomFilterQuery } from "../../components/fields";
import { VALIDATION } from "../../utils/Constants/ValidationMessages";
import { convertToFloatHours } from "../payrolls/weeklyEntries";
import { convertNumToTime } from "../../utils/formatter";
import { Form } from 'react-final-form';
import { CustomFilterContext } from "../../layout/CustomFilterContext";
import { CLOSE_ICON } from "../../utils/Constants/ClassName";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getBonusSearchQuery } from "../propays/propay_utils";
import { getFilterCount } from "../../utils/Constants/CommonFunctions";
import debounce from 'lodash/debounce';
import { useGetIdentityOptimized } from "../../components/identity";
export const BONUS_OPTIONS = [
    { id: 'pending', name: 'Open' },
    { id: 'paid', name: 'Closed' },
];
const MobileViewFiter = ({setBonusFilter,bonusFilter})=>{
    const [open,setOpen]= useState(false);
    const bonusContext = useContext(CustomFilterContext);
    const [search,setSearch] = useState('');
    const [customFilter,setCustomFilter] = useState([]);
    const [bonusFilterCount,setBonusFilterCount] = useState(NUMBER.ZERO)
    const initialFilter = [{tag:STATEMENT_FILTER_OPTION.status,selected_ids:['pending']}];
    const { identity } = useGetIdentityOptimized();


    useEffect(()=>{
        const prevAppliedFilters  = localStorage.getItem('bonusAppliedFilterMobile');
        const getPrevSearch = localStorage.getItem('bonusSearch');
        const prevSearch = getPrevSearch || '';
        if(prevAppliedFilters === null){
            // setCustomFilter(initialFilter);
            // const flQuery = getBonusCustomFilterQuery(initialFilter , identity);
            // let query = prevSearch ? {...flQuery,...getBonusSearchQuery(prevSearch)}:flQuery;
            setBonusFilter(prevSearch ? getBonusSearchQuery(prevSearch):{});
        }else{
            const parsedPrevFilters = JSON.parse(prevAppliedFilters);
            setCustomFilter(parsedPrevFilters);
            const prvQyery = getBonusCustomFilterQuery(parsedPrevFilters , identity);
            setBonusFilter(prevSearch ? {...prvQyery,...getBonusSearchQuery(prevSearch)}:prvQyery);
        }
        setSearch(prevSearch);
    },[])


  const applyFilter =(searchText:string)=>{
    const filteredData = customFilter.filter(item=>item.selected_ids !== null && (Array.isArray(item.selected_ids) ? item.selected_ids?.length > NUMBER.ZERO :item.selected_ids ) );
    let filterQuery = getBonusCustomFilterQuery(filteredData , identity);
    setBonusFilter(searchText ? {...filterQuery,...getBonusSearchQuery(searchText)}:filterQuery);
    localStorage.setItem('bonusAppliedFilterMobile',JSON.stringify(filteredData));
    setOpen(false);
  }
  const updateStatusValue = (value:string[])=>{
    let update = customFilter.filter(item=>item.tag !== STATEMENT_FILTER_OPTION.status);
    value.length > NUMBER.ZERO ? setCustomFilter([...update,{tag:STATEMENT_FILTER_OPTION.status,selected_ids:value}]):setCustomFilter(update);
}
const handleSubmit = (dates:any)=>{
    if(dates._gte || dates._lte){
        localStorage.setItem('bonusFromDateFilter',JSON.stringify(dates))
    }
}

const getStatusDefaultValue = ()=>{
    let statusItem = customFilter.filter(item=>item.tag === STATEMENT_FILTER_OPTION.status);
    if(statusItem.length > 0){
     return statusItem[0].selected_ids
    }
    return []
}

const handleSearch = (text:string)=>{
    setSearch(text);
    localStorage.setItem('bonusSearch',text)
    handleDebounce(text)
}
const handleDebounce = useCallback(debounce((searchText) => {
    setBonusFilter({...bonusFilter,...getBonusSearchQuery(searchText)})
},NUMBER.ONE_THOUSAND), []);


const handleClose = ()=>{
    setOpen(false);
    const prevAppliedFilters  = localStorage.getItem('bonusAppliedFilterMobile');
    setCustomFilter(prevAppliedFilters ? JSON.parse(prevAppliedFilters):initialFilter)
}

const handleResetAll = ()=>{
    setOpen(false);
    setCustomFilter([]);
    localStorage.removeItem('bonusAppliedFilterMobile')
}

const prevBonusApplied  = JSON.parse(localStorage.getItem('bonusAppliedFilterMobile'));
const bonusDateFilter = JSON.parse(localStorage.getItem('bonusFromDateFilter'))
useEffect(() => {
    if(prevBonusApplied !== null){
        const count = prevBonusApplied?.filter((item)=> item?.selected_ids?.length)?.length
        setBonusFilterCount(prevBonusApplied?.length);
    }else{
        setBonusFilterCount(NUMBER.ZERO);
    }
    bonusDateFilter !== null && setBonusFilterCount(prev=>prev+1)
}, [prevBonusApplied]);

    return(
        <>
        <div className="filter-mobile-search-row">
        <div className="propay-filter-search">
            <Input type="text" value={search} onChange={(e)=>handleSearch(e.target.value)} placeholder={SEARCH_PLACEHOLDER.bonus} className="propay-search" />
        </div>
        <IconButton className={`filter-mobile-btn ${bonusFilterCount > 0 ? 'filter-badge':''}`} onClick={()=>{setOpen(true)}}><FilterAlt color="primary" />
       {bonusFilterCount > 0 ? <Badge className='custom-badge-btn' color='primary' badgeContent={getFilterCount(bonusFilterCount)} max={NUMBER.FIVE}></Badge>:''}
        </IconButton>
        </div>
        <Dialog className="filter-modal-mobile" open={open} onClose={()=>handleClose()}>
        <DialogTitle sx={{p:2,}}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                            Filters
                                <IconButton color="primary" onClick={()=>handleClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                <DialogContent>
                <div className="filter-label-title">Status</div>
                <TagPicker onOpen={()=> document.body.classList.add("tag-picker-body")} onClose={()=> document.body.classList.remove("tag-picker-body")} menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" data={BONUS_OPTIONS} onChange={(e)=>updateStatusValue(e)}
                 valueKey={'id'} labelKey={'name'} style={{ width: 300 }} placeholder='Select Status' defaultValue={getStatusDefaultValue()}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={STATEMENT_FILTER_OPTION.worker} resource={'employees'} filterQuery={{ active: {_eq: false}, _or: {active: {_eq: true}}}}
                label={'name'} value={'id'} renderData={bonusContext?.allWorkers} setRenderData={bonusContext?.setAllWorkers} sortQry={{ field: 'name', order: 'ASC' }}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={STATEMENT_FILTER_OPTION.propay} resource={'propays'}
                filterQuery={{parent_id: {_is_null: true}}} label={'name'} value={'id'} renderData={bonusContext?.allPropays} setRenderData={bonusContext?.setAllPropays} sortQry={{ field: 'name', order: 'ASC' }}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={STATEMENT_FILTER_OPTION.job} resource={'jobs'}
                filterQuery={{active: { _eq: true }}} label={'display_name'} value={'id'}  renderData={bonusContext?.allJobs} setRenderData={bonusContext?.setAllJobs} sortQry={{ field: 'name', order: 'ASC' }}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={STATEMENT_FILTER_OPTION.paid_period} resource={'periods'} filterQuery={{ start_date: { _lte: moment().format('YYYY-MM-DD') } }}
                label={'name'} value={'id'} renderData={bonusContext?.allPeriods} setRenderData={bonusContext?.setAllPeriods} sortQry={{ field: 'start_date', order: 'DESC' }}/>
                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={STATEMENT_FILTER_OPTION.statement_period} resource={'ProtivStatementPeriod__DropdownList'} filterQuery={{}}
                label={'name'} value={'id'} renderData={bonusContext?.statementPeriod} setRenderData={bonusContext?.setStatementPeriod} sortQry={{ field: 'start_date', order: 'DESC' }}/>
                <CustomConditionInput name={STATEMENT_FILTER_OPTION.earnings} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
                <CustomConditionInput name={STATEMENT_FILTER_OPTION.reference} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
                <CustomDateFilter name={STATEMENT_FILTER_OPTION.created_date} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
                <CustomDateFilter name={STATEMENT_FILTER_OPTION.close_date} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
                <Form onSubmit={()=>{}}
                render={()=><DateRangeInputFilter
                    className="date-input-filter"
                    source="statement_period_id_obj.start_date"
                    pageType='bonus'
                    onChange={handleSubmit}
                    alwaysOn/>}>
                </Form>
                </DialogContent>
                <div className="responsive-apply-filter-div">
                <Button className="responsive-apply-filter-btn reset-a-f-btn" fullWidth size="large"  variant="contained" color="primary" onClick={()=>handleResetAll()}>Reset All</Button>
                <Button className="responsive-apply-filter-btn" fullWidth size="large" type="submit" variant="contained" color="primary" onClick={()=>applyFilter(search)}>Apply Filter</Button>
                </div>
        </Dialog>
        </>
    )
}

export const CustomTagInput = ({name,resource,filterQuery,label,value,customFilter,setCustomFilter,renderData,setRenderData,sortQry})=>{
    let prevSelectedValue = customFilter.filter(item=>item.tag === name);
    const [defaultValue,setDefaultValue]=useState(prevSelectedValue.length > 0 ? prevSelectedValue[0].selected_ids:[]);
    const dataProvider = useDataProvider();

    useEffect(()=>{
        if(!renderData.length){
            dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND},
                sort: sortQry,
                filter: filterQuery,
            }).then(res=>{
                setRenderData(res.data);
            })
        }
    },[])

    const updateSelectedValue = (value)=>{
        let update = customFilter.filter(item=>item.tag !== name);
         setCustomFilter(_.concat(update,[{tag:name,selected_ids:value}]));
    }

   return (<>
   <div className="filter-label-title">{name}</div>
   <TagPicker onOpen={()=> document.body.classList.add("tag-picker-body")} onClose={()=> document.body.classList.remove("tag-picker-body")} menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" placeholder={`Select ${name}`} searchable={true} data={renderData} valueKey={value} labelKey={label} onChange={(e)=>updateSelectedValue(e)}
   defaultValue={defaultValue}
   style={{ width: 300 }} />
   </>
   )
}

export const CustomConditionInput = ({name,customFilter,setCustomFilter})=>{
    const inBetween = ['_nin', '_in'];
    let prevInput = customFilter.filter(fl=>fl.tag === name);
    let prevBudgetCondition = prevInput.length > 0 ? prevInput[0].value:'';
    const prevTag = prevInput[NUMBER.ZERO] || {};
    let prvFirstInputValue = inBetween.includes(prevBudgetCondition) ? prevTag.selected_ids[NUMBER.ZERO]:prevTag.selected_ids;
    let prevSecValue = inBetween.includes(prevBudgetCondition) ? prevTag.selected_ids[1]:'';
    const [budgetCondition, setBudgetCondition] = useState(prevBudgetCondition);
    const [showSecondInput,setShowSecondInput] = useState(inBetween.includes(prevBudgetCondition));
    const [firstValue,setFirstValue]= useState(prevInput.length > 0 ? prvFirstInputValue : '');
    const [secondValue,setSecondValue] = useState(prevInput.length > 0 ? prevSecValue : '');
    const [isExpanded,setIsExpanded] = useState(prevInput.length > 0);


    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowSecondInput(inBetween.includes(event.target.value));
    };

    useEffect(()=>{
        if(budgetCondition){
            let selectValue=null;
            if (inBetween.includes(budgetCondition)) {
                selectValue = [parseFloat(firstValue), parseFloat(secondValue)];
            }else{
                selectValue = parseFloat(firstValue)
            }
            let update = customFilter.filter(item=>item.tag !== name);
            setCustomFilter(_.concat(update,[{tag:name,value:budgetCondition,selected_ids:selectValue}]));
        }
    },[budgetCondition,firstValue,secondValue])
    const handleClear = ()=>{
     let updateFilter =customFilter.filter(fl=>fl.tag !== name);
     setCustomFilter(updateFilter);
     setBudgetCondition('');
     setFirstValue('');
     setSecondValue('')
     setShowSecondInput(false)
    }
    return(<Accordion className="filter-accordion" expanded={isExpanded}  onChange={(_,expanded)=>setIsExpanded(expanded)}>

        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="options-content"
            id="options-header"
        >
           {name}
        </AccordionSummary>
        <AccordionDetails id="options-content" aria-labelledby="options-header">
       {(budgetCondition ||firstValue || secondValue) && <Button className="clear-budget-value" onClick={()=>handleClear()}>
            {CLOSE_ICON()}
        </Button>}
                <div className="filter-input">
                    <Select
                        size="small"
                        displayEmpty
                        className="worked-hour-filter-select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                        onChange={handleChange}
                        fullWidth
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </div>
                <div className="filter-input">
                <TextField
                    fullWidth
                    label="Add Value"
                    variant="outlined"
                    size="small"
                    value={firstValue}
                    type="number"
                    onChange={e => setFirstValue(e.target.value)}
                />
            </div>
            {showSecondInput && (
                <>
                    <div className="filter-input">And</div>
                    <div className="filter-input">
                        <TextField
                            fullWidth
                            label="Add Value"
                            id="secondValue"
                            variant="outlined"
                            size="small"
                            value={secondValue}
                            type="number"
                            onChange={e => setSecondValue(e.target.value)}
                        />
                    </div>
                </>
            )}
        </AccordionDetails>
    </Accordion>)
}
export const CustomDateFilter = ({name,customFilter,setCustomFilter})=>{
    const prevIds = customFilter.filter(item => item.tag === name);
    const prevStartDate =
        prevIds.length > NUMBER.ZERO
            ? prevIds[NUMBER.ZERO].selected_ids[NUMBER.ZERO]
            : null;
    const prevEndDate =
        prevIds.length > NUMBER.ZERO
            ? prevIds[NUMBER.ZERO].selected_ids[NUMBER.ONE]
            : null;
    const [startDate, setStartDate] = useState(prevStartDate);
    const [endDate, setEndDate] = useState(prevEndDate);
    const [isExpanded,setIsExpanded] = useState(prevIds.length > 0);

    useEffect(()=>{
        if(startDate || endDate){
        let prevFilter = customFilter.filter(item=>item.tag !== name);
        setCustomFilter([...prevFilter,{ tag:name,selected_ids:[startDate, endDate]}]);
        }
    },[startDate,endDate])

    const handleDateClear = ()=>{
        let updateFilter =customFilter.filter(fl=>fl.tag !== name);
        setCustomFilter(updateFilter);
        setStartDate(null);
        setEndDate(null);
    }

    return(<Accordion className="filter-accordion" expanded={isExpanded}  onChange={(_,expanded)=>setIsExpanded(expanded)}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="options-content"
        id="options-header"
    >
       {name}
    </AccordionSummary>
    <AccordionDetails id="options-content" aria-labelledby="options-header">
   {(startDate || endDate) && <Button className="clear-budget-value" onClick={()=>handleDateClear()}>
        {CLOSE_ICON()}
    </Button>}
    <div className="filter-input">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    maxDate={moment(endDate).subtract(1, 'days').toDate()}
                    placeholderText="From"
                    className='react-filter-date'
                />
            </div>
            <div className="filter-input">
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    placeholderText="To"
                    minDate={moment(startDate).add(1, 'days').toDate()}
                    className='react-filter-date'
                />
            </div>
    </AccordionDetails>
</Accordion>)
}
export default MobileViewFiter;

export const CustomSingleDateFilterMobile = ({name,customFilter,setCustomFilter})=>{
    const prevIds = customFilter.filter(item => item.tag === name);
    const inBetween = ['_nin', '_in'];

    const prvBudgetCondition = prevIds.length > NUMBER.ZERO ? prevIds[NUMBER.ZERO].value:'';
    const prevTag = prevIds[NUMBER.ZERO] || {};
    let prvFirstInputValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[NUMBER.ZERO]:prevTag.selected_ids?.length ? prevTag.selected_ids[0] : '';

    let prevSecValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[1]:'';
    const [startDate, setStartDate] = useState(prvFirstInputValue || '');
    const [budgetCondition, setBudgetCondition] = useState(prvBudgetCondition);
    const [showInput, setShowInput] = useState(inBetween.includes(prvBudgetCondition));
    const [secondValue, setSecondValue] = useState(prvBudgetCondition ? prevSecValue : '');
    const [isExpanded,setIsExpanded] = useState(prevIds.length > 0);

    useEffect(()=>{
        if(startDate || (showInput && secondValue)){
            let prevFilter = customFilter.filter(item=>item.tag !== name);
            setCustomFilter([...prevFilter,{ tag:name,selected_ids:showInput ? [startDate,secondValue]:[startDate],value:budgetCondition}]);
        }
    },[startDate,secondValue])

    const handleDateClear = ()=>{
        let updateFilter =customFilter.filter(fl=>fl.tag !== name);
        setCustomFilter(updateFilter);
        setStartDate(null);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowInput(inBetween.includes(event.target.value));
    };

    return(<Accordion className="filter-accordion" expanded={isExpanded}  onChange={(_,expanded)=>setIsExpanded(expanded)}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="options-content"
        id="options-header"
    >
       {name}
    </AccordionSummary>
    <AccordionDetails id="options-content" aria-labelledby="options-header">
   {(startDate) && <Button className="clear-budget-value" onClick={()=>handleDateClear()}>
        {CLOSE_ICON()}
    </Button>}
    <div className="filter-input">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Condition
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                         onChange={handleChange}
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
    <div className="filter-input">
                <DatePicker
                    selected={startDate ? new Date(`${startDate}T00:00:00`) : null}
                    time
                    maxDate={moment(secondValue).subtract(1, 'days').toDate()}
                    onChange={date => setStartDate(moment(date).format('YYYY-MM-DD'))}
                    placeholderText={`${showInput ? 'From' : 'Date'}`}
                    className='react-filter-date'
                />
            </div>
            {showInput && (
                <>
                    <div className="filter-input">And</div>
                    <div className="filter-input">
                    <DatePicker
                            showTimeSelect={false}
                            selected={secondValue ? new Date(`${secondValue}T00:00:00`) : null}
                            minDate={moment(startDate).add(1, 'days').toDate()}
                            onChange={date => setSecondValue(moment(date).format('YYYY-MM-DD'))}
                            placeholderText="To"
                            className='react-filter-date'
                        />
                    </div>
                </>
            )}
    </AccordionDetails>
</Accordion>)
}