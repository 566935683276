import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
    CRUD_DELETE,
    CRUD_UPDATE,
    DatagridBody, DatagridBodyProps, DatagridRow,
    FunctionField, ListActions,
    LoadingIndicator,
    ResourceContextProvider,
    TextField,
    useDataProvider,
    useDelete,
    useListContext,
    useMutation,
    useNotify,
    useRefresh
} from 'react-admin';
import { toast } from 'react-toastify';
import { Datagrid } from '../../components/datagrid';
import { DateFormat } from '../../components/fields/DateFormat';
import { MoneyField, NumberToTimeField, getDuplicateConnectorList } from '../../components/fields/fields';
import { useGetIdentityOptimized } from '../../components/identity';
import PageAlerts from '../../components/page-alerts/PageAlerts';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import Empty from '../../layout/Empty';
import GroupPropayCardResponsive from '../../layout/GroupPropayCardResponsive';
import { List } from '../../layout/List';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import { PropayStatusField } from '../../layout/StatusField';
import { findpropayWithoutBudget, getFormatedMessage } from '../../utils/Constants/CommonFunctions';
import { CONSTANT_DATA, PROPAY_BULK_ACTION_API, PROPAY_COLUMN, checkforColumnVisibility, getColumnLabel, isMobile, truncatePropayListName } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { ManagerFullNameField } from '../employees/Employee';
import { AddPropaysModal } from './AddPropaysModal';
import { useQueryClient } from 'react-query';
import CostCodeChip from './CostCodeChip';
import PropayFilterMobile from './PropayFilterMobile';
import { CustomPropaySearcInput } from '../attendances/AttendanceFilterModal';
import { ALERT_ICON_WHITE, REMOVE_GROUP, ZOOM_SCREEN_ICON } from '../../utils/Constants/ClassName';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandPropaysModal } from './ExpandPropaysModal';
import { POST } from '../../services/HttpService';
import { useSnackbar } from 'notistack';
import { JobTypeIdsChip } from './JobIdsChip';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { IconButton } from '@mui/material';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { handleErrorAndSuccessToast } from '../../layout/AutomationListActions';
import { GENERAL_INFO } from '../../constants';

export const GroupPropayListType = (props: any) => {
    const { hideColumns,isAddPropay, addModal, fullScreenModal, showLimitedFilter } = props;
    const isMobileDevice = isMobile();
    const dataProvider = useDataProvider();
    const [useFieldsData, setUserFileds] = useState([]);
    const [mobilePropayFilter,setMobilePropayFilter] = useState(null);
    const [propayFilter, setPropayFilter] = useState({});
    const { identity } = useGetIdentityOptimized();
    const [connectorList,setConnectorList]= useState([]);

    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => {
        const dashboardApiCalls = async () => {
            let userFields = await dataProvider.getList('userFields',{pagination: { page: NUMBER.ONE, perPage: NUMBER.FIFTY },
                                        sort:{field: 'field_id', order: 'ASC'},filter:{model: {_eq: 'protiv.propay'}}});
            userFields?.data && userFields?.data?.forEach((item,i)=>{
                if( item.field_id === PROPAY_COLUMN.TITLE ){
                    userFields?.data?.splice(i, NUMBER.ONE);
                    userFields?.data?.unshift(item);
                }
            });
          //This will always show the title column for every User.
          //TODO: Remove when all existing data is migrated
          if (userFields?.data) {
            userFields = {
              ...userFields,
              data: userFields.data.map(column => {
                if (column.field_id === 14 || column.field_id === 25) {
                  return { ...column, is_visible: true };
                }
                return column;
              })
            }
          }

            if(hideColumns && userFields?.data) {
              const filteredData = userFields?.data.filter(item => item.field_id !== PROPAY_COLUMN.STATUS_BUTTON);
                setUserFileds(_.cloneDeep(filteredData))
            } else {
                setUserFileds(_.cloneDeepWith(userFields?.data));
            }
        }
        dashboardApiCalls();
    }, [dataProvider]);

    return (
        <>
            {!isMobileDevice ? (

                <GroupPropayTabList showLimitedFilter={showLimitedFilter} addModal={addModal} fullScreenModal={fullScreenModal} {...props} connectorList={connectorList} identity={identity} setPropayFilter={setPropayFilter} propayFilter={propayFilter} options={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} userFields={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} setUserFileds={setUserFileds}/>
            ) : (
                <div className="propay-page-card filter-toolbar" id='card-list-view'>
                    <ResourceContextProvider value="propays">
                        <>
                            <PageAlerts />
                            <List filterDefaultValues={{parent_id: {_eq: props?.record?.id}}}
                                filter={isMobileDevice ? mobilePropayFilter:propayFilter}
                                resource='propays'
                                title=' '
                                filters={<></>}
                                actions={<ListActions exporter={false} />}
                                sort={{ field: 'create_date', order: 'DESC' }}
                                emptyWhileLoading
                                disableSyncWithLocation={CONSTANT_DATA.true}
                            >
                              <>
                                {isMobileDevice ? <PropayFilterMobile showLimitedFilter={showLimitedFilter} connectorList={connectorList} identity={identity} setMobilePropayFilter={setMobilePropayFilter} mobilePropayFilter={mobilePropayFilter}/>:''}
                                <EditColumnsOpenDrawer connectorList={connectorList} {...props} propayExport={isMobileDevice} showFilter={!isMobileDevice} setFilterQuery={setPropayFilter} propayFilter={mobilePropayFilter}  userFields={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))}
                                 options={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} setUserFileds={setUserFileds}/>

                                {
                                    <GroupPropayCardResponsive {...props} propayFilter={propayFilter} connectorList={connectorList} onDashboard={false} showExport={!isMobileDevice} options={useFieldsData} hideOldAction={true} />
                                }
                              </>
                            </List>
                        </>
                    </ResourceContextProvider>
                </div>
            )}
        </>
    );
};

export const DatagridBodyExpandHeadless = (props: DatagridBodyProps) => {
    return <DatagridBody {...props} row={<DatagridRow headlessExpand />} />;
};

const CustomPropayFilters = [
    <CustomPropaySearcInput
        className="filter-search"
        source="name._ilike"
        label="resources.propays.search"
        size="medium"
        alwaysOn
        alwaysOnMobile
    />,
    <DateRangeInputFilter
        className="date-input-filter"
        source="from_date"
        alwaysOn
    />,
];

const GroupPropayTabList = (props:any) => {
    const {showLimitedFilter,isAddPropay=false,record,setPropaySelectedIds, addModal, fullScreenModal} = props;
    const { options,propayFilter,setPropayFilter,identity,connectorList, setTab } = props;
    const [showAddPropay, setShowAddPropay] = useState(false);
    const propaySort = JSON.parse(localStorage.getItem('propaySort')) || { field: 'create_date', order: 'DESC' };
    const [scrollCl, setScrollClass] = useState('');
    const [showFullScreen, setShowFullScreen] = useState(false);
    const navigate = useNavigate();
    const refresh = useRefresh()
    const defaultQuery = {parent_id: {_is_null: true},is_group_propay:{_eq:false},has_milestones:{_eq:false},id:{_nin:record?.grouped_propay_idsIds},status:{_in:['pending','started','completed']}}
    const dataProvider = useDataProvider();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} =  useSnackbar();

    useEffect(() => { }, [propayFilter]);

    useEffect(() => {
        const handleScroll = () => {
          const currentPosition = window.scrollY || window.pageYOffset;
          const isAtBottom = Math.ceil(currentPosition + window.innerHeight) >= document.body.scrollHeight;
          if (isAtBottom) {
            setScrollClass('bulk-listing-table');
          }else{
            setScrollClass('');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return(()=>{ window.removeEventListener('scroll', handleScroll);})
      }, []);

    const rowStyleForAutomatedPropayWithoutBudget = (record, index) => {
        if (findpropayWithoutBudget(record)) {
            return {
                backgroundColor:'#FEF2C8',
            };
        } else {
            return {
            };
        }
    };

    const showPropay = (PropayId: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('filter');
        if (queryParams.has('page')) {
            queryParams.set('page', '1');
        }
        const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
        queryParams.has('page') ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } }) :
            navigate(`/show/${PropayId}/propay`, { state: { _scrollToTop: true } });
        setTimeout(() => { refresh(); }, NUMBER.ONE_THOUSAND);
    };
    const BulkAction = ()=>{
        const {selectedIds}=useListContext();
        useEffect(()=>{
            setPropaySelectedIds(selectedIds)
        },[selectedIds])
        return(<></>)
    }

    const getAddPropayResponse = (responseId,count)=>{
        const interval = setInterval(() => {
            dataProvider.getOne('protivBulkSyncInfo', { id: responseId }).then((res)=>{
                if(res?.data?.status === 'success'){
                    const {failure_count} =res?.data;
                    const result = JSON.parse(res?.data?.result);
                    enqueueSnackbar(failure_count > 0? getFormatedMessage(result):`${count}/${count} ProPays were added.`,{variant:failure_count > 0 ? 'error':'success',key:'addProay',autoHideDuration:8000});
                    !res?.data?.failure_count &&   queryClient.refetchQueries()
                    clearInterval(interval);
                }
            })
        }, 2000);
    }

          return (
            <>
           <ResourceContextProvider value="propays">
            <div className='filter-toolbar propay-new-toolbar'>
                <PageAlerts />
                <List
                    filterDefaultValues={isAddPropay ? defaultQuery:{parent_id: {_eq: props?.record?.id}}}
                    filter={propayFilter}
                    resource='propays'
                    title={' '}
                    filters={<ResponsiveFilterGusser filters={CustomPropayFilters} />}
                    actions={<ListActions exporter={false} />}
                    sort={propaySort}
                    className='propay-listing propay-listing-columns'
                    disableSyncWithLocation={CONSTANT_DATA.true}
                >
                    <>
               {!isAddPropay ? <Button onClick={() => setShowAddPropay(true)} variant="contained" className='add-propay-btn'>
                        Add ProPay
                </Button>:''}
                {!addModal && !fullScreenModal &&
                <Button onClick={() => setShowFullScreen(true)} variant="contained" className='zoom-propay-btn'>
                        {ZOOM_SCREEN_ICON()}
                </Button>}
                <div className='propay-filter-toolbar'>
                 <EditColumnsOpenDrawer {...props} connectorList={connectorList} showLimitedFilter={showLimitedFilter} showFilter={true} propayExport={!isAddPropay} setFilterQuery={setPropayFilter} propayFilter={propayFilter} options={options} identity={identity}/>
                </div>
                    <div className='listing-scroll common-listing-scroll'>
                    <Datagrid
                        size="small"
                        rowClick="expand"
                        bulkActionButtons={isAddPropay ? <BulkAction/>:false}
                        isRowSelectable={()=>isAddPropay}
                        empty={<Empty />}
                        showFooter
                        rowStyle={rowStyleForAutomatedPropayWithoutBudget}
                        body={DatagridBodyExpandHeadless}
                        className='data-grid-listing'
                        stickyHeader={true}
                    >
                        {checkforColumnVisibility(options, PROPAY_COLUMN.TITLE) && <FunctionField
                            source="name"
                            label={getColumnLabel(options,PROPAY_COLUMN.TITLE)}
                            className="no-translate"
                            render={record => {
                                return (
                                    <>
                                    {findpropayWithoutBudget(record) && <div className='missing-budget-name'>{ALERT_ICON_WHITE()} ProPay is missing budget</div>}
                                        {record?.name?.length > NUMBER.FOURTY_TWO ? (
                                            <Tooltip id='unique-tooltip' title={record?.name} placement="bottom" arrow>
                                                <span className='propay-title-hover' onClick={()=>showPropay(record.id)}>
                                                    {truncatePropayListName(record?.name.toString())}
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <>
                                            <span className='propay-title-hover' onClick={()=>showPropay(record.id)}>{record?.name} </span>
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        />}
                        {checkforColumnVisibility(options, PROPAY_COLUMN.MANAGER) && <FunctionField
                            source="manager_id"
                            label={getColumnLabel(options, PROPAY_COLUMN.MANAGER)}
                            render={record => {
                                return (
                                    <>
                                     <ManagerFullNameField managerName={record?.manager_id_obj} />
                                    </>
                                );
                            }}
                        />
                        }
                        {checkforColumnVisibility(options, PROPAY_COLUMN.JOB) && <FunctionField
                            source="job_id"
                            label={connectorList.length > 1 ? `Job-${connectorList[NUMBER.ZERO]?.name || connectorList[NUMBER.ZERO]?.service}` : getColumnLabel(options, PROPAY_COLUMN.JOB)}
                            render={(record) => (<> {record?.job_id ? record?.job_id_obj?.full_name:''} </>)}
                        />
                        }
                        {(connectorList.length > NUMBER.ONE && checkforColumnVisibility(options, PROPAY_COLUMN.SECOND_JOB)) && <FunctionField
                            source="second_job_id"
                            label={`Job-${connectorList[NUMBER.ONE]?.name || connectorList[NUMBER.ONE]?.service}`}
                            render={(record) => (<> {record?.second_job_id ? record?.second_job_id_obj?.full_name :''} </>)}
                        />}

                        {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_HOURS) &&
                            <NumberToTimeField
                            fromPropay={true}
                            source="estimated_hours"
                            label={getColumnLabel(options, PROPAY_COLUMN.BUDGET_HOURS)}
                            groupBy
                            className="no-translate"
                            identity={identity}
                            />
                        }
                        {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_AMOUNT) &&
                            <MoneyField fromPropay={true} className="no-translate" groupBy source="budget" label={getColumnLabel(options, PROPAY_COLUMN.BUDGET_AMOUNT)}/>
                        }


                        {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_HOURS) &&
                            <NumberToTimeField
                            source="hours"
                            fromPropay={true}
                            identity={identity}
                            label={getColumnLabel(options, PROPAY_COLUMN.ACTUAL_HOURS)}
                            groupBy
                            className="no-translate"
                            />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_AMOUNT) &&
                            <MoneyField fromPropay={true} source="attendance_earning" className="no-translate" groupBy
                            label={getColumnLabel(options, PROPAY_COLUMN.ACTUAL_AMOUNT)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.VARIANCE_AMOUNT) &&
                            <MoneyField fromPropay={true} source="remaining_amount" className="no-translate" groupBy
                            label={getColumnLabel(options, PROPAY_COLUMN.VARIANCE_AMOUNT)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.VARIANCE_HOURS) &&
                            <NumberToTimeField
                                source="remaining_hours"
                                fromPropay={true}
                                label={getColumnLabel(options, PROPAY_COLUMN.VARIANCE_HOURS)}
                                groupBy
                                className="no-translate"
                                identity={identity}
                            />
                        }

                        {connectorList?.some(item => item?.support_options?.import_entities?.includes("job_type")) && checkforColumnVisibility(options, PROPAY_COLUMN.JOB_TYPE) &&
                        <TextField
                            source="job_id_obj.job_type_id_obj.name"
                            label={getColumnLabel(options, PROPAY_COLUMN.JOB_TYPE)}
                        />
                        }

                        {connectorList?.some(item => item?.service === 'aspire' && item?.status === 'authorized') && checkforColumnVisibility(options, PROPAY_COLUMN.INVOICE_TYPE) &&
                        <FunctionField
                            source="invoice_type_ids"
                            sortBy='invoice_type_ids'
                            label={getColumnLabel(options, PROPAY_COLUMN.INVOICE_TYPE)}
                            render={(record) => <JobTypeIdsChip fieldName={'invoice_type_ids_obj'} record={record} />}
                        />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.APPROVED_BONUSES) &&
                            <MoneyField fromPropay={true} source="performance_bonus" className="no-translate" groupBy
                            label={getColumnLabel(options, PROPAY_COLUMN.APPROVED_BONUSES)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.COST_CODES) && identity?.company?.can_use_cost_code &&
                            <FunctionField
                            source="cost_code_ids"
                            label={getColumnLabel(options, PROPAY_COLUMN.COST_CODES)}
                            className='milestone-label'
                            data-title='Cost Codes'
                            render={(record: any) =>
                                !findpropayWithoutBudget(record) ? record.cost_code_ids_obj.length > NUMBER.ZERO ? <CostCodeChip record={record}/>
                                 : null : <div className='add-budget-alert'>~</div>
                            }
                        />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.START_DATE) &&
                        <FunctionField
                            className="no-translate"
                            source="from_date"
                            label={getColumnLabel(options, PROPAY_COLUMN.START_DATE)}
                            sortable
                            render={(record: any) => (
                                 <DateFormat date={record.from_date} showTilde={findpropayWithoutBudget(record)}/>
                            )}
                        />}

                        {checkforColumnVisibility(options, PROPAY_COLUMN.END_DATE) && <FunctionField
                            className="no-translate"
                            source="to_date"
                            label={getColumnLabel(options, PROPAY_COLUMN.END_DATE)}
                            sortable
                            render={(record: any) => (
                                <DateFormat date={record.to_date} showTilde={findpropayWithoutBudget(record)}/>
                            )}
                        />}
                        {checkforColumnVisibility(options, PROPAY_COLUMN.STATUS) &&
                        <FunctionField
                        className="no-translate"
                        source="status"
                        sortable
                        render={(record: any) => (
                             <PropayStatusField record={record} />
                        )}
                        />}

                        {(!isAddPropay) && <FunctionField
                            textAlign="right"
                            label={'Actions'}
                            render={(record: any) => (
                                <RemovePropayAction record={record} />
                            )}
                        />}

                    </Datagrid>
                    </div>
                    </>

                </List>
            </div>
        </ResourceContextProvider>
        {showAddPropay && <AddPropaysModal addModal={true} isOpen={showAddPropay} record={props?.record} onClose={() => setShowAddPropay(false)} getAddPropayResponse={getAddPropayResponse} /> }
        {showFullScreen && <ExpandPropaysModal fullScreenModal={true} isOpen={showFullScreen} record={props?.record} onClose={() => setShowFullScreen(false)} /> }
        </>
    );
};

export const RemovePropayAction = ({ record }) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const { id } = useParams();
    const dataProvider = useDataProvider();
    const [mutate] = useMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [actionClicked, setActionClicked] = useState('');
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setActionClicked('');
    };

    const removeFromGroup = () => {
        handleClose();
        notify(GENERAL_INFO.soonNotify, { autoHideDuration: NUMBER.ONE_THOUSAND_FIVE_HUNDRED });
        const payload = {
            grouped_propay_id: Number(id),
            propay_ids: [record?.id]
        }
        POST(PROPAY_BULK_ACTION_API.RemoveGroupPropay, payload).then((res: any) => {
            getSyncIdInfo(res?.bulk_sync_info_id)
        })
    }

    const getSyncIdInfo = (syncId) => {
        const interval = setInterval(() => {
            dataProvider.getOne('protivBulkSyncInfo', { id: syncId }).then((res) => {
                if (res?.data?.status === 'success') {
                    const result = JSON.parse(res?.data?.result);
                    enqueueSnackbar(getFormatedMessage(result), { variant: res?.data?.failure_count > 0 ? 'error' : 'success', key: 'propay', autoHideDuration: 6000 });
                    !res?.data?.failure_count && queryClient.refetchQueries()
                    clearInterval(interval);
                }
            })
        }, 1500);
    }

    const handleConfirm = (name) => {
        handleClose();
        notify(GENERAL_INFO.soonNotify, { autoHideDuration: NUMBER.ONE_THOUSAND_FIVE_HUNDRED });
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { id: record.id, action: 'cancel_propay',action_name: name, data: {} },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (result: any, variables: any = {}) => {
                    handleErrorAndSuccessToast('success', `You have successfully ${name === 'cancel' ? 'cancelled' : 'deleted'} ProPay.`)
                    queryClient.refetchQueries();
                },
                onFailure: error => {
                    handleErrorAndSuccessToast('error', `${error.message}`)
                },
            }
        );
    };

    const handleConfirmation = (actionName) => {
        switch (actionName) {
            case 'remove': {
                makeFunctionCall(actionName, removeFromGroup);
                return;
            }
            case 'delete': {
                makeFunctionCall(actionName, handleConfirm);
                return;
            }
            default: {
                makeFunctionCall(actionName, handleConfirm);
                return;
            }
        }
    }

    const makeFunctionCall = (name, callback) => {
        if (actionClicked === name) {
            callback(name);
        } else {
            setActionClicked(name);
        }
    }

    const showText = (condition, secondText, firstText) => condition ? secondText : firstText;

    return (
        <>
            <div className='on-list-dropdown card-dropdown'>
                <IconButton
                    size='small'
                    sx={{ ml: 2 }}
                    id="card-menu"
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <DropdownIcon />
                </IconButton>
                <Menu
                    className='propay-action-menu'
                    id='card-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => handleConfirmation('remove')}>{showText(actionClicked === 'remove', 'Really Remove?', 'Remove from GP')}</MenuItem>
                    <MenuItem onClick={() => handleConfirmation('cancel')}>{showText(actionClicked === 'cancel', 'Really Remove & Cancel?', 'Remove & Cancel')}</MenuItem> 
                    <MenuItem onClick={() => handleConfirmation('delete')}>{showText(actionClicked === 'delete', 'Really Remove & Delete?', 'Remove & Delete')}</MenuItem>
                </Menu>
            </div>
        </>)
}
