import {useCallback} from 'react';
import {
    Identifier,
} from 'ra-core';

import { BulkActionProps, useTranslate } from 'react-admin';
import useActionMutation from './useActionMutation';
import { useSnackbar } from 'notistack';
import { TOAST_MESSAGES } from '../../constants';

const noSelection: Identifier[] = [];

const useUnarchiveEmployee = (props: BulkActionProps&{onSuccess?}) => {
    const { selectedIds = noSelection, onSuccess } = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useTranslate()
    const resource = 'employees';
    const { callAction, loading } = useActionMutation({
      resource,
      ids: selectedIds, 
      action:'activateEmployee',
      onSuccess: (data: any, variables: any = {}) => {
        enqueueSnackbar(
          translate('resources.employees.notification.activated_success'),
          { variant: 'success', autoHideDuration: TOAST_MESSAGES.autoHideDuration  });
        if(onSuccess) {
          onSuccess()
        }
      },
      onFailure: (error) => {
        enqueueSnackbar(
          translate('resources.employees.notification.activated_error'),
          { variant: 'error', autoHideDuration: TOAST_MESSAGES.autoHideDuration  }
        );
      },      
    })
    const activate = useCallback(() => {
        return callAction();
    },[callAction]);

    return {
        activate,
        loading,
    }
};


export default useUnarchiveEmployee;
