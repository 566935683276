import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Stack, Button, FormControl, OutlinedInput, AccordionDetails, FormGroup, FormControlLabel } from '@mui/material';

import moment from 'moment';
import {
    Create,
    ReferenceField,
    required,
    Button as RaButton,
    SelectInput,
    BooleanInput,
    useNotify,
    FunctionField,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceInput,
    Record,
    Toolbar,
    SaveButton,
    useGetList,
    SelectField,
    minValue,
    ResourceContextProvider,
    FormDataConsumer,
    useRedirect,
    useListContext,
    useMutation,
    CRUD_UPDATE,
    useUnselectAll,
    ListActions,
    useTranslate,
    useUpdate,
    email,
    useDataProvider,
} from 'react-admin';
import { List } from '../../layout/List';
import { Edit } from '../../layout/Edit';
import BooleanLabelField from '../../components/BooleanLabelField';
import { Theme, styled } from '@mui/material/styles';
import {
    EmailInput,
    getDuplicateConnectorList,
    getParsedConnectorName,
    MoneyInput,
    StyledDatagrid,
    validateWageNonZero
} from '../../components/fields';
import { DatePickerInput } from '../../components/fields/inputs';
import { canAccess } from '../../ra-rbac';
import { BooleanListInput } from '../../components/fields/BooleanInputList';
import EmployeeMoreMenu from './EmployeeMoreMenu';
import BulkArchiveButton from './BulkArchiveButton';
import {
    BulkActionProps,
    CreateButton,
    ExportButton,
    RadioButtonGroupInput,
    TopToolbar,
} from 'ra-ui-materialui';
import BulkActivateButton from './BulkActivateButton';

import { Route, Routes, useNavigate } from 'react-router';
import { EmployeeInviteDialog, EmployeeStatus } from './EmployeeInvite';
import { PhoneInput } from '../../components/fields/PhoneInput';
import { Field } from 'react-final-form';
import {
    DialogFormWithRedirect,
    DialogTitleForRecord,
} from '../../components/dialog-form';
import { useIdentityContext } from '../../components/identity';
import { usePermissionsOptimized } from '../../components/identity';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import { useGetBaseLocationForCurrentRoute } from '../../hooks/useGetBaseLocationForCurrentRoute';
import { useQueryClient } from 'react-query';
import { EmployeeSaveToolbar } from './EmployeeSaveToolbar';
import createDecorator from 'final-form-calculate';
import { ArrayInput, SimpleFormIterator } from '../../components/ArrayInput';
import { ADD_WAGE_USER, BULK_ACTIVATE_ICON, BULK_DISABLE_ICON, BULK_MERGE_ICON, CLASSNAME, CLOSE_ICON, DEACT_USER_ICON, DISABLED_NOTIFICATION_ICON, LOCK_ICON, TICK_ICON_FILLED_02 } from '../../utils/Constants/ClassName';
import { CONSTANT_DATA, TEAM_COLUMN, USER_TYPE, formatter, getDateTime } from '../../utils/Constants/ConstantData';
import { Tooltip } from '@mui/material';
import Empty from '../../layout/Empty';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { Typography } from '@mui/material';
import { InputLabel } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import Label from '../../components/Label';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import CustomEmployeeFilters from './CustomEmployeeFilters';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import EmployeeAction, { ResponsiveEmployeeAction } from './EmployeeAction';
import { useMediaQuery } from '@mui/material';
import {  DropdownBulkActions } from './EmployeeBulkAction';
import { IconButton } from '@mui/material';
import EmployeeResponsive from './EmployeeResponsive';
import EmployeeMobileViewFilter from './EmployeeMobileViewFilter';
import ExportTeam from './ExportTeam';
import { CustomSearchInput } from '../attendances/AttendanceFilterModal';
import { Datagrid } from '../../components/datagrid';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox } from '@mui/material';
import { EMPLOYEE_ADDITIONAL_SETTING } from '../../constants';
import { Box } from '@mui/material';
import { EmployeeRestrictedIcon } from '../../utils/Constants/CommonView';
import { CheckPicker } from 'rsuite';
import { isAllowedFilters } from '../../utils/Constants/CommonFunctions';

export const EmployeeFullNameField = ({ record }: any) => (
    <span id='team-worker-name' data-title='Name' className={CLASSNAME.no_translate}>
        {record.first_name} {record.last_name}
        {record?.is_disable_all_notification ? <NotificationIcon/> :''}
        
    </span>
);

export const EmployeeDisplayNameField = ({ record }: any) => (
    <span
      id="team-worker-name"
      data-title="Name"
      className={CLASSNAME.no_translate}
    >
      {record.display_name}
      {record?.is_disable_all_notification ? <NotificationIcon /> : ''}
    </span>
  )

export const ManagerFullNameField = ({ managerName }: any) => (
    <span id='team-worker-name' className={CLASSNAME.no_translate}>
        {managerName?.display_name !== 'false' ? managerName?.display_name:''}
        {managerName?.is_disable_all_notification ? <NotificationIcon/> :''}
    </span>
);


export const NotificationIcon = () => {
    return(
        <Tooltip title="Notifications Disabled" arrow placement="top" className='notification-tooltip' >
            {DISABLED_NOTIFICATION_ICON()}
        </Tooltip>
    )
}
export const DeactivatedIcon = ()=>{
    return(<Label
        variant="ghost"
        color={'pending'}
        sx={{ fontSize: 10, color: '#975B06', fontWeight:600, display: 'inline-flex !important', alignItems: 'center', background:'#FCF1E2', borderRadius: '9', height: '18px', minWidth: '65px' }}
    >
        {DEACT_USER_ICON()}
        Deact.
    </Label>)
}

export const NameField = ({ record }: any) => <span>{record.name}</span>;

export const user_type_selection = [
    { id: 'admin', name: 'resources.employees.choices.user_type.admin' },
    { id: 'manager', name: 'resources.employees.choices.user_type.manager' },
    {
      id: 'crew_lead',
      name: 'resources.employees.choices.user_type.crew_lead',
    },
    { id: 'worker', name: 'resources.employees.choices.user_type.worker' },
  ];
export const propayTypeSelection = [
    { id: 'budget_hours', name: 'Budget Hours' },
    { id: 'labor_budget', name: 'Labor Budget' },
    { id: 'percent_of_revenue', name: '% of Contract Price' },
];

export const bonuspoolSelection = [
    { id: 'pending_and_future', name: 'All Open, but Approved' },
    { id: 'future_only', name: 'Future Only' },
];

export const employee_status= [
    { id: true, name: 'resources.employees.choices.active.active' },
    { id: false, name: 'resources.employees.choices.active.inactive' },
]
export const propay_user_type =[
    { id: true, name: 'ProPay Users' },
    { id: false, name: 'Non ProPay Users' },
]

const EmployeeFilter = [
    <CustomSearchInput
        className='filter-search-team'
        source="name"
        label="Search"
        size="medium"
        alwaysOn
        alwaysOnMobile
        name='team'
    />,
    <DateRangeInputFilter
    className="date-input-filter"
    source="create_date"
    alwaysOn
    pageType='team'
    size='medium'/>,
    // <SelectInput
    //     sx={{ minWidth: '150px' }}
    //     size="medium"
    //     alwaysOn
    //     label="ProPay User?"
    //     source="is_propay_user._eq"
    //     choices={propay_user_type}
    // />,
];

const CustomToolbar = (props: any) => {
    const { handleClose, buttonText } = props;
    const notify = useNotify();
    const [mutate] = useMutation();

    const bulkUpdateEmployee = (data: any) => {
        return mutate(
            {
                type: 'update',
                resource: 'Employee',
                payload: { id:data?.change_employee_ids[0]?.id,employee_data:JSON.stringify(data?.change_employee_ids), action: 'bulkUpdateEmployeeData' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    props.onEditSuccess();
                },
                onFailure: error => {
                    notify(`Unable to update due to ${error.message}`);
                }
            }
        );
    }
    return (
        <Toolbar {...props}>
            <Button onClick={()=>handleClose()}>{buttonText ? buttonText : 'Cancel'}</Button>
            <SaveButton {...props} onSave={bulkUpdateEmployee} label="Update" />
        </Toolbar>
    );
};
export const StyledEmployeeFormIterator = styled(SimpleFormIterator)({
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
    // ...CommonSimpleFormIteratorStyle,
});

export const EmployeeUpdateForm = (props: any) => {
    const { emptyFields,isDashboard, isLoading, handleClose, subText, buttonText} = props;
    const validations = isDashboard ? [validateWageNonZero] : [required(), validateWageNonZero];
    const validateEmail = async (values: any, allValues: any) => {
        if(values?.length> 4){
        const emailToCheck = values;
        const emailCount = {};
        for (const item of allValues?.change_employee_ids) {
            const email = item.email;
            if (emailCount[email]) {
            emailCount[email]++;
            } else {
            emailCount[email] = 1;
            }
        }
        const countOfEmailToCheck = emailCount[emailToCheck] || 0;
        if (countOfEmailToCheck > 1) {
            return 'You can not use same email for multiple users.';
        } else {
            return undefined;
        }
    }else{
        return undefined;
    }

    };
    const validateMobile = async (values: any, allValues: any) => {
        if(values?.length> 4){
        const mobileToCheck = values;
        const mobileCount = {};
        for (const item of allValues?.change_employee_ids) {
            const mobile = item.mobile_number;
            if (mobileCount[mobile]) {
            mobileCount[mobile]++;
            } else {
            mobileCount[mobile] = 1;
            }
        }
        const countOfmobileToCheck = mobileCount[mobileToCheck] || 0;
        if (countOfmobileToCheck > 1) {
            return 'You can not use same mobile number for multiple users.';
        } else {
            return undefined;
        }
        }else{
            return undefined;
        }
    };
    return (
        <>
        {emptyFields?.length ?
            <SimpleForm
            className="add-wage-body"
            initialValues={{ change_employee_ids: emptyFields }}
            toolbar={
                <CustomToolbar
                    className='add-wage-toolbar'
                    onEditSuccess={props?.onEditSuccess}
                    handleClose={handleClose}
                    emptyFields={emptyFields}
                    buttonText={buttonText}
                />
            }
        >
            <div className="add-wage-desc">
              {subText ? subText : 'Worker listed require wage to correctly calculate ProPays'}
            </div>
        <ArrayInput source="change_employee_ids" label={false}>
            <StyledEmployeeFormIterator
                    disableReordering
                    disableAdd={true}
                    disableRemove={true}
                >
        <FormDataConsumer label='Name'>
                {({ scopedFormData,getSource }) => {
                return(
                 <div className='add-wage-name-field'>
                    <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                        <InputLabel htmlFor="worker-bonus-pool">Name</InputLabel>
                                        <OutlinedInput
                                            id="worker-bonus-pool"
                                            type='text'
                                            label=""
                                            value={scopedFormData?.name}
                                            disabled
                                        />
                                        </FormControl>
                    <Tooltip title={`Assigned as ${scopedFormData?.user_type ? scopedFormData?.user_type : 'worker'}`} arrow placement="top">
                    {ADD_WAGE_USER()}
                    </Tooltip>
                 </div>
                    )}}
        </FormDataConsumer>
        <FormDataConsumer label='Wage'>
                {({ scopedFormData,getSource }) => {
                return(
                <MoneyInput
                    sx={{ width: '20%' }}
                    source={getSource('base_wage')}
                    validate={scopedFormData?.ask_email ? [validateWageNonZero] : validations}
                    label='Wage'
                    className='wage-input-modal'
                />
                )}}
                </FormDataConsumer>
                <PhoneInput
                    source="mobile_number"
                    label='Mobile #'
                    validate={[validateMobile]}
                />
        <FormDataConsumer label='Email'>
                {({ scopedFormData,getSource }) => {
                return(
                    <EmailInput className='add-wage-email-input' fullWidth label='Email'
                     source={getSource("email")} validate={scopedFormData?.ask_email ? [email(), required(),validateEmail]:[email(),validateEmail]} />
                )}}
        </FormDataConsumer>
    </StyledEmployeeFormIterator>
    </ArrayInput>
    </SimpleForm>:
    (!isLoading && <Empty/>)}
    </>
    );
};

const UpdateShowList = (props: any) => {
    const { selectedIds } = useListContext();
    const unselectAll = useUnselectAll('employees');
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const handleClick = () => {
        mutate(
            {
                type: 'updateMany',
                resource: 'employees',
                payload: { ids: selectedIds, action: 'updateShowList' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    notify('You have successfully Imported Employee');
                    unselectAll();
                    queryClient.invalidateQueries(['employees', 'getList']);
                    redirect('/employees');
                },
                onFailure: error => {
                    notify(error.message);
                },
            }
        );
    };
    return <RaButton label="Import" onClick={() => handleClick()} />;
};

const ImportEmployeeListFilter = [
    <TextInput
        source="name._ilike"
        label="Search"
        size="medium"
        alwaysOn
        alwaysOnMobile
    />,
];

const ImportEmployeeList = (props: any) => {
    const redirect = useRedirect();
    const unselectAll = useUnselectAll('employees');
    return (
        <DialogFormWithRedirect
            {...props}
            hideToolbar={true}
            onClose={() => {
                unselectAll();
                redirect('/employees');
            }}
            render={(formProps: any) => {
                return (
                    <List
                        title=""
                        filter={{
                            show_in_list: { _eq: false },
                            active: { _eq: true },
                        }}
                        filters={
                            <ResponsiveFilterGusser
                                filters={ImportEmployeeListFilter}
                            />
                        }
                        actions={<ListActions exporter={false} />}
                    >
                        <StyledDatagrid
                            bulkActionButtons={<UpdateShowList {...props} />}
                        >
                            <TextField source="name" />
                        </StyledDatagrid>
                    </List>
                );
            }}
        />
    );
};

const ImportEmployeeListActions = (props: any) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const currentRouteBasePath = useGetBaseLocationForCurrentRoute();
    const identity = useIdentityContext();
    const handleClick = () => {
        redirect(currentRouteBasePath + '/import', '', null, {}, {});
    };
    return (
        <TopToolbar
            sx={{
                minWidth: 'fit-content',
            }}
        >
            <CreateButton />
            <ExportButton />
            {identity?.company?.allow_salesforce_api && (
                <Button
                    color="primary"
                    variant="contained"
                    sx={{ alignItems: 'center', justifyContent: 'center' }}
                    onClick={handleClick}
                >
                    {translate('resources.employees.fields.import_employee')}
                </Button>
            )}
        </TopToolbar>
    );
};

export const EmployeeBulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        <BulkArchiveButton {...props} />
        <BulkActivateButton {...props} />
    </Fragment>
);

export const EmployeeList = (props: any) => {
    const { permissions } = usePermissionsOptimized();
    const translate = useTranslate();
    const isSmall = useMediaQuery((theme: Theme) =>theme.breakpoints.down('sm'));
    const identity = useIdentityContext();
    const [connectorList,setConnecterList]=useState([])
    const allow_all_workers_to_add_time = identity?.company?.allow_all_workers_to_add_time;
    const dataProvider = useDataProvider();
    const [bulkAction,setBulkAction]=useState('');
    const [teamFields,setTeamFields]=useState([]);
     const defaultFilter = {
        ...(
          identity?.user_type === 'manager'
          ? {
              ...(
                identity?.allowed_branches?.length > 0 
                && { allowed_branches: { _in: identity.allowed_branches } }
              ),
              ...(
                identity?.allowed_divisions?.length > 0 
                && { allowed_divisions: { _in: identity.allowed_divisions } }
              ),
              show_in_list: { _eq: true }
            }
          : { show_in_list: { _eq: true } }
        )
      };
    const [filterQuery,setFilterQuery] = useState(defaultFilter);
    const [mobileFilterQuery,setMobileFilterQuery] = useState(defaultFilter);
    const getValue  = (val)=> val.toLowerCase() !== 'false' && val ? val :'';
    const customQuery =  isSmall ? mobileFilterQuery:filterQuery;
    const allowCreate = canAccess({permissions,resource: 'employees',action: 'create',})
    const [hideAction,setHideAction]= useState(false);
    const navigate = useNavigate();
    const prevSort = JSON.parse(localStorage.getItem('teamPageSetting'));
    const parsedSort = prevSort ? JSON.parse(prevSort?.sort):''
    const teamSort = parsedSort || { field: 'id', order: 'ASC' };
    useEffect(()=>{
        const getConnectorList = async ()=>{
            const data = await getDuplicateConnectorList();
           setConnecterList(!data.length ? []: data)
        }
        getConnectorList();
        const getUserFields = async ()=>{
            let userFields = await dataProvider.getList('userFields',{pagination: { page: NUMBER.ONE, perPage: NUMBER.FIFTY },
            sort:{field: 'field_id', order: 'ASC'},filter:{model: {_eq: 'protiv.employee'}}});
            const filteredData = userFields?.data?.filter(obj => obj.field_id_obj.name !==TEAM_COLUMN.ACTIONS)
            setTeamFields(filteredData)
        }
        getUserFields();
    },[])

    const checkforColumnVisibility = (columnName)=> {
        const filteredObjects = teamFields ? teamFields.filter(obj => obj.field_id_obj.name === columnName && obj.is_visible) : [];
        return filteredObjects.length > NUMBER.ZERO;
    }
    useEffect(() => {
        document.body.classList.add("team-body");
        return () => {
        document.body.classList.remove("team-body");
        }
    }, []);

    useEffect(()=>{
        const queryExtended = {
            ...filterQuery,
            ...(identity?.allowed_branches?.length > 0
              && { allowed_branches: { _in: identity?.allowed_branches } }
              ),
              ...(identity?.allowed_divisions?.length > 0
                && { allowed_divisions: { _in: identity?.allowed_divisions } }
                ),
          };
        isAllowedFilters(identity , queryExtended ,{} , setFilterQuery );
    },[identity?.user_type])

    return (
        <>
        {!isSmall ? (
        <div className='team-title-row'>
            <div className='team-main-title'>Team</div>
            {allowCreate && <Button className='add-team-button' onClick={()=>navigate('/employees/create')}>Add Team Member</Button>}
        </div>
        ) : (
        <></>
        )}

        <div className='employee-MuiPaper-root'>
            
           <List
                {...props}
                resource='employees'
                hideTitle={true}
                filters={!isSmall ? <ResponsiveFilterGusser filters={EmployeeFilter} />:<></>}
                filterDefaultValues={defaultFilter}
                titleActionProps={{ showCreate: false }}
                filter={customQuery}
                hasCreate={false}
                exporter={false}
                sort={teamSort}
                disableSyncWithLocation={CONSTANT_DATA.true}
                className="employees-table team-mobile-create-btn"
               // actions={<ImportEmployeeListActions />}
            >
            {isSmall ? (
            <div className='team-responsive-top-bar'>
            <div className='team-title-row'>
            <div className='team-main-title'>Team</div>
            <ResponsiveEmployeeAction teamFields={teamFields} setTeamFields={setTeamFields} allowCreate={allowCreate} filterQuery={mobileFilterQuery}/>
            </div>
            </div>
            ) : (
                <></>
            )}
        <div className='propay-column-row propay-column-team-row'>
     {isSmall ? <EmployeeMobileViewFilter setEmployeeFilter={setMobileFilterQuery} employeeFilter={mobileFilterQuery}/> : <CustomEmployeeFilters setEmployeeFilter={setFilterQuery} defaultFilter={filterQuery}/>}
     {!isSmall && <EditColumnsOpenDrawer userFields={teamFields} options={teamFields} setUserFileds={setTeamFields}/> }
       {!isSmall && <EmployeeAction filterQuery={customQuery} columnOptions={teamFields} setBulkAction={setBulkAction} setHideAction={setHideAction}/>}
        </div>
       {!isSmall ? <>
        {(bulkAction && hideAction) && <div className='close-statement-toolbar'>
                <div className='close-bulk-action-text'>
               <IconButton onClick={()=>{setBulkAction('')}} size="medium" className='cross-statement'>
                    <CLOSE_ICON />
                </IconButton>
                Select team member you wish to {bulkAction.toLowerCase()}
                <Button className='bulk-approve-button' disabled>{bulkAction} </Button>
               </div>
               </div>}

               {/* Team Bulk Message Bar Toolbar Start */}
                {/* <div className='team-message-bar'>
                <div className='team-message-text'>The 3 users you selected will be merge with the “Primary Account”.
                <InfoLabel className="notification-title-info-label" icon="ri:error-warning-fill" height={12} >
                <Typography sx={{ fontSize: 10 }}> Dummy Content</Typography>
                </InfoLabel>
                </div>
                <div className='team-message-right'>
                Would you like to merge this users?
                <Button className='merge-user-btn merge-user-grey-btn'>{BULK_MERGE_ICON()} Merge Users</Button>
                </div>
                </div> */}
                {/* Team Bulk Message Bar Toolbar End */}

                {/* Team Merge Alert Message Start */}
                {/* <div className='team-merge-alert'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 7.49994V10.6249M2.2475 13.4383C1.52584 14.6883 2.42834 16.2499 3.87084 16.2499H16.1292C17.5708 16.2499 18.4733 14.6883 17.7525 13.4383L11.6242 2.81494C10.9025 1.56494 9.0975 1.56494 8.37584 2.81494L2.2475 13.4383ZM10 13.1249H10.0058V13.1316H10V13.1249Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>You cannot merge two or more users who have an active connector. This users have active connectors:</p>
                <ul>
                    <li>Jacob Jones</li>
                    <li>Devon Lane</li>
                </ul>
                </div> */}
                {/* Team Merge Alert Message End */}

                <div className='team-listing-scroll team-page-scroll'>
                <Datagrid
                    size="medium"
                    bulkActionButtons={<DropdownBulkActions filterQuery={filterQuery} bulkAction={bulkAction} setHideAction={setHideAction} hideAction={hideAction} setBulkAction={setBulkAction}/>}
                    isRowSelectable={(record: Record) => !record.is_owner}
                    stickyHeader
                    empty={<Empty />}
                >
                    {checkforColumnVisibility(TEAM_COLUMN.NAME) &&<EmployeeFullNameField
                        source="name"
                        sortBy={'name'}
                        sortable={true}
                    />}

                    {checkforColumnVisibility(TEAM_COLUMN.ROLE) &&<FunctionField
                        source="user_type"
                        sortBy={'user_type'}
                        label={translate('resources.employees.fields.role')}
                        render={record => (
                            <>
                                {record.is_owner && (
                                    <>
                                        <p className={CLASSNAME.no_translate}>
                                            {translate(
                                                'resources.employees.fields.owner'
                                            )}
                                        </p>
                                    </>
                                )}
                                {!record.is_owner && (
                                    <SelectField
                                        source="user_type"
                                        choices={user_type_selection}
                                    />
                                )}
                            </>
                        )}
                    />}
                    {checkforColumnVisibility(TEAM_COLUMN.WAGE) && <FunctionField
                        label="Wage"
                        sortBy={'base_wage'}
                        textAlign="right"
                        render={(record: any) =>formatter.format(record.base_wage)}/>}

                    {checkforColumnVisibility(TEAM_COLUMN.MOBILE) && <FunctionField
                        label="Mobile"
                        sortBy={'mobile_number'}
                        textAlign="right"
                        render={(record: any) =>getValue(record.mobile_number)}/>}

                    {checkforColumnVisibility(TEAM_COLUMN.EMAIL) && <FunctionField
                        label="Email"
                        sortBy={'email'}
                        textAlign="right"
                        render={(record: any) =>getValue(record.email)}/>}

                    {checkforColumnVisibility(TEAM_COLUMN.CREATE_DATE) && <FunctionField
                        label="Create Date"
                        textAlign="right"
                        sortBy={'create_date'}
                        render={(record: any) =>getDateTime(record.create_date)}/>}

                    {checkforColumnVisibility(TEAM_COLUMN.LAST_UPDATED) && <FunctionField
                        label="Last Updated"
                        textAlign="right"
                        sortBy={'write_date'}
                        render={(record: any) =>getDateTime(record.write_date)}/>}

                    {!(identity?.company?.tsheets_status === 'connected') &&
                        allow_all_workers_to_add_time && teamFields.length && (
                            <FunctionField
                                source="allow_to_add_time"
                                render={record => {
                                    return (
                                        <BooleanListInput
                                            source="allow_to_add_time"
                                            variant="standard"
                                            label=""
                                            disabled={
                                                record.user_type !== USER_TYPE.worker
                                            }
                                        />
                                    );
                                }}
                            />
                        )}
                   {(connectorList.length > NUMBER.ONE && teamFields.length) ? <FunctionField
                        label='Connector'
                        textAlign="right"
                        render={(record: any) => getParsedConnectorName(record.connected_name) }
                    />:''}

                   {checkforColumnVisibility(TEAM_COLUMN.STATUS) && <FunctionField
                        label="resources.employees.fields.status"
                        textAlign="right"
                        render={(record: any) => (
                            <>
                             {record.restrict_access ? 
                                <>
                                    <BooleanLabelField
                                        source="restrict_access"
                                        yes_value={'Restricted'}
                                        no_value={'Restricted'}
                                    />
                                    </> :<>
                                    {!record.active && (
                                    <BooleanLabelField
                                        source="active"
                                        yes_value={translate(
                                            'resources.employees.fields.yes_value'
                                        )}
                                        no_value={translate(
                                            'resources.employees.fields.no_value'
                                        )}
                                    />
                                )}
                                {record.active && (
                                    <EmployeeStatus record={record} />
                                )}
                                    </>
                                }
                            </>
                        )}
                    />}
                    {(teamFields.length > 0) && <FunctionField
                        label="Actions"
                        textAlign="center"
                        render={(record: any) => (
                            <EmployeeMoreMenu record={record} />
                        )}
                    />}
                </Datagrid>
                </div>
                </> :
                <>
                <EmployeeResponsive checkforColumnVisibility={checkforColumnVisibility}/>
                </>}
            </List>
            <Routes>
                <Route
                    path=":id/invite"
                    element={
                        <ResourceContextProvider value="employees">
                            <EmployeeInviteDialog />
                        </ResourceContextProvider>
                    }
                />
                <Route
                    path=":id/edit"
                    element={
                        <ResourceContextProvider value="employees">
                            <EmployeeEditDialog />
                        </ResourceContextProvider>
                    }
                />
                <Route
                    path="create"
                    element={
                        <ResourceContextProvider value="employees">
                            <EmployeeCreateDialog />
                        </ResourceContextProvider>
                    }
                />
                <Route
                    path="import"
                    element={
                        <ResourceContextProvider value="employees">
                            <ImportEmployeeList />
                        </ResourceContextProvider>
                    }
                />
            </Routes>
        </div>
        </>
    );
};

const StyledReferenceInputFull = styled(ReferenceInput)({
    width: '100%',
});

const onChangeBaseWage = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const result: any = {};
    if (fieldValue == 0) {
        result['base_wage'] = null;
    }
    return result;
};

const onChangePayroll = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const result: any = {};
    if (fieldValue == false) {
        result['employee_payroll_number'] = '';
    }
    return result;
};

export const validateEmailRequired = (value, allValues) => {
    if (allValues.user_type === 'manager' && !value) {
        return 'Email is required';
    }
    return undefined;
};


export const EmployeeDialogForm = (props: any) => {
    const navigate = useNavigate();
    const { permissions } = usePermissionsOptimized();
    const [branchList , setBranchList] = useState([]);
    const [ divisionList , setDivisionList]= useState([]);
    const {record }=props;
    const [isSelectedBranch , setIsSelectedBranch]= useState(false);
    const [isSelectedDivision , setIsSelectedDivision]= useState(false);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);

    const handleBranchCheckboxClick = (value , formProps) => {
        setIsSelectedBranch(!isSelectedBranch); 
        if(!value.target.checked){
            formProps.form.change('allowed_branches' ,[]);
        }
    };

    const handleDivisionCheckboxClick = ( value , formProps) => {
        setIsSelectedDivision(!isSelectedDivision); 
        if(!value.target.checked){
            formProps.form.change('allowed_divisions' ,[]);
        }
    };

    const newRecord = {
        // Reset crew lead Permissions when the user
        ...record, ...(record?.user_type !== USER_TYPE.crew_lead &&
          { is_crew_able_to_see_worker_wages: undefined, is_crew_able_to_assign_propays: undefined })
      }
    const allow_user_type_access = canAccess({
        permissions,
        resource: 'employees',
        action: 'allow_user_type_access',
    });
    const identity = useIdentityContext();
    const allow_all_workers_to_add_time =identity?.company?.allow_all_workers_to_add_time;
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'base_wage',
                    updates: onChangeBaseWage,
                },
                {
                    field: 'employee_payroll_number',
                    updates: onChangePayroll,
                }
            ),
        ];
    }, []);

    const setUsertype = () => {
        if(props?.hideRoleInput){
            return {user_type:USER_TYPE.manager};
        }else if(props?.record?.user_type){
            return {user_type: props?.record?.user_type}
        }else{
            return {user_type:USER_TYPE.worker}
        }
    }

    const handleSettingChange = (isChecked,form,field)=>{
      form.change(field,isChecked);
    }

    const dataProvider = useDataProvider();
    const getBranchList = (perCount,text)=>{
        let query = text ? { name: { _ilike: text } , type: { _eq: 'branch' }}:{  type: { _eq: 'branch' }};
        dataProvider.getList('Branch__DropdownList', {
            pagination: { page: NUMBER.ONE, perPage: perCount },
            sort: { field: 'id', order: 'DESC' },
            filter: query,
        }).then(res=>{
            const mappedData = res.data.map(item => ({ label: item.display_name, value: item.id }))
             setBranchList(mappedData);
        })
    }

    const getDivisionList = (perCount,text)=>{
        // setSearch(text);
        let query = text ? { name: { _ilike: text } , type: { _eq: 'division' }}:{  type: { _eq: 'division' }};
        // setLoading(true);
        dataProvider.getList('Branch__DropdownList', {
            pagination: { page: NUMBER.ONE, perPage: perCount },
            sort: { field: 'id', order: 'DESC' },
            filter: query,
        }).then(res=>{
            const mappedData = res.data.map(item => ({ label: item.display_name, value: item.id }))
            setDivisionList(mappedData);
            //  setLoading(false);
            //  setCurrentCount(perCount);
            //  setTotalDivision(res.total);
            // !text && divisionContext.setAllDivision(res.data);
        })
    }

    useEffect(()=>{
        getBranchList(NUMBER.TEN,'');
        getDivisionList(NUMBER.TEN,'');
        }, [])


    const handleChangeBranches=(e , formProps)=>{
        setSelectedBranches(e);
        formProps.form.change('allowed_branches' ,e);
        {formProps.form.getState().values?.allowed_branches.length >= 1 && setIsSelectedBranch(true)}
    }    

    const handleChangeDivision=(e , formProps)=>{
        setSelectedDivisions(e);
        formProps.form.change('allowed_divisions' ,e);
        {formProps.form.getState().values?.allowed_divisions.length >= 1 && setIsSelectedDivision(true)}
    }    

  useEffect(() => {
    if (props?.record) {
      // Prepopulate the checkbox based on the presence of allowed branches/divisions
      setIsSelectedBranch(props.record.allowed_branches?.length > 0);
      setIsSelectedDivision(props.record.allowed_divisions?.length > 0);

      // Prepopulate the CheckPicker with allowed branches and divisions
      setSelectedBranches(props.record.allowed_branches_objIds || []);
      setSelectedDivisions(props.record.allowed_divisions_objIds || []);
    }
  }, [props.record]);
    return (
        <DialogFormWithRedirect
            dynamicTitle={props?.record?.display_name ? `Modify ${props?.record.display_name} Information`:'Create Team Member'}
            {...props}
            record={newRecord}
            decorators={calculator}
            initialValues={setUsertype()}
            toolbar={<EmployeeSaveToolbar />}
            render={(formProps: any) => {
                return (
                    <Stack direction="column">
                        {/* <div className='wage-change-alert'><span>{TICK_ICON_FILLED_02()}</span> Wage changed</div> */}
                        {/* <div className='disable-notification-tagline restrict-access-tagline'>{LOCK_ICON()} This user have disbled all notifications.</div> */}
                        {record?.is_disable_all_notification ? <div className='disable-notification-tagline'> <NotificationIcon /> This user have disbled all notifications. <Button className='disable-notification-edit' onClick={()=>navigate('/setting/notifications',{state:{record:props?.record,tab:'Users'}})}>Edit</Button></div> :''}
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            sx={{ width: '100%' }}
                            className="team-first-last"
                            spacing={2}
                        >
                            <TextInput
                                fullWidth
                                source="first_name"
                                validate={required()}
                                className='team-form-input'
                                size='small'
                            />
                            <TextInput
                                fullWidth
                                source="last_name"
                                validate={required()}
                                className='team-form-input'
                                size='small'
                            />
                        </Stack>
                        <TextInput source="employee_payroll_number" size='small' className='team-form-input' />
                        {/* {identity?.company?.allow_job_position &&
                        <FunctionField
                            source='position_id'
                            fullWidth
                            className='function-field'
                            render={(record: any) => (
                                <Stack direction='row' sx={{width:'100%'}} >
                                    <StyledReferenceInputFull source='position_id' label={'resources.employees.fields.position_id'} reference='positions' fullWidth sx={{pr:1}}>
                                        <RaAutocompleteInput
                                            fullWidth
                                            showCreateButton={true}
                                            create={<CreateJobPosition />}
                                            edit={<EditJobPosition/>} />
                                    </StyledReferenceInputFull>
                                    {record.has_closed_period && record.position_history_ids &&
                                        <Field name={'position_id'} subscription={{ value: true }}>
                                            {({ input: { value } }) => {
                                                return (<>
                                                    {value!==record.position_id && <DatePickerInput
                                                        fullWidth
                                                        sx={{pl:1}}
                                                        source='position_effective_date'
                                                        validate={required()}
                                                        minDate={minDate}
                                                    />}</>
                                                );
                                            }};
                                        </Field>

                                    }
                                </Stack>
                            )}
                    />} */}
                        <FormDataConsumer>
                            {({ formData: { user_type, is_propay_user } }) => {
                                return (
                                    <FunctionField
                                        source="base_wage"
                                        fullWidth
                                        render={(record: any) => (
                                            <Stack
                                                direction="row"
                                                sx={{ width: '100%' }}
                                            >
                                                <MoneyInput
                                                    fullWidth
                                                    source="base_wage"
                                                    validate={
                                                        user_type ===
                                                        USER_TYPE.worker ||
                                                        is_propay_user
                                                            ?  validateWageNonZero : []
                                                    }
                                                    //sx={{ pr: 1 }}
                                                    className='team-form-input'
                                                    size='small'
                                                />
                                                {record.has_closed_period && (
                                                    <Field
                                                        name={'base_wage'}
                                                        subscription={{
                                                            value: true,
                                                        }}
                                                    >
                                                        {({
                                                            input: { value },
                                                        }) =>
                                                            value !=
                                                                record.base_wage && (
                                                                <DatePickerInput
                                                                    fullWidth
                                                                    source="effective_date"
                                                                    validate={required()}
                                                                    sx={{
                                                                        pl: 1,
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                )}
                                            </Stack>
                                        )}
                                    />
                                );
                            }}
                        </FormDataConsumer>

                    {/* Customize Settings accordion */}
                    <FormDataConsumer>
                            {({ formData }) => {
                                return (
                    <Accordion className='team-setting-acc' expanded={props?.record?.display_name} >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    Customize Settings
                    </AccordionSummary>
                    <AccordionDetails className='team-set-detail'>
                    
                        {Object.keys(EMPLOYEE_ADDITIONAL_SETTING).map(item => {
                            return(
                            <div className='t-s-d-row'>
                            <FormControlLabel control={<Checkbox checked={formData[item]} onChange={(e)=>handleSettingChange(e.target.checked,formProps.form,item)} />} label={EMPLOYEE_ADDITIONAL_SETTING[item].heading} />
                        
                            <InfoLabel
                            className="remove-bonus-tooltip"
                            sx={{ height: 20 }}
                            icon="ri:error-warning-fill"
                            height={20}
                            >
                            <Typography className="bonus-tooltip-cs">
                            {EMPLOYEE_ADDITIONAL_SETTING[item].info}
                            
                            </Typography>
                            </InfoLabel>
                            </div>
                        )})}
                   
                    </AccordionDetails>
                    </Accordion>
                )}}
                    </FormDataConsumer>
                    {/* Customize Settings accordion */}

                    {!props?.hideRoleInput && <FunctionField
                      render={record => (
                        <>
                          {!record.is_owner && (
                            <RadioButtonGroupInput
                              source="user_type"
                              variant="standard"
                              defaultValue={USER_TYPE.worker}
                              choices={user_type_selection}
                              validate={required()}
                              disabled={!allow_user_type_access}
                              sx={{
                                '.MuiFo1rmHelperText-root': {
                                  display: 'none',
                                },
                              }}
                            />
                          )}
                        </>
                      )}
                    />}
              <FormDataConsumer>
            {({ formData }) => (formData?.user_type === "manager" && props?.record?.user_id !== identity?.uid) && (
              <div className='manager-p-row'>
                    <p>
                      Personalize <strong>Manager</strong> Permission
                    </p>
                    <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isSelectedBranch}/>} label="Restrict Access by Branches" 
                                            onClick={(e)=>handleBranchCheckboxClick(
                                                e ,
                                                formProps)}

                    />
                    <CheckPicker data={branchList} className='custom-check-picker' menuClassName='custom-rs-check-picker' 
                    onChange={(e)=>{handleChangeBranches(e , formProps)}}
                    placeholder='Select Branch' sticky style={{ minWidth: 250 }} 
                    value={selectedBranches}
                    />

                    <FormControlLabel control={<Checkbox checked={isSelectedDivision}/>} label="Restrict Access by Division"
                                            onClick={(e)=>handleDivisionCheckboxClick(e , formProps)}
                    
                    />
                    <CheckPicker
                        data={divisionList} className='custom-check-picker' menuClassName='custom-rs-check-picker' placeholder='Select Division'
                    onChange={(e)=>{handleChangeDivision(e , formProps)}}
                    value={selectedDivisions}
                    />
                    </FormGroup>
              </div>
                )}
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData }) => formData?.user_type === "crew_lead" && (
                    <div className='crew-lead-user-type'>
                    <p>
                      Personalize <strong>Crew Lead</strong> Permission
                    </p>
                    <Stack sx={{ paddingY: '16px' }}>
                      <BooleanInput
                        source='is_crew_able_to_see_worker_wages'
                        label='resources.employees.choices.crew_lead_permissions.worker_wages'
                        helperText={false}
                        defaultValue={false}
                        formControl="CheckBox"
                      />
                      <BooleanInput
                        source='is_crew_able_to_assign_propays'
                        label='resources.employees.choices.crew_lead_permissions.workers_to_propays'
                        helperText={false}
                        defaultValue={true}
                        formControl="CheckBox"
                      />
                    </Stack>
                    </div>
                )}
              </FormDataConsumer>
              {!props?.hideRoleInput && (
                <Stack direction="row" sx={{ width: '100%' }}>
                  {/* Commenting this code to hide this flag from ui. as we no longer need this. I am not removing this code as we night need this cod ein future for super admin user role*/}
                  {/* {allow_user_type_access && (
                                <BooleanInput
                                    fullWidth
                                    source="is_propay_user"
                                    sx={{
                                        width: '100%',
                                        '.MuiF1or1mHelperText-root': {
                                            display: 'none',
                                        },
                                    }}
                                />


                        {!props?.hideRoleInput && <FunctionField
                            render={record => (
                                <>
                                    {!record.is_owner && (
                                        <RadioButtonGroupInput
                                            source="user_type"
                                            variant="standard"
                                            defaultValue={USER_TYPE.worker}
                                            choices={user_type_selection}
                                            validate={required()}
                                            disabled={!allow_user_type_access}
                                            sx={{
                                                '.MuiFo1rmHelperText-root': {
                                                    display: 'none',
                                                },
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        />}
                        
                        {!props?.hideRoleInput && <Stack direction="row" sx={{ width: '100%' }}>
                            {/* Commenting this code to hide this flag from ui. as we no longer need this. I am not removing this code as we night need this cod ein future for super admin user role*/}
                            {/* {allow_user_type_access && (
                                <BooleanInput
                                    fullWidth
                                    source="is_propay_user"
                                    sx={{
                                        width: '100%',
                                        '.MuiF1or1mHelperText-root': {
                                            display: 'none',
                                        },
                                    }}
                                />
                            )} */}

                            <FunctionField
                                fullWidth
                                sx={{ width: '100%' }}
                                render={record => (
                                    <>
                                        {!(
                                            identity?.company
                                                ?.tsheets_status === 'connected'
                                        ) &&
                                            allow_all_workers_to_add_time &&
                                            record.user_type === USER_TYPE.worker && (
                                                <BooleanInput
                                                    fullWidth
                                                    source="allow_to_add_time"
                                                    sx={{
                                                        width: '100%',
                                                        '.MuiFo1rmH1elperText-root': {
                                                            display: 'none',
                                                        },
                                                    }}
                                                />
                                            )}
                                    </>
                                )}
                            />
                        </Stack>)}
                        <PhoneInput fullWidth source="mobile_number" className='team-form-input' size='small' />
                        <TextInput
                            fullWidth
                            source="email"
                            validate={[email()]}
                            className='team-form-input'
                            size='small'
                        />
            </Stack>
                );
            }}
        />
    );
};

const createEmployeeoptions = ['Save & Close','Save & New'];
const editEmployeeoptions = ['Save'];

export const EmployeeCreateDialog = (props: any) => {
    const {buttonOptions} = props;
    return (
        <Create
            {...props}
            mutationMode={'pessimistic'}
            component="div"
            title={<DialogTitleForRecord />}
        >
          <EmployeeDialogForm {...props} buttonOptions={buttonOptions ? buttonOptions : createEmployeeoptions}/>
        </Create>
    );
};
export const EmployeeEditDialog = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode={'pessimistic'}
            component="div"
            title={<DialogTitleForRecord />}
        >
          <EmployeeDialogForm {...props} buttonOptions={editEmployeeoptions}/>
        </Edit>
    );
};
