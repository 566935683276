import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField } from '@mui/material';
import Select from 'react-select';
import { CRUD_UPDATE, useDataProvider, useMutation,useNotify } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useQueryClient } from 'react-query';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { POST } from '../../services/HttpService';
import { BONUS_BULK_ACTION_API, GENERAL_INFO } from '../../constants';
export const PayBonusModal = (props) => {
    const {
        isOpen = false,
        onClose,
        recordIds,
        refreshData,
        isBulk
    } = props;
    const [listData,setListData]=useState([]);
    const [selectedPeriod,setSelectedPeriod]=useState(null);
    const [mutate] = useMutation();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [loading,setLoading]=useState(false);
    const bonusContext = useContext(CustomFilterContext);
    useEffect(()=>{
      setLoading(true);
      dataProvider.getList('Period__DropdownList', {
        sort: { field: 'start_date', order: 'DESC' },
        pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
        filter: {},
    }).then(dt=>{
        setLoading(false);
        setListData(dt.data);
    });
    },[])
    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    const getOptions = data =>data.length > NUMBER.ZERO ? data.map(ele=>({value:ele.id,label:ele.name})):[];

    const closeStatements = ()=>{
        const data = {
            statement_ids:recordIds,
            paid_period_id:selectedPeriod.value
        };
        POST(BONUS_BULK_ACTION_API.pay_bonus,data).then((response:any[])=>{
            if(isBulk){
            bonusContext.setShowToast(true);
            bonusContext.setShowToastAction(true)
            const pendingItems = response.filter(item=>item.is_success);
            bonusContext.setCustomMessage(`${pendingItems.length}/${recordIds.length}  Statements closed!`);
            bonusContext.setBulkActionResponse(response); 
            }else{
                onClose();
                queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
                notify('Successfully! Statement closed');
            }
            refreshData();
        }).catch(error=>{
            notify(`Failure ! ${error.message}`);
        })
        if(isBulk){
            onClose();
            notify(GENERAL_INFO.soonNotify); 
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            className='void-modal'
        >
        <DialogTitle id="alert-dialog-title">
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                Close Statement
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="void modal"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            </DialogTitle>
          <DialogContent>
            <div className='void-paragraph'>Please select pay period to add bonuses:</div>
            <div className='void-desc-textfield'>
            <Select
                                    value={selectedPeriod}
                                    onChange={(e) => setSelectedPeriod(e)}
                                    className='basic-single no-translate'
                                    placeholder='Pay Period'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='PayPeriod'
                                    isLoading={loading}
                                    classNamePrefix='react-select-unique react-select-void-unique'
                                    required
                                    options={getOptions(listData)}
                                    {...props} menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                />
            </div>

            </DialogContent>
            <DialogActions className='button-justified'>
            <Button className='cancel-button' onClick={()=>onClose()}>Cancel</Button>
                <Button className='update-button' onClick={()=>closeStatements()}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

