import {
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';

const CustomOptionStatusFilter = props => {
    const { statusOptions, searchTag, closeSubFilter, updateSearchTag, subTitle } = props;
    const statusFilter = searchTag?.filter(
        element => element.tag === subTitle
    );
    const prevSelected =
        statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : null; // Only one selected
    const [selectedStatus, setSelectedStatus] = useState(prevSelected);
    const translate = useTranslate();

    const applyFilter = () => {
        const updatedTags = searchTag.filter(ele => ele.tag !== subTitle);
        const selectedStatusName = statusOptions.find(option => option.id === selectedStatus)?.name; 
        updateSearchTag(updatedTags, [
            {
                tag: subTitle,
                name: selectedStatusName,
                count: 1, // Only one selected, so count is 1
                selected_ids: selectedStatus, // Set as an array with one item
            },
        ]);
        closeSubFilter();
    };

    return (
        <>
            <div className="filter-parent-scroll filter-custom-scroll">
            <div className="automation-radios filter-radios">
                <RadioGroup value={selectedStatus} onChange={(e) =>{
                    setSelectedStatus(e.target.value)}}>
                  {statusOptions.map(ele => (
                        <FormControlLabel
                            key={ele.id}
                            control={<Radio />}
                            label={translate(ele.name)}
                            value={ele.id}
                        />
                    ))}
                </RadioGroup>
            </div>
            </div>
            <Button
                className="apply-filter-button"
                onClick={applyFilter}
                disabled={!selectedStatus} // Disable if no selection is made
            >
                Apply Filter
            </Button>
        </>
    );
};

export default React.memo(CustomOptionStatusFilter);