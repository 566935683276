import { Button, Menu, MenuItem, Popover } from '@mui/material';
import { BREADCRUMB_BACK_ICON, CHIP_CLOSE_ICON, CLOSE_ICON, FILTER_ADD_ICON, FILTER_ICON } from '../utils/Constants/ClassName';
import React from 'react';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { LEADERBOARD_FILTER_OPTIONS, LEADERBOARD_USER_FILTER_STATUS } from '../constants';
import { useGetIdentityOptimized } from '../components/identity';
import { getCustomLeaderboardQuery } from '../components/fields';
import CustomOptionStatusFilter from '../layout/CustomOptionStatusFilter';
import _ from 'lodash';

const LeaderboardFilter = props => {
    const { setFilterQuery } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorSub, setAnchorSub] = React.useState(null);
    const [subTitle, setSubTitle] = React.useState('');
    const [searchTag, setSearchTag] = React.useState([]);
    const { identity } = useGetIdentityOptimized();
    const debounceTimeout = React.useRef(null);
    const [removedChip, setRemovedChip] = React.useState([]);
    const checkNull = (value:string) => value === null;
    const removeFilter = tag => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const updateTags = removedChip.filter(el => el.tag !== tag);
        setRemovedChip(updateTags);
        debounceTimeout.current = setTimeout(() => {
            setSearchTag(updateTags);
        }, 1000);
    };
    const open = Boolean(anchorEl);
    const filterOptions = Object.values(LEADERBOARD_FILTER_OPTIONS);
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const openSelectedFilter = (event, title) => {
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    };

    React.useEffect(()=>{
           const prevAppliedFilters  = getLocalStorageValues('leaderBoardAppliedFilters');
        if(!(checkNull(prevAppliedFilters))){
            setSearchTag(JSON.parse(prevAppliedFilters));
        }else{
            setSearchTag([{
                tag: "User Status",
                name:   "Active",
                count: NUMBER.ONE,
                selected_ids: "active",
            }])

        }
     },[])

    React.useEffect(() => {
        if (searchTag.length > NUMBER.ZERO) {
            setFilterQuery(getCustomLeaderboardQuery(searchTag));
        }else {
            setFilterQuery({status: 'active'});
        }
        localStorage.setItem('leaderBoardAppliedFilters',JSON.stringify(searchTag))
        setRemovedChip(searchTag);
    }, [searchTag]);

    const clearAll = () => {
        handleClose();
        setSearchTag([]);
    };

    return (
        <>
            <div className="dashboard-box-panel l-b-filter-box">
                <div className="propay-filter-toolbar">
                    <div className="propay-column-row">
                        <div className="propay-column-item filter-add-item">
                            {FILTER_ICON()}
                            Filter:
                            <>
                                {removedChip.length > NUMBER.ZERO
                                    ? removedChip.map(el => {
                                          return (
                                              <>
                                                  <div className="filter-chip">
                                                      <Button
                                                          className="filter-chip-button"
                                                          key={el.name}
                                                          onClick={event => openSelectedFilter(event, el.tag)}
                                                      >
                                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                                          {(el.count > NUMBER.ZERO && el.tag !==  "User Status") ? (
                                                              <span className="filter-chip-plus">+{el.count}</span>
                                                          ) : (
                                                              ''
                                                          )}
                                                      </Button>
                                                      <Button onClick={() => removeFilter(el.tag)}>
                                                          {CHIP_CLOSE_ICON()}
                                                      </Button>
                                                  </div>
                                              </>
                                          );
                                      })
                                    : ''}
                                {removedChip.length > NUMBER.TWO && (
                                    <Button
                                        className="clear-filter-all"
                                        onClick={() => clearAll()}
                                    >
                                        Clear All
                                    </Button>
                                )}
                            </>
                            <Button
                                className="filter-add-btn"
                                onClick={handleClick}
                                aria-controls={open ? 'card-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                {FILTER_ADD_ICON()}
                                Add
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                id="filter-menu"
                                className="filter-dropdown"
                                open={open}
                                onClose={handleClose}
                                disableScrollLock={true}
                                PaperProps={{
                                    elevation: NUMBER.ZERO,
                                    sx: {
                                        overflow: 'visible',
                                        borderWidth: '1px 0px 0px 1px',
                                        mt: 1.5,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: -1,
                                            left: NUMBER.FIFTEEN,
                                            width: NUMBER.FIFTEEN,
                                            height: NUMBER.FIFTEEN,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: NUMBER.ZERO,
                                            borderWidth: '1px 0px 0px 1px',
                                            borderColor: '#B8C0C6',
                                            borderStyle: 'solid',
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                            >
                                {' '}
                                <div className="filter-head">
                                    Filters
                                    <button className="close-filter" onClick={handleClose}>
                                        {CLOSE_ICON()}
                                    </button>
                                </div>
                                <div className="filter-parent-scroll filter-custom-scroll">
                                    {filterOptions.map(item => {
                                        return (
                                            <>
                                                <MenuItem key={item} onClick={() => handleItemClick(item)}>
                                                    {item}
                                                    <button className="filter-arrow">Arrow</button>
                                                </MenuItem>
                                            </>
                                        );
                                    })}
                                </div>
                            </Menu>
                            <SubMultiFilter
                                anchorSub={anchorSub}
                                setSearchTag={setSearchTag}
                                searchTag={searchTag}
                                setAnchorSub={setAnchorSub}
                                subTitle={subTitle}
                                setAnchorEl={setAnchorEl}
                                identity={identity}
                                removeFilter={removeFilter}
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeaderboardFilter;

export const SubMultiFilter = props => {
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const closeSubFilter = () => {
        setAnchorSub(null);
    };
 
    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };
    
    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status' , 'User Status'];
        const SelectedTag = element[NUMBER.ZERO];
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };
const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }
    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div> 
                </MenuItem>
                {subTitle === LEADERBOARD_FILTER_OPTIONS.user_status && (

                    <CustomOptionStatusFilter setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={LEADERBOARD_USER_FILTER_STATUS}
                    subTitle={subTitle}
                    />
                )}
            </Popover>
        </>
    );
};