import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    IconButton,
    Stack,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useDataProvider } from 'react-admin';
import { useIdentityContext } from '../components/identity';
import {
    AUTO_SAVE_CLOSE,
    DASH_SETTING_ICON,
    EDIT_ICON_02,
    REFRESH_ICON,
    TICK_ICON_FILLED,
    TICK_ICON_FILLED_02,
    TOAST_INFO_ICON,
    ZOOM_ICON
} from '../utils/Constants/ClassName';
import BonusCalculatorDetailPage from './BonusCalculatorDetailPage';
import DashboardIndicators from './DashboarIndicators';
import DashboardCountCards from './DashboardCountCards';
import DashboardLeaderboard from './DashboardLeaderboard';
import DashboardSettingDialog from './DashboardSettingDialog';
import { capitalizeFirstLetter, LEADERBOARD_CONFIG , USER_TYPE } from '../utils/Constants/ConstantData';
import IdentityContext from '../components/identity/IdentityContext';
import ThemeWrapper from '../layout/ThemeWrapper';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import  LeaderboardFilter  from './LeaderboardFilter';

const DASHBOARD_GRID_OPTIONS = [
    { label: 'Indicators', value: 'Indicators' },
    { label: 'Propay Count', value: 'Propay Count' },
    { label: 'Leader Board', value: 'Leader Board' },
]
const renderComponent = (value: string, companyData, identity, fullScreen, refetchCount , leaderboardFilter) => {
    const leaderboardsConfig = JSON.parse(companyData?.leaderboards_config || '{}');

    const shouldShowLeaderboard = () => {
        if (identity?.employee_id?.toString() === 'false') {
            return true;
        }

        for (const key in leaderboardsConfig) {
            if (leaderboardsConfig[key].show_to && leaderboardsConfig[key].show_to.includes(capitalizeFirstLetter(identity?.user_type?.toString()))) {
                return true;
            }
        }

        return false;
    };

    switch (value) {
        case 'Indicators':
            return <DashboardIndicators companyData={companyData} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Propay Count':
            return <DashboardCountCards companyData={companyData} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Leader Board':{
            const showLeaderBoard = shouldShowLeaderboard();
            if(showLeaderBoard){
                return <DashboardLeaderboard companyData={companyData} fullScreen={fullScreen} refetchCount={refetchCount}
                leaderboardFilter={leaderboardFilter} 
                />;
            }else{
                return <></>;
            }
        }
        default:
            return <></>;
    }
};


const OverviewBonuses = (props: any) => {
    const { fullScreen, refetchCount, handleHardRefresh, count } = props;
    const [SetProPayGoalsopen, SetProPayGoalssetOpen] = React.useState(false);
    const [dashboardSettingOpen, SetDashSettingsetOpen] = React.useState(false);
    const identity = useIdentityContext();
    const dataProvider = useDataProvider();
    const [dashboardGrid, setDashboardGrids] = React.useState([]);
    const [companyData, setCompanyData] = React.useState({});
    const [leaderboardFilter , setLeaderboardFilter]= React.useState({});

    React.useEffect(() => {
        const dashboardGrids = fullScreen ? sessionStorage?.getItem('dashboard_grid') : localStorage?.getItem('dashboard_grid');
        if(!dashboardGrids){
            const visibleoptions = DASHBOARD_GRID_OPTIONS?.map((item, index)=>(index < 4 ? {...item, is_visible: true, just_saved:false}: {...item, is_visible: false, just_saved:false}));
            fullScreen ? sessionStorage.setItem('dashboard_grid', JSON.stringify(visibleoptions)) : localStorage.setItem('dashboard_grid', JSON.stringify(visibleoptions));
            setDashboardGrids(visibleoptions);
        }else{
            setDashboardGrids(JSON.parse(dashboardGrids));
        }
    }, [fullScreen]);

    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            if (!identity?.company_id) return;
    
            try {
                const { data } = await dataProvider.getOne('Company__companyLeaderboardsConfig', { id: identity.company_id });
                const leaderboardsConfig = data?.leaderboards_config ? JSON.parse(data.leaderboards_config) : {};

                /*  This is a temporary solution. Until the backend team fixes the issue, 
                    we will set the default leaderboard configuration from the UI side 
                    for a new company or any company that does not have a default leaderboard config.
                */

                if (_.isEmpty(leaderboardsConfig)) {
                    await dataProvider.update('company', { 
                        id: identity.company_id, 
                        data: { id: identity.company_id, leaderboards_config: JSON.stringify(LEADERBOARD_CONFIG) }, 
                        previousData: { id: identity.company_id, leaderboards_config: JSON.stringify({}) } 
                    });    
                    setCompanyData({
                        id: identity.company_id,
                        leaderboards_config: JSON.stringify(LEADERBOARD_CONFIG),
                        name: identity.company?.name
                    });
                } else {
                    setCompanyData(data);
                }
            } catch (error) {
                console.error('Error fetching or updating company data:', error);
            }
        };
    
        allPropaysApiCall();
    }, [dataProvider, identity]);
    
    const SetProPayGoalshandleClickOpen = () => {
        SetProPayGoalssetOpen(true);
    };

    const SetProPayGoalshandleClose = () => {
        SetProPayGoalssetOpen(false);
    };

    const SetDashSettinghandleClickOpen = () => {
        SetDashSettingsetOpen(true);
    };

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const getTimeFromCount = () => {
        const minutes = Math.floor(count / 60);
        const remainingSeconds = count % 60;
        return `${minutes ? minutes+' min  & ':''} ${remainingSeconds>9 ? remainingSeconds : '0'+remainingSeconds} sec`;
    }

    const [showtoast, setShowToast] = React.useState(false);
    const handleShowToast = (check) => {
        if (check) {
            setShowToast(false);
        } else {
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000)
        }
    }
    const restrictedUserTypes = [USER_TYPE.worker, USER_TYPE.crew_lead];

    return (
        <div className={`${fullScreen ? 'full-screen-dashboard' : ''}`}>
            {/* <DashboardShimmer /> */}

            {/* <div className='dash-setting-save'>
            <span className='dash-save-svg'>
            {TICK_ICON_FILLED_02()}
            </span> Leaderboard Changes Saved!
            <Button className='close-dash-save'>{AUTO_SAVE_CLOSE()}</Button>
            </div> */}

            {showtoast && <div className='dash-setting-save info-dash-setting'>
            <span className='dash-save-svg'>
            {TOAST_INFO_ICON()}
            </span> Please wait for {40-(count > 40 ? 40 : count)} seconds before refreshing the page.
            <Button className='close-dash-save' onClick={()=>handleShowToast(true)}>{AUTO_SAVE_CLOSE()}</Button>
            </div>}

            <Box className="overview-head">
                <div className="overview-head-left">
                    <Typography variant="h2" className="overview-head-title">
                        Overview
                        {/* {OVERVIEW_EDIT_ICON()} */}
                    </Typography>
                    {!fullScreen && <div className="switch-company-name">
                        Company<span>-</span>{' '}
                        <strong className="no-translate">
                            {identity?.company?.name ? identity?.company?.name  : ''}
                        </strong>
                    </div>}
                </div>
                <div className="overview-head-right">
                    {/* {!isSmall ? (
                        <Button
                            className="set-propay-goal-btn"
                            onClick={SetProPayGoalshandleClickOpen}
                        >
                            Set ProPay Goals
                        </Button>
                    ) : (
                        <></>
                    )} */}
                    {fullScreen &&
                    <div className='dash-refreshed'>
                    <div className='dash-refresh-text'>Last update {getTimeFromCount()} ago</div>
                    <Button className='dash-refresh-btn' onClick={()=> count<41 ? handleShowToast(false) : handleHardRefresh()}>{REFRESH_ICON()} Refresh</Button>
                    </div>
                    }
                    <Button
                        className="dash-overview-setting dash-set-tp"
                        onClick={SetDashSettinghandleClickOpen}
                    >
                        {DASH_SETTING_ICON()}
                    </Button>
                    {!fullScreen &&
                    <Tooltip id='unique-tooltip' title='Click to add full screen tabs!' placement="top" arrow>
                        <Button className="overview-edit" onClick={()=> window.open(`${window.location.origin}/full-dashboard`, '_blank')}>
                         {EDIT_ICON_02()}
                        </Button>
                    </Tooltip>
                    }
                </div>
            </Box>
            {
                !restrictedUserTypes.includes(identity?.user_type) && <LeaderboardFilter setFilterQuery={setLeaderboardFilter} />
                }
            {dashboardGrid?.length ? dashboardGrid?.map((grid) => (
                <>
                    {grid?.is_visible && renderComponent(grid?.value, companyData, identity, fullScreen, refetchCount , leaderboardFilter)}
                </>
            )) : ''}
            {/* <DashboardIndicators /> */}
            {/* <ActualAndBudgetHoursGraph /> */}
            {/* <DashboardCountCards /> */}
            {/* <ClosedPropayGraph /> */}
            {/* <TotalPaidBonuses /> */}
            {/* <DashboardLeaderboard /> */}
            {/* {isSmall ? (
                <div className="set-propay-p-inline">
                    <Button
                        className="set-propay-goal-btn"
                        onClick={SetProPayGoalshandleClickOpen}
                        fullWidth
                    >
                        Set ProPay Goals
                    </Button>
                </div>
            ) : (
                <></>
            )} */}
            {/* Bonus Goals Dialog */}
            <Dialog
                open={SetProPayGoalsopen}
                onClose={SetProPayGoalshandleClose}
                aria-labelledby="alert-dialog-title"
                className="create-propay-modal bonus-goal-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        width="100%"
                    >
                        <IconButton
                            sx={{ top: -2, padding: 0 }}
                            color="primary"
                            aria-label="Leaderboard"
                            onClick={SetProPayGoalshandleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent className="dash-dialog-cont">
                    <div className="indicator-heading">Bonus Goals</div>
                    <BonusCalculatorDetailPage />
                    {/* {record={record} maxHours={maxHours} workerPreview={workerPreview}} */}
                </DialogContent>
                <DialogActions
                    className="button-justified"
                    sx={{ justifyContent: 'center!important' }}
                >
                    <Button
                        className="cancel-button"
                        onClick={SetProPayGoalshandleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Bonus Goals Dialog */}
            <DashboardSettingDialog
                fullScreen={fullScreen}
                setDashboardGrids={setDashboardGrids}
                allGrids={dashboardGrid}
                dashboardSettingOpen={dashboardSettingOpen}
                SetDashSettingsetOpen={SetDashSettingsetOpen}
            />
        </div>
    );
};
export default OverviewBonuses;

export const DashboardFullScreenView = () => {

    const navigate = useNavigate();
    const [refetchCount, setRefetchCount] = React.useState(1);
    const fullScreenRefresh = React.useRef(null);
    const timer = React.useRef(1);
    const [duration, setDuration] = React.useState(0);

    const startTimer = () => {
        if (fullScreenRefresh.current) clearInterval(fullScreenRefresh.current);
        fullScreenRefresh.current = setInterval(() => {
            if(timer.current === 0){
                setRefetchCount(1)
            }else{
                if(timer.current >= 25)
                setRefetchCount(0)
            }
            timer.current = timer.current+1;
            setDuration((prev)=>prev+1);
            if(timer.current>298){
                timer.current = 0;
                setDuration(0);
                setRefetchCount(1);
                clearInterval(fullScreenRefresh.current)
                startTimer();
            }
        }, 1000);
    };

    const handleHardRefresh = () => {
        timer.current = 0;
        setDuration(0);
        startTimer()
    }

    React.useEffect(() => {
        startTimer();
        return () => {
            clearInterval(fullScreenRefresh.current);
        };
    }, []);


    const savegrids = (key) => {
        const dashboardGrids = JSON.parse(sessionStorage?.getItem(key));
        if(!dashboardGrids){
            const dashboardGrid = localStorage?.getItem(key);
            sessionStorage.setItem(key, dashboardGrid);
        }
    }

    React.useEffect(()=>{
        const STORAGE_KEYS = ['dashboard_indicators', 'dashboard_grid', 'dashboard_leaderboard', 'Dashboard_Filters']
        const login = JSON.parse(localStorage.getItem('loginUser'));

        if (!login?.company_id) {
            navigate('/');
        }else{
            STORAGE_KEYS.map((key)=>{
                return savegrids(key)
            })
        }
    },[navigate])

    React.useEffect(() => {
        document.body.classList.add('dashboard-body');
        return () => {
            document.body.classList.add('dashboard-body');
        }
    }, [])

    return (
        <IdentityContext>
            <ThemeWrapper>
                <OverviewBonuses fullScreen={true} refetchCount={refetchCount} handleHardRefresh={handleHardRefresh} count={duration}/>
            </ThemeWrapper>
        </IdentityContext>
    );
};
