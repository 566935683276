import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import {  FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import _ from 'lodash';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import { useIdentityContext } from '../components/identity';
const CustomBranchFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag}=props;
    const [search,setSearch]=useState('');
    const branchContext = useContext(CustomFilterContext);
    const [branchList,setBranchList]=useState([]);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    const [totalBranch,setTotalBranch]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.branches);
    const [branchName,setBranchName]=useState({name:'',ids:prevIds[NUMBER.ZERO]?.selected_ids ? prevIds[NUMBER.ZERO].selected_ids:[]});
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [branchValues,setBranchValue]=useState(prevIds.length ? prevIds[0].selected_obj:{});
    const identity = useIdentityContext();

    useEffect(()=>{
        if(branchContext.allBranch.length > NUMBER.ZERO){
            setBranchList(branchContext.allBranch);
        }else{
            getBranchList(NUMBER.TEN,'');
        }
    },[])
    const getBranchList = (perCount,text)=>{
        setSearch(text);
        const filterBasedOnManager = 
            identity?.user_type === 'manager' && identity?.allowed_branches?.length > 0 
             && { id: { _in: identity.allowed_branches } };
        let query = text ? { name: { _ilike: text } , type: { _eq: 'branch' } , ...filterBasedOnManager}:{  type: { _eq: 'branch' }, ...filterBasedOnManager};
        setLoading(true);
        dataProvider.getList('Branch__DropdownList', {
            pagination: { page: NUMBER.ONE, perPage: perCount },
            sort: { field: 'id', order: 'DESC' },
            filter: query,
        }).then(res=>{
             setBranchList(res.data);
             setLoading(false);
             setCurrentCount(perCount);
             setTotalBranch(res.total);
            !text && branchContext.setAllBranch(res.data);
        })
    }

    useEffect(()=>{},[branchName,branchList])
    const applyFilter = ()=>{
        let len = branchName.ids.length;
        let ct = len > NUMBER.ONE ? branchName.ids.length - NUMBER.ONE :NUMBER.ZERO;
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.branches).length > NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.branches);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.branches,name:branchName.name,count:ct,selected_ids:branchName.ids,selected_obj:branchValues}]);
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.branches,name:branchName.name,count:ct,selected_ids:branchName.ids,selected_obj:branchValues}]);
        }
        closeSubFilter()
    }

    const toggleBranch = (isChecked:any,codeId:any,code:string)=>{
        let ids = branchName.ids.filter(el=>el !== codeId);
         if(isChecked){
            branchValues[codeId]=code;
            setBranchValue(branchValues)
            setBranchName({name:code,ids:[...ids,codeId]});
         }else{
            setBranchName({name:ids.length ? branchValues[ids[NUMBER.ZERO]]:'',ids:ids});
         }
    }

    const isPrevChecked = (empId)=> {
      const values = searchTag ? searchTag?.filter(element=>element.tag === FILTER_OPTIONS.branches):[];
      return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(empId) : false;
    }
    const handleLoadMore = (element)=>{
        if(element.scrollTop + element.offsetHeight >= element.scrollHeight && !loading && (branchList.length < totalBranch || !totalBranch) ){
            getBranchList(currentCount+NUMBER.TEN,search);
        }
    }

    return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search Branch"
                    id="branch-search"
                    name="branch-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{getBranchList(currentCount || NUMBER.TEN,e.target.value)}}
                />
               {search && <Button className='clear-search-filter'>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { branchList.length > NUMBER.ZERO   ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>handleLoadMore(element.currentTarget)}>
                    {' '}
                    {branchList.map(emp => {
                        return (
                            <MenuItem key={emp.id}>
                                <FormControlLabel onChange={(e,checked)=>toggleBranch(checked,emp?.id,emp?.name)}
                                    control={<Checkbox  defaultChecked={isPrevChecked(emp?.id)} />}
                                    label={emp?.name}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
              {(!branchList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !branchName.ids.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomBranchFilter);