import * as React from 'react';
import { Button, Menu, MenuItem, Popover } from '@mui/material';
import { useState,useEffect,useRef,useMemo,useContext} from 'react';
import {
    FILTER_ICON,
    FILTER_ADD_ICON,
    CLOSE_ICON,
    BREADCRUMB_BACK_ICON,
    CHIP_CLOSE_ICON,
} from '../utils/Constants/ClassName';
import { STATEMENT_FILTER_OPTION, USER_TYPE } from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { useListContext, useTranslate } from 'react-admin';
import CustomWorkerFilter from './CustomWorkerFilter';
import { getBonusCustomFilterQuery } from '../components/fields';
import _ from 'lodash';
import CustomManagerFilter from './CustomManagerFilter';
import CustomJobFilter from './CustomJobFilter';
import CustomConditionalFilter from './CustomConditionalFilter';
import { useGetIdentityOptimized } from '../components/identity';
import CustomPropayFilter from './CustomPropayFilter';
import { CustomFilterContext, CustomFilterContextProvider } from './CustomFilterContext';
import CustomPeriodFilter from './CustomPeriodFilter';
import CustomDateFilter from './CustomDateFilter';
import CustomStatusFilter from './CustomStatusFilter';
import CustomBranchFilter from './CustomBranchFilter';
import CustomDivisionFilter from './CustomDivisionFilter';
import { isAllowedFilters } from '../utils/Constants/CommonFunctions';

const CustomBonusFilter = props => {
    const { setBonusFilterQuery,bonusFilterQuery,bonusStatusFilter,setBonusStatusFilter } = props;
    const {perPage,page,filterValues,setPage,setPerPage,currentSort} = useListContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSub, setAnchorSub] = useState(null);
    const [subTitle, setSubTitle] = useState('');
    const [searchTag, setSearchTag] = useState([]);
    const [removedChip, setRemovedChip] = useState([]);
    const open = Boolean(anchorEl);
    const { identity } = useGetIdentityOptimized();
    const filterOptions = Object.values(STATEMENT_FILTER_OPTION);
    const userTypeOptions = identity?.user_type === USER_TYPE.worker ? filterOptions.filter(fl=>fl !== STATEMENT_FILTER_OPTION.worker): filterOptions;
    const checkNull = (value:string) => value === null;
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const filterRef = useRef([]);
    const updataRef =  useRef(true);

    useEffect(()=>{
       const prevAppliedFilters  = getLocalStorageValues('bonusAppliedFilters');
       const selectedPerPage = getLocalStorageValues('bonusPerPage');
       const selectedPage = getLocalStorageValues('bonusPage');

      if(!(checkNull(prevAppliedFilters) && checkNull(getLocalStorageValues('bonusFromDateFilter')))){
          setSearchTag(JSON.parse(prevAppliedFilters));
      }
      !checkNull(selectedPerPage) && setPerPage(Number(selectedPerPage));
      !checkNull(selectedPage) && setPage(Number(selectedPage));
      return()=>{
         localStorage.setItem('bonusPerPage',filterRef?.current[NUMBER.ZERO]);
         localStorage.setItem('bonusPage',filterRef?.current[NUMBER.ONE]);
         localStorage.setItem('bonusSort',filterRef?.current[NUMBER.TWO]);
      }
    },[])
    useEffect(() => {
        if (searchTag.length > NUMBER.ZERO || !checkNull(getLocalStorageValues('bonusFromDateFilter'))){
            setBonusFilterQuery(getBonusCustomFilterQuery(searchTag , identity));
        } else {
            const queryExtended = {
                parent_id: { _is_null: true },
                employee_id_obj: {
                  ...(identity?.allowed_branches?.length > 0 && { branch_id: { _in: identity.allowed_branches } })
                }
              }
              const baseQuery = {parent_id:{_is_null:true}};
            isAllowedFilters(identity ,queryExtended ,baseQuery ,  setBonusFilterQuery )
        }
         localStorage.setItem('bonusAppliedFilters',JSON.stringify(searchTag))
         setRemovedChip(searchTag);
    }, [searchTag]);

    useEffect(()=>{
     filterValues.hasOwnProperty('statement_period_id_obj') && localStorage.setItem('bonusFromDateFilter',JSON.stringify(filterValues['statement_period_id_obj'].start_date));
    if(bonusFilterQuery.hasOwnProperty('statement_period_id_obj') && !filterValues.hasOwnProperty('statement_period_id_obj')){
     setBonusFilterQuery(getBonusCustomFilterQuery(searchTag ,identity ));
    }
    },[filterValues])

    useEffect(()=>{
        filterRef.current  = [perPage.toString(),page.toString(),JSON.stringify(currentSort)];
    },[perPage,currentSort,page])

    useEffect(()=>{
        if(updataRef.current){
            updataRef.current = false;
            return;
        }
      const filterTag = searchTag.filter(item => item.tag !== STATEMENT_FILTER_OPTION.status);
      const index = bonusStatusFilter ?  bonusStatusFilter.length - 1: -1;
      index >= 0 ? setSearchTag([...filterTag,{
        tag: STATEMENT_FILTER_OPTION.status,
        name: bonusStatusFilter[index] === 'paid' ? 'Closed' : bonusStatusFilter[index],
        count: index > NUMBER.ZERO ? index : NUMBER.ZERO,
        selected_ids: bonusStatusFilter,
    }]):setSearchTag(filterTag)

    },[bonusStatusFilter])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const debounceTimeout = React.useRef(null);
    const removeFilter = tag => {
        if(tag === STATEMENT_FILTER_OPTION.status){
            setBonusStatusFilter([]);
        }
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const updateTags = removedChip.filter(el => el.tag !== tag);
        setRemovedChip(updateTags);
        debounceTimeout.current = setTimeout(() => {
            setSearchTag(updateTags);
        }, 1000);
    };
    const clearAll = () => {
        handleClose();
        setSearchTag([]);
        setBonusStatusFilter([]);
    };
    const openSelectedFilter = (event,title)=>{
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    }
    return (
        <>
        <CustomFilterContextProvider>
            <div className="propay-column-item filter-add-item">
                {FILTER_ICON()}
                Filter:
                <>
                    {removedChip.length > NUMBER.ZERO
                        ? removedChip.map(el => {
                              return (
                                  <>
                                    <div className="filter-chip">
                                      <Button
                                          className="filter-chip-button"
                                          key={el.name}
                                          onClick={(event) => openSelectedFilter(event,el.tag)}
                                      >
                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                          {el.count > NUMBER.ZERO ? (
                                              <span className="filter-chip-plus">
                                                  +{el.count}
                                              </span>
                                          ) : (
                                              ''
                                          )}{' '}
                                      </Button>
                                      <Button
                                              onClick={() =>
                                                  removeFilter(el.tag)
                                              }
                                          >
                                              {CHIP_CLOSE_ICON()}
                                      </Button>
                                      </div>
                                  </>
                              );
                          })
                        : ''}
                    {removedChip.length > NUMBER.TWO && (
                        <Button
                            className="clear-filter-all"
                            onClick={() => clearAll()}
                        >
                            Clear All
                        </Button>
                    )}
                </>
                {userTypeOptions.length !== removedChip.length && <Button
                    className="filter-add-btn"
                    onClick={handleClick}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {FILTER_ADD_ICON()}
                    Add
                </Button>}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="filter-menu"
                className="filter-dropdown"
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    sx: {
                        overflow: 'visible',
                        borderWidth: '1px 0px 0px 1px',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -1,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {' '}
                <div className="filter-head">
                    Filters
                    <button className="close-filter" onClick={handleClose}>
                        {CLOSE_ICON()}
                    </button>
                </div>
                <div className="filter-parent-scroll filter-custom-scroll">
                    {userTypeOptions.map(item => {
                        return (
                            <MenuItem
                                key={item}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                                <button className="filter-arrow">Arrow</button>
                            </MenuItem>
                        );
                    })}
                </div>
            </Menu>
            <SubMultiFilter
                anchorSub={anchorSub}
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                setAnchorSub={setAnchorSub}
                subTitle={subTitle}
                setAnchorEl={setAnchorEl}
                identity={identity}
                removeFilter={removeFilter}
                setBonusStatusFilter={setBonusStatusFilter}
            />
            </CustomFilterContextProvider>
        </>
    );
};

export default  React.memo(CustomBonusFilter);

export const SubMultiFilter = props => {
    const translate = useTranslate();
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter,
        setBonusStatusFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const statusFilter = searchTag?.filter(element=>element.tag === STATEMENT_FILTER_OPTION.status);
    const prevSelected = statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : [];
    const [status, setStatus] = useState(prevSelected);
    const periodContext = useContext(CustomFilterContext);
    const BONUS_STATUS = [
        { id: 'pending', name: 'Pending' },
        { id: 'paid', name: 'resources.propays.choices.status.paid' },
    ];
    useEffect(()=>{
        setStatus(prevSelected)
    },[prevSelected.length])
    const closeSubFilter = () => {
        setAnchorSub(null);
        setStatus([])
    };

    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };

    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status','ProPays','Workers','Managers','Jobs','Statement Period'];
        const SelectedTag = element[NUMBER.ZERO];
        if(SelectedTag.tag === STATEMENT_FILTER_OPTION.status){
            setBonusStatusFilter(SelectedTag.selected_ids)
        }
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };

    const removeDuplicate = (statusArr)=> statusArr.filter((item,index) => statusArr.indexOf(item) === index);

    const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }


    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                        // left: '50%',
                        // transform: 'translateX(50%) translateY(0%)',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div>
                </MenuItem>
                {subTitle === STATEMENT_FILTER_OPTION.status ? (
                    <CustomStatusFilter setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={BONUS_STATUS}
                    subTitle={subTitle}
                  />
                ) : (
                    ''
                )}
                {subTitle === STATEMENT_FILTER_OPTION.worker ? (
                    <CustomWorkerFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}

                {subTitle === STATEMENT_FILTER_OPTION.job ? (
                    <CustomJobFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {subTitle === STATEMENT_FILTER_OPTION.propay ? (
                    <CustomPropayFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        showMilestone={true}
                    />
                ) : (
                    ''
                )}
                {(subTitle === STATEMENT_FILTER_OPTION.reference) ? (
                    <CustomConditionalFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {(subTitle === STATEMENT_FILTER_OPTION.earnings) ? (
                    <CustomConditionalFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {(subTitle === STATEMENT_FILTER_OPTION.statement_period) ? <CustomPeriodFilter
                resource='ProtivStatementPeriod__DropdownList'
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                closeSubFilter={closeSubFilter}
                updateSearchTag={updateSearchTag}
                isPrevChecked={isPrevChecked}
                currentTag={subTitle}
                contextList={periodContext.statementPeriod}
                setContextList={periodContext.setStatementPeriod}
                />:''}
                 {(subTitle === STATEMENT_FILTER_OPTION.paid_period) ? <CustomPeriodFilter
                resource='Period__FilterList'
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                closeSubFilter={closeSubFilter}
                updateSearchTag={updateSearchTag}
                isPrevChecked={isPrevChecked}
                currentTag={subTitle}
                contextList={periodContext.statementPeriod}
                setContextList={periodContext.setStatementPeriod}
                />:''}
                {
                   (subTitle === STATEMENT_FILTER_OPTION.created_date) ? <CustomDateFilter selectedTag={subTitle} updateSearchTag={updateSearchTag}
                   searchTag={searchTag} closeSubFilter={closeSubFilter}/>:''
                }
                {(subTitle === STATEMENT_FILTER_OPTION.close_date) && <CustomDateFilter selectedTag={subTitle} updateSearchTag={updateSearchTag}
                   searchTag={searchTag} closeSubFilter={closeSubFilter}/>}
                { subTitle === STATEMENT_FILTER_OPTION.branches && (
                    <CustomBranchFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                )}

            </Popover>
        </>
    );
};