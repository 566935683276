
import zappier from '../../assets/integraions/zappier.svg';
import QuickBooksOnline from '../../assets/integraions/quickbooks-online.svg';
import Salesforce from '../../assets/integraions/salesforce.svg';
import Dataverse from '../../assets/integraions/Dataverse.svg';
import Jobber from '../../assets/integraions/Jobber.svg';
import QuickbooksTime from '../../assets/integraions/QuickbooksTime.svg';
import Buildertrend from '../../assets/integraions/Buildertrend.svg';
import paycom from '../../assets/integraions/paycom.svg';
import ClockShark from '../../assets/integraions/ClockShark.svg';

export const connectors = [
    // {
    //   icon: QuickBooksOnline,
    //   title: 'Quickbooks Online',
    //   description: 'Easily Manage and Sync Your Data with Quickbooks Integration. Free Up Valuable Time for Your Business, Not Bookkeeping.',
    //   connected: false,
    //   connector: ''
    // },
    // {
    //   icon: zappier,
    //   title: 'Zappier',
    //   description: 'Streamline processes with custom workflows, advanced automation – no coding expertise necessary.',
    //   connected: true,
    //      connector: ''
    //     },
    // {
    //   icon: Salesforce,
    //   title: 'Salesforce',
    //   description: 'Securely connect data, systems, and AI models, automating tasks across diverse environments.',
    //   connected: false,
    //   connector: ''
    // },
    // {
    //     icon: Jobber,
    //     title: 'Jobber',
    //     description: 'Streamline appointments with online booking, and Jobber schedules jobs to your preferences.',
    //     connected: false,
    //     connector: ''
    // },
    // {
    //     icon: QuickbooksTime,
    //     title: 'Quickbooks Time',
    //     description: 'Streamline your financial tasks effortlessly by incorporating precise time tracking into your payroll and invoicing process.',
    //     connected: false,
    //     connector: ''
    // },
    // {
    //     icon: Dataverse,
    //     title: 'Dataverse',
    //     description: 'Seamlessly integrate data between finance, operations, Dataverse, and Dynamics 365 Sales for streamlined functionality.',
    //     connected: false,
    //     connector: ''
    // },
    {
        connector: 'buildertrend',
        is_active: false,
    },
    {
        connector: 'paycom',
        is_active: false,
    },
    {
        connector: 'clockshark',
        is_active: false,
    }
  ];
  export const connectorIcons = {
    buildertrend:Buildertrend,
    paycom:paycom,
    clockshark:ClockShark
  }

  export const connectorContent = {
    buildertrend:{
        icon: Buildertrend,
        title: 'Buildertrend',
        inputLableOne:'Username',
        payloadKeyOne:'username',
        payloadKeyTwo:'password',
        inputLabelTwo:'Password',
        description: 'Import jobs, time, budgets, users, and cost codes via our web scrapping solution.',
    },
    paycom:{
        icon: paycom,
        inputLableOne:'Sid',
        inputLabelTwo:'Token',
        payloadKeyOne:'sid',
        payloadKeyTwo:'token',
        title: 'Paycom',
        description: 'Connect your Paycom account and bring over jobs, time, and users, We do not support Payroll export.',
    },
    clockshark:{
        icon: ClockShark,
        title: 'ClockShark',
        inputLableOne:'Email',
        payloadKeyOne:'username',
        payloadKeyTwo:'password',
        inputLabelTwo:'Password',
        description: 'Import jobs, time, budgets, users, and tasks via our web scrapping solution.',
    },
  }

  export const connectorDescription = {
    arborgold:'Import jobs, time, schedule, visits, budgets, users, services, and activities via our web scrapping solution.',
    aspire:'Connect your Aspire account and bring over work tickets, opportunities, users, schedule, time, services, and budgets.',
    busybusy:'Import jobs, time, budgets, users, and cost codes via our web scrapping solution.',
    exaktime:'Import jobs, time, budgets, users, and cost codes via our web scrapping solution.',
    jobber:'Connect your Jobber account and bring over jobs, visits, users, schedule, and time.',
    jobtread:'Connect your Jobtread account and bring over jobs, visits, users, schedule, and time.',
    quickbooksOnline:'Connect to QB Online to pull over customer, job, and estimate information. We do NOT support payroll integration.',
    salesforce:'Connect your SalesForce account and bring over jobs, budgets, users, schedule, and time.',
    tsheet:'Connect your QB Time account and bring over jobs, time, and users, Also, create a custom field to map your milestones.',
    zapier:'Connect to hundreds of apps to pull over job budgets, job details, and attendance. Commonly used for ProPay automation.',
    'apify-lmn':'Import jobs, estimates, time, schedule, visits, budgets, users, tasks, and cost codes via our web scrapping solution.',
    buddypunch:'Connect your BuddyPunch account and bring over jobs, time, wages, and users,',
    procore:'Connect your Procore account and bring over contracts, budgets, users, cost codes, and time entries.',
    'apify-contractor-foreman':'Import jobs, time, budgets, users, and cost codes via our web scrapping solution.',
    vericlock:'Connect your VeriClock account and bring over jobs, time, and users, Also, create a custom field to map your milestones.',
    servicetitan:"Connect your Service Titan account and bring over jobs, budgets, technicians, employees, time entries, and dispatch schedule.",
    "apify-knowify":"Import jobs, time, schedule, visits, budgets, and users via our web scrapping solution.",
    "apify-serviceautopilot": "Import jobs, time, schedule, visits, budgets, users, and services via our web scrapping solution.",
    timescape: "Connect your Timescape account and bring over jobs,  employees, timesheets.",
    connecteam: "Connect your Connecteam account and bring over jobs, time, wages, and users."
  }

export const getEnvironment = (origin) => {
    if (origin === 'http://localhost:3000') {
        return 'dev';
    } else if (origin === 'https://test.app.protiv.com') {
        return 'dev';
    } else if (origin === 'https://testkiwi.app.protiv.com') {
        return 'qa';
    } else if (origin === 'https://app.protiv.com') {
        return 'prod';
    } else {
        return 'qa';
    }
  };

  export const ERROR_MESSAGES = {
    ALREADY_RUNNING: 'Data synchronization is currently underway. Please wait until the current synchronization process completes before initiating a new one.',
    CONNECTOR_CONNECTION_NOT_FOUND: 'Connection lost with this connector',
    CLOCKSHARK_INVALID_EMAIL: 'Please enter a valid email.'
  }
