import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Alert, AvatarGroup, Box, Button, ButtonGroup, Dialog, Grid, IconButton, ListItemIcon, Menu, MenuItem, Link as MuiLink, Snackbar, Stack, TableContainer, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import get from 'lodash/get';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import {
    DatagridBody, DatagridBodyProps, DatagridRow, FieldWithLabel, FunctionField, ListActions,
    ReferenceField, ReferenceInput, ResourceContextProvider, SelectInput, Show, TextField, useDataProvider, useGetList, useGetOne, useListContext, useNotify, useQuery, useRecordContext, useRefresh, useShowController, useTranslate
} from 'react-admin';
import { useQueryClient } from 'react-query';
import { Link, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MAvatar } from '../../components/@material-extend';
import { DialogConentForm } from '../../components/DialogForm';
import Label from '../../components/Label';
import { Datagrid } from '../../components/datagrid';
import { DateTimeField } from '../../components/fields';
import { DateFormat } from '../../components/fields/DateFormat';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import DateTimeTextField, { DateTimeTextLink } from '../../components/fields/DateTimeTextField';
import ReferenceArrayInput from '../../components/fields/ReferenceArrayInput';
import { FormatTimeField, MoneyField, NumberToTimeField, PercentField, getDuplicateConnectorList, getParsedConnectorName } from '../../components/fields/fields';
import { useGetIdentityOptimized, useIdentityContext } from '../../components/identity';
import PageAlerts, { PageAlertContextProvider } from '../../components/page-alerts/PageAlerts';
import { formatter } from '../../dashboard/BonusCalculatorDashboard';
import CardListActions, { CardListActionsProPayWithoutBudget, ResponsivePropayAction } from '../../layout/CardListActions';
import CardListView from '../../layout/CardListView';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import Empty from '../../layout/Empty';
import { List, ListGroup, ListGroupWithoutCard, ListWithoutCard } from '../../layout/List';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import { PropayStatusField } from '../../layout/StatusField';
import { Title } from '../../layout/Title';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import { EditableDatagrid } from '../../ra-editable-datagrid';
import { ALERT_ARROW_RIGHT, ALERT_ICON_WHITE, APPROVED_CLOSE_ICON, BIN_ICON, BONUS_POOL_ICON_02, CLOSE_ICON, CLOSE_ICON_ACTIONS, CLOSE_PREVIEW_ALERT, COMPLETE_PROPAY_ACTION, CURRENCY_DOLLAR_ICON, GROUPED_ICON, GROUP_EXPORT_ICON, PENDING_ICON, PREVIEW_AS_WORKER_SEARCH_ICON, STATUS_ICON, SVG_PREVIEW, TICK_ICON_FILLED } from '../../utils/Constants/ClassName';
import { findpropayWithoutBudget, getFormatedMessage, isAllowedFilters } from '../../utils/Constants/CommonFunctions';
import { CONSTANT_DATA, PROPAY_COLUMN, USER_TYPE, checkforColumnVisibility, getColumnLabel, isMobile, truncatePropayListName, PROPAY_ACTION_OPTION, PROPAY_BULK_ACTION_API } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import createAvatar from '../../utils/createAvatar';
import { CustomPropaySearcInput } from '../attendances/AttendanceFilterModal';
import { EmployeeFullNameField, ManagerFullNameField } from '../employees/Employee';
import { PropayChanges, PropayChangesView } from '../protivPropayChanges/ProPayChangeForm';
import CostCodeChip, { PropayDetailBonusPool } from './CostCodeChip';
import ExportPropay from './ExportPropay';
import MilestonesTab from './MilestonesTab';
import OtherWorkerDetails from './OtherWorkerDetails';
import { StyledDialog } from './Propay';
import PropayActions from './PropayActions';
import PropayActualAndTotalData from './PropayActualAndTotalData';
import PropayBulkAction from './PropayBulkAction';
import PropayDetailStatusView from './PropayDetailStatusView';
import PropayFilterMobile from './PropayFilterMobile';
import WorkerActions from './WorkerActions';
import WorkerTableResponsive from './WorkerTableResponsive';
import { NewPropayChangesShow, PropayDetailPage } from './groupPropay/PropayDetailPage';
import PropayDetailOverview from './propaydetailoverview';
import PropayDetailPageShimmer from '../../layout/Shimmers/PropayDetailPage';
import CreateGroupPropay from './groupPropay/CreateGroupPropay';
import GroupPropayCardResponsive from '../../layout/GroupPropayCardResponsive';
import { DialogContentText } from '@mui/material';
import { Checkbox } from '@mui/material';
import JobIdsChip, { JobTypeIdsChip } from './JobIdsChip';
import PropayIndicators from './PropayIndicators';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { POST } from '../../services/HttpService';
import { useSnackbar } from 'notistack';
import { handleErrorAndSuccessToast } from '../../layout/AutomationListActions';

export enum VIEW_MODE {
    PROPAY = 'PROPAY',
    EMPLOYEE = 'EMPLOYEE',
}

export const StyledSelectInput = styled(SelectInput)({
    minWidth: '150px',
});
const StyledReferenceInput = styled(ReferenceInput)({
    minWidth: '150px',
});
const StyledReferenceArrayInput = styled(ReferenceArrayInput)({
    minWidth: '150px',
});

const PREFIX = 'PropayEdit';

const classes = {
    root: `${PREFIX}-root`,
};

export const formStyle = {
    [`&.${classes.root}`]: { alignItems: 'flex-start' },
    '.MuiFormHelperText-root': { display: 'none' },
    '.MuiFormHelperText-root.Mui-error': { display: 'block' },
    '.MuiCardHeader-root': { 'padding-top': '0px' },
    '.MuiFormControl-root': { marginTop: '16px' },
};

export const StyledEditableDatagrid = styled(EditableDatagrid)({
    '.MuiInput-root.MuiInput-underline.MuiInputBase-root': {
        marginTop: '0px',
    },
    '.MuiFormControl-root.MuiFormControl-marginDense.MuiTextField-root': {
        marginTop: '0px',
        marginBottom: '0px',
    },
    '.MuiTableCell-root:last-of-type': {
        'padding-right': '0px',
        width: '90px',
    },
});

const LabelAvatar = ({ record, name }: any) => {
    const first_name = name
        ? get(name.split(' ', 2), 0, '')
        : (record && record.first_name) || '';
    const last_name = name
        ? name.split(' ', 2)[1]
        : (record && record.last_name) || '';
    const avatar = createAvatar(first_name, last_name);
    return (
        <MAvatar color={avatar.color} sx={{ width: 30, height: 30 }}>
            <Typography variant="inherit" noWrap sx={{ fontSize: 12 }}>
                {avatar.name}
            </Typography>
        </MAvatar>
    );
};

export const EmployeeAvatar = (props: any) => {
    const { record, showBonus, is_remove_bonus } = props;
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
        >
            <LabelAvatar {...props} />
                <Stack direction='column' sx={{ overflow: 'hidden' }}>
                    <EmployeeFullNameField record={record} />
                    <Stack direction='row'>
                        {showBonus && is_remove_bonus &&<Label
                            variant='ghost'
                            color={'pending'}
                        >
                            No Bonus
                        </Label>}
                </Stack>
            </Stack>
        </Stack>
    );
};

export const PropayEmployeeAvatar = (props: any) => {
    const { wage_ids } = props;
    const { data } = useListContext();
    if (data.length === 1) {
        return (
            <EmployeeAvatar
                showBonus
                is_remove_bonus={
                    _.size(wage_ids) && wage_ids[0].is_remove_bonus
                }
                record={data[0]}
            />
        );
    }

    if (data.length > 1) {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <AvatarGroup max={2}>
                    {data.map((employee: any) => {
                        return <LabelAvatar {...employee} />;
                    })}
                </AvatarGroup>
            </Stack>
        );
    }
    return <div>No Data</div>;
};


export const StatusButtonGroup = ({
    onClick,
    loading,
    reverseLoading,
    variant,
    reverseVariant,
    style,
    reverseStyle,
    buttonsTitle,
}: any) => {
    return (
        <ButtonGroup
            variant="outlined"
            color="inherit"
        >
            <LoadingButton
                size="small"
                loading={reverseLoading}
                variant={reverseVariant}
                style={{ ...reverseStyle, width: '100%', boxShadow: 'none' }}
                onClick={onClick}
            >
                <p className="no-translate">{buttonsTitle.button1}</p>
            </LoadingButton>
            <LoadingButton
                variant={variant}
                size="small"
                loading={loading}
                style={{
                    ...style,
                    width: '100%',
                    boxShadow: 'none',
                }}
                onClick={onClick}
            >
                <p className="no-translate">{buttonsTitle.button2}</p>
            </LoadingButton>
        </ButtonGroup>
    );
};
export const ProPayPaidAlert = ({ name }) => {
    return (
        <>
            <Typography variant="body2">
                No bonus on
                <Typography variant="subtitle1" component="span">
                    &nbsp;{name}&nbsp;
                </Typography>
                ProPay closed automatically
            </Typography>
        </>
    );
};

export const PROPAY_STATUS = [
    { id: 'open', name: 'resources.propays.choices.status.open' },
    { id: 'pending', name: 'resources.propays.choices.status.pending' },
    { id: 'started', name: 'Started' },
    { id: 'completed', name: 'Completed' },
    { id: 'approved', name: 'resources.propays.choices.status.approved' },
    { id: 'paid', name: 'resources.propays.choices.status.paid' },
    { id: 'cancelled', name: 'resources.propays.choices.status.cancelled' },
];

const CustomPropayFilters = [
    <CustomPropaySearcInput
        className="filter-search"
        source="name._ilike"
        label="resources.propays.search"
        size="medium"
        name='propay'
        alwaysOn
        alwaysOnMobile
    />,
    <DateRangeInputFilter
        className="date-input-filter"
        source="from_date"
        pageType='propay'
        alwaysOn
    />,
];

export const PropayShowButton = (props: any) => {
    const { record, basePath } = props;
    return (
        <Tooltip title="Details">
            <IconButton
                onClick={e => {
                    e.stopPropagation();
                }}
                component={Link}
                to={{
                    pathname: `${basePath || ''}${record && record.id}/show`,
                }}
            >
                <Icon icon="akar-icons:arrow-right" fr="" />
            </IconButton>
        </Tooltip>
    );
};

export const StyledDatagrid = styled(Datagrid)({
    '.MuiTableCell-head': {
        paddingLeft: 0,
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 2,
    },
    '.RaDatagrid-row': {
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 1,
    },
    '.MuiTableCell-body': {
        paddingLeft: 0,
    },
    '.MuiTableCell-footer': {
        paddingLeft: 4,
    },
    '.MuiTableRow-root': {
        '.MuiTableCell-root:first-of-type': {
            paddingLeft: 0,
        },
        '.MuiTableCell-root:last-of-type': {
            paddingRight: 0,
        },
    },
});

export const ListStyle = {
    marginTop: 5,
    '.MuiTableCell-head.RaDatagrid-headerCell': {
        backgroundColor: 'white',
        padding: 0,
    },

    '.MuiTableCell-body': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    '.MuiTableCell-footer': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    '.MuiTableCell-head': {
        paddingLeft: 0,
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 2,
    },
    '.RaDatagrid-row': {
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 1,
    },
    '.MuiTableRow-root': {
        '.MuiTableCell-root:first-of-type': {
            paddingLeft: 0,
        },
        '.MuiTableCell-root:last-of-type': {
            paddingRight: 0,
        },
    },
};
const StyledListGroupWithoutCard = styled(ListGroupWithoutCard)(ListStyle);
const StyledListWithoutCard = styled(ListWithoutCard)(ListStyle);
export const StyledActivityListWithoutCard = styled(ListWithoutCard)({
    ...ListStyle,
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaDatagrid-row': {
        borderBottomWidth: 0,
    },
});

export const StyledFieldWithLabel = styled(FieldWithLabel)({
    '.RaFieldWithLabel-label': {
        fontWeight: 'bold',
    },
    '.MuiTypography-body2': {
        border: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: 'rgba(137, 150, 164, 0.32)',
        padding: 10,
    },
});

export const StyledTextField = styled(TextField)({
    fontWeight: 'bold',
    fontSize: 20,
    color: '#231F20',
    paddingRight: 5,
});

const textStyle = {
    fontSize: 14,
    color: '#231F20',
    fontWeight: 400,
    marginLeft: 1,
    marginRight: 1,
};

const StyledStatusTextField = styled(TextField)({
    ...textStyle,
});

const StyledNameTextField = styled(TextField)({
    ...textStyle,
    fontWeight: 'bold',
});

export const StyleDateTimeField = styled(DateTimeField)({
    ...textStyle,
});

const HasData = (props: any) => {
    const { list } = props;
    if (list && list.length > 0) {
        return props.children;
    }
    return null;
};

export const WeeklyDialogForm = forwardRef((props: any, ref: any) => {
    const [open, setOpen] = useState(props.open);
    const [record, setRecord] = useState(null);
    const { onCloseAlert ,customClass} = props;
    const onClose = () => {
        if (onCloseAlert) {
            onCloseAlert();
        }
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        open(record: any) {
            setRecord(record);
            setOpen(true);
        },
        close() {
            onClose();
        },
    }));
    const xsFullScreenDialog = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <StyledDialog
            maxWidth="md"
            fullScreen={xsFullScreenDialog}
            open={open}
            {...props.dialogProps}
            className={customClass || ''}
        >
            <DialogConentForm
                open={open}
                record={record}
                onClose={onClose}
                {...props}
            />
        </StyledDialog>
    );
});
export const EmployeeFullNameBonus = ({
    record,
    is_remove_bonus,
    is_propay_assigned_by_themselves,
}: any) => (
    <span>
        {record.first_name} {record.last_name}
        {is_remove_bonus && (
            <Label variant="ghost" color={'pending'}>
                No Bonus
            </Label>
        )}
        {!record.active && (
            <Label variant="ghost" color={'pending'}>
                Deact.
            </Label>
        )}
        {/* {is_propay_assigned_by_themselves && (
            <InfoLabel
                sx={{ color: 'red' }}
                height={15}
                icon="ri:error-warning-fill"
            >
                <StyledTypography>
                    Worker assigned themselves to ProPay.
                </StyledTypography>
            </InfoLabel>
        )} */}
    </span>
);
/**
 *
 * TODO: This propay drawer needs further fix, This kind of thing happens because of wrong cohesion, drawer for propay and
 * propay wage has to be totally different drawer, rather than adding conditions in the same drawer
 *
 */

const SubJobList = (props: any) => {
    const { record } = props;
    return (
        <div className='sub-job-table'>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    {record?.contractor_item_ids_obj?.length > NUMBER.ZERO ? <TableBody>
                        {record?.contractor_item_ids_obj?.map((item) => {
                            return (
                                <TableRow key={item.id + 'key'}>
                                    <TableCell>{item?.display_name}</TableCell>
                                    <TableCell>{item?.quantity}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody> :
                        <TableBody><TableCell colSpan={2}><Empty /></TableCell></TableBody>
                    }
                </Table>
            </TableContainer>
            </div>
    );
};

const PropayChangesList = (props: any) => {
    const { record } = props;
    const diaglogRef: any = React.useRef();
    const [messageRecord, setRecord] = useState(null);
    const translate = useTranslate();
    return (
        <>
            <Typography className="propay-change-modal-heading">
                {' '}
                {translate('resources.propays.propay_changes.info')}{' '}
            </Typography>
            <div className="propay-changes-boxes">
                {record?.map((changes, index) => {
                    return (
                        <Stack
                            className="changes-boxes-col"
                            direction="row"
                            spacing={2}
                        >
                            <div className="changes-boxes-bg">
                                <Typography>
                                    {' '}
                                    {translate(
                                        'resources.propays.propay_changes.change'
                                    )}{' '}
                                    {index + NUMBER.ONE}
                                </Typography>
                                <MuiLink
                                    component="button"
                                    onClick={() => {
                                        diaglogRef.current.open();
                                        setRecord(changes);
                                    }}
                                    underline="always"
                                >
                                    <DateTimeTextLink record={changes} />
                                </MuiLink>
                            </div>
                        </Stack>
                    );
                })}
            </div>
                <WeeklyDialogForm title="Add Time" ref={diaglogRef}>
                    <PropayChangesView
                        record={messageRecord}
                        onClose={() => {
                            diaglogRef.current && diaglogRef.current.close();
                        }}
                    />
                </WeeklyDialogForm>
        </>
    );
};

const PropayChangesShow = props => {
    const { record } = props;
    const translate = useTranslate();

    const { data, total } = useGetList('protivPropayHistorys', {
        pagination: { page: NUMBER.ONE, perPage: NUMBER.HUNDRED },
      filter: {
        propay_res_id: { _eq: record?.id }, _and:
                {new_values: {_ilike: "job_id"}, _or:
                {new_values: {_ilike: "name"},_or:
                {new_values: {_ilike: "task_names"},_or:
                {new_values: {_ilike: "amount"}, _or:
                {new_values: {_ilike: "budget_hours"}
        }}}}},
        },
        sort: { field: 'id', order: 'ASC' },
    });
    const MessageList = total && data.slice(NUMBER.ONE, data.length);
    if (total <= 1) {
        return <></>;
    }
    return (
        <>
            <div className="propay-detail-accordion propay-change-accordion">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="changepanel1d-content"
                        id="changepanel1d-header"
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{ paddingBottom: 2 }}
                        >
                            {' '}
                            {translate(
                                'resources.propays.propay_changes.title'
                            )}{' '}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            className="activity-propay-boxes propay-change-boxes"
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} lg={12} sx={{ paddingTop: 0 }}>
                                <PropayChangesList record={MessageList} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

const StatusActivity = (props: any) => {
    const { record } = props;
    return (
        <>
            <StyledActivityListWithoutCard
                title=" "
                disableSyncWithLocation={CONSTANT_DATA.true}
                component="div"
                filter={{
                    propay_res_id: {_eq: record.id},
                    new_values: { _ilike: "status"},
                }}
                actions={false}
                resource="protivPropayHistorys"
                empty={<></>}
                titleActionProps={{ showCreate: false }}
                perPage={NUMBER.THIRTY}
                showPagination={false}
                sort={{ field: 'create_date', order: 'DESC' }}
            >
                <StyledDatagrid bulkActionButtons={false} empty={<></>}>
                    <FunctionField
                        source="status"
                        sortable
                        label=" "
                        render={(record: any) => {
                            return (
                                <Stack
                                    direction="row"
                                    className="activity-typography-cs"
                                >
                                    <StyledStatusTextField source="new_value_char" />
                                    <Typography variant="body1" sx={textStyle}>
                                        &nbsp;By:&nbsp;
                                    </Typography>
                                        <StyledNameTextField
                                            source="author_id_obj.name"
                                            className="activity-box-name"
                                        />
                                    <Typography
                                        variant="caption"
                                        sx={textStyle}
                                    >
                                        (
                                        <StyleDateTimeField
                                            source="create_date"
                                            isLocal={false}
                                            label="Date"
                                        />
                                        )
                                    </Typography>
                                </Stack>
                            );
                        }}
                    />
                </StyledDatagrid>
            </StyledActivityListWithoutCard>
        </>
    );
};

const PropayShowView = (props: any) => {
    const translate = useTranslate();
    const { refetch } = useShowController();
    const location = useLocation()
    const navigate = useNavigate();
    const refresh = useRefresh()
    const initialValues = { show: false, employee_id: null };
    const [workerPreview, setWorkerPreview] = useState(initialValues);
    const [selectedWorkerToPreview, setSelectedWorkerToPreview] = useState(null);
    const [connectorList,setConnectorList]= useState([]);
    // const [GroupOpen, GroupSetOpen] = React.useState(false);
    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => {
        document.body.classList.add("propay-body");
        return () => {
        document.body.classList.remove("propay-body");
        }
    }, [])

    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => { }, [selectedWorkerToPreview])
    const handleGoBack = () => {
        if (location.state === 'toPropayList') {
            navigate(NUMBER.ZERO);
        }
        if (window?.history?.length > 1) {
            navigate(-NUMBER.ONE);
        } else {
            navigate('/');
        }
    }

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const identity = useIdentityContext();
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }


    const max_hours = _.round(get(record, "estimated_hours"), NUMBER.TWO);
    const employeeId = () => workerPreview.show ? workerPreview?.employee_id : identity?.employee_id;
    const otherWorker = () => {
        let employees = record?.wage_ids?.filter(empId => empId?.employee_id !== employeeId());
        return employees?.length > NUMBER.ZERO;
    }

    const getWorkerFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = [];
            record.milestone_ids.map(ele => {
                if (ele?.selected_employee_ids?.length > NUMBER.ZERO) {
                    ele.selected_employee_ids.map(el => milestoneIds.push(el))
                }
            })
            let milestoneEmployeeIds = record.selected_employee_ids.filter(item => !milestoneIds.includes(item));
            let ids = record.milestone_ids[NUMBER.ZERO]?.id ? record.milestone_ids.map(ml => ml.id):[]
            if (milestoneEmployeeIds.length > NUMBER.ZERO) {
                return { _or: { _and: { propay_id: { _eq: record.id }, employee_id: { _in: milestoneEmployeeIds } } }, propay_id: { _in: ids } }
            }
            ids.push(record.id);
            return ['pending', 'cancelled'].includes(record.status) ? { propay_id: { _in: ids, }, } : {
                propay_id: { _in: ids, }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        } else {
            return ['pending', 'cancelled'].includes(record.status) ? { propay_id: { _eq: record.id, }, } : {
                propay_id: { _eq: record.id, }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        }
    }
    const getOtherWorkerDetail = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = record.milestone_ids.map(ml => ml.id);
            milestoneIds.push(record.id);
            return { propay_id: { _in: milestoneIds }, employee_id: { _neq: employeeId(), } }
        } else {
            return { propay_id: { _eq: record.id }, employee_id: { _neq: employeeId(), } }
        }
    }
    const toChoices = items => items?.map(item => ({ value: item?.employee_id_obj?.id, label: item?.employee_id_obj?.display_name }));

    const getUserFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = record.milestone_ids.map(ml => ml.id);
            milestoneIds.push(record.id);
            return { propay_id: { _in: milestoneIds }, employee_id: { _eq: employeeId(), } }
        } else {
            return { propay_id: { _eq: record.id }, employee_id: { _eq: employeeId(), } }
        }
    }
    const closeAlert = () => {
        document.body.classList.remove('preview-worker-body');
        setWorkerPreview({ show: false, employee_id: null });
        refresh()
    }

    // const GroupHandleClickOpen = () => {
    //     GroupSetOpen(true);
    // };
    // const GroupHandleClose = () => {
    //     GroupSetOpen(false);
    // };

    const commonWorkerTableApiFields = [
            "propay_id", "base_wage", "employee_id", "hours", "bonus","attendance_earning",
            "propay_earning","is_void", "total_earning", "base_pay", "pay_rate",
            "is_remove_bonus", "is_disable_all_notification", "lead_pay",
            "propay_ot_amt", "is_propay_assigned_by_themselves", "bonus_per",
            "active", "ending_balance",
          ];

    const showWorkerPreview =()=> {
        document.body.classList.add('preview-worker-body');
        const emp =  record?.wage_ids[NUMBER.ZERO].employee_id_obj;
        setSelectedWorkerToPreview({value:emp.id,label:emp.display_name});
        setWorkerPreview({show:true,employee_id:emp.id});
    }

   const getConnectName = (index)=> connectorList.length > 1 ? (connectorList[index]?.name || connectorList[index]?.service) :'';

    return (
        <>
        <Dialog className='alert-preview-modal' open={workerPreview.show}>
        <Stack sx={{ width: '100%' }} spacing={2} className='alert-preview-bar'>
              <Alert action={<><Button className='close-preview-window' onClick={() => closeAlert() }>
                {CLOSE_PREVIEW_ALERT()} Close Preview</Button></>}>
                                {SVG_PREVIEW()}
                                    You are previewing the ProPay as a
                                    <Select className="basic-single no-translate alert-worker-select"
                                    value={selectedWorkerToPreview} onChange={(val:any)=>{setSelectedWorkerToPreview(val);setWorkerPreview({show:true,employee_id:val.value})}} placeholder="Select"
                                    isClearable={false} isSearchable={true} required={true}
                                    name="color" options={toChoices(record.wage_ids)} menuPortalTarget={document.body} {...props}
                                    menuPosition="fixed" classNamePrefix="react-select-import"
                                                />
              </Alert>
        </Stack>
        </Dialog>
        {/* <div className='propay-group-toolbar'>
                This ProPay is {GROUPED_ICON()} <span>Go to <strong>“ProPay Group 1”</strong> Group</span>
        </div> */}
            <Box
                className="propay-detail-page"
                sx={{ px: 2, overflowY: 'auto' }}
            >
                <Grid container spacing={2} className='propay-head-bg'>
                    <Grid className="propay-detail-title-row" item xs={12}>
                        <Stack direction="row" alignItems="center">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Stack direction="row" alignItems="center" className="status-label-details">
                                        <span className="back-button-title" onClick={() => handleGoBack()}>Back</span>
                                        <StyledTextField source="name" className="propay-dtl-title no-translate"/>
                                        <div className="propay-job-cost-code">
                                        {Boolean(record['job_id']) && (
                                                <>
                                                {/* <Button className='propay-grouped-btn'>Grouped</Button> */}
                                                <Typography className="label-dtl-sub-head">
                                                    <FunctionField source="job_id"
                                                        render={record => ( <> <strong>{getParsedConnectorName(record?.job_id_obj?.connected_name) || getConnectName(0) ?  `Job-${getParsedConnectorName(record?.job_id_obj?.connected_name) || getConnectName(0)}:`:'Job:' } </strong>{' '}{<span className="no-translate detail-job-btn">{record?.job_id_obj?.full_name}</span>}{' '}</>)}/>
                                                </Typography>
                                                </>
                                            )}
                                            {Boolean(record['second_job_id']) && (
                                                <Typography className="label-dtl-sub-head">
                                                    <FunctionField source="second_job_id"
                                                        render={record => ( <> <strong> &nbsp;{`Job-${getParsedConnectorName(record?.second_job_id_obj?.connected_name) || getConnectName(1)}`}:</strong>{' '}{<span className="no-translate detail-job-btn">{record?.second_job_id_obj?.full_name || ''}</span>}{' '}</>)}/>
                                                </Typography>
                                            )}
                                            {Boolean(record['job_id']) && record['cost_code_ids']?.length > NUMBER.ZERO  && (
                                                        <div className="label-dtl-sub-head">
                                                        <span className="none-nbsp-xs"> &nbsp; | &nbsp; </span>{' '}
                                                            <strong> {identity?.company?.cost_code_label}: </strong>{' '}
                                                        <CostCodeChip record={record}/>
                                                        </div>
                                            )}
                                        </div>
                                        <div className='propay-progress-row'>
                                        <div className='propay-progress-left'>
                                            <div className='propay-progress-label'>Manager:
                                            <span className="no-translate detail-job-btn">{record.manager_id ? record.manager_id_obj.display_name:''}</span>
                                           {record?.leadpay_amount ?
                                           <>
                                            <span className='grouped-separator'>|</span>
                                            <span className="detail-job-btn group-lead-pay-link">LeadPay: {formatter.format(record?.leadpay_amount)}</span></>:''}
                                            </div>
                                            </div>
                                            <PropayDetailStatusView identity={identity} record={record}/>
                                            </div>

                                        {(identity?.user_type !== 'worker' && !workerPreview.show) && (
                                            <CardListActions
                                                record={record}
                                                onShowPage={true}
                                                refresh={refresh}
                                            />
                                        )}

                                {/* Bonus Pool Edit Modal */}
                                   <div className='bonus-pool-btn-group'>
                                   <PropayDetailBonusPool identity={identity} record={record} workerPreview={workerPreview} />
                                   {/* { (identity?.user_type !== 'worker' && !workerPreview.show && record?.wage_ids?.length > 0) &&
                                   <Button className='bonus-pool-btn preview-bonus-worker' onClick={()=>showWorkerPreview()}>
                                        {PREVIEW_AS_WORKER_SEARCH_ICON()} Preview as a Worker
                                    </Button>
                                   } */}
                                    </div>

                                    {/* <FunctionField className="status-btn-dtl" source="status" sortable
                                        render={(record: any) => ( <StatusField record={record} /> )}
                                    /> */}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>

                <div className='propays-wrap-padding'>
                <HasData list={record.contractor_item_ids_obj}>
                    <Grid className="worker-sub-job-box" container spacing={0} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} lg={12}>
                            <Typography variant="h5" noWrap> {translate('resources.propays.fields.contractor_item_ids')} </Typography>
                            <SubJobList record={record} />
                        </Grid>
                    </Grid>
                </HasData>

                {record.has_milestones ?
                <Grid className="worker-detail-box" container spacing={0} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} lg={12}>
                        <PropayDetailOverview record={record} identity={identity} maxHours={max_hours} selectedWorkerToPreview={selectedWorkerToPreview}
                         workerPreview={workerPreview}
                        />
                    </Grid>
                </Grid> :
                <Grid className="worker-detail-box" container spacing={0} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} lg={12}>
                        <PropayActualAndTotalData record={record} maxHours={max_hours} identity={identity}
                            selectedWorkerToPreview={selectedWorkerToPreview} workerPreview={workerPreview}
                        />
                    </Grid>
                </Grid>}

                {/* ProPay Milestone Section */}
               {record?.milestone_ids?.length > 0 && <div>
                 <Stack className='milestone-stacked' sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Stack>
                        <Typography variant="h5">Milestones</Typography>
                        <Typography variant="subtitle1" sx={{ mb: '15px' }}> All Milestones assigned to ProPay
                        </Typography>
                    </Stack>
                    <Stack className='milestone-group-stacked'>
                        {/* <Button className='milestone-group-btn' onClick={GroupHandleClickOpen}>Group</Button> */}
                    {(identity?.user_type !== 'worker' && !workerPreview.show )&&
                        <Button className='adjust-time-btn' onClick={()=>navigate('/attendances/show',{replace: true ,state:{record:record,openDialog:true}})}>Adjust Time</Button>
                    }
                    </Stack>
                </Stack>
                <MilestonesTab  propayRecord={record} refetch={refresh} workerPreview={workerPreview}/>
                </div>}

               {(identity?.user_type !== 'worker' &&  !workerPreview.show) && (
                    <HasData list={record.employee_wage_ids}>
                        <Grid className="worker-detail-box" container spacing={0} sx={{ marginBottom: 2 }}>
                            <Grid item xs={12} lg={12}>
                                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                                <Stack>
                                    <Typography variant="h5"> {translate("resources.propays.worked_details")}</Typography>
                                    <Typography variant="subtitle1" sx={{ mb: "15px" }}> {translate("resources.propays.worked_assigned_propay")}</Typography>
                                </Stack>
                                </Stack>
                                {isSmall ? (
                                    <StyledListGroupWithoutCard
                                        title=" "
                                        disableSyncWithLocation={CONSTANT_DATA.true}
                                        component="div"
                                        filter={getWorkerFilter()}
                                        actions={false}
                                        resource="propayWageByBaseWage"
                                        lazy={false}
                                        groupBy={['employee_id', 'base_wage']}
                                        fields={commonWorkerTableApiFields}
                                        empty={false}
                                        titleActionProps={{ showCreate: false }}
                                        perPage={200}
                                        showPagination={false}
                                        emptyWhileLoading
                                        className="worker-table-responsive"
                                    >
                                        <WorkerTableResponsive value={record} refetch={refetch} workerPreview={workerPreview} />
                                    </StyledListGroupWithoutCard>
                                ) :
                                (
                                    <StyledListGroupWithoutCard
                                        title=" "
                                        disableSyncWithLocation={CONSTANT_DATA.true}
                                        component="div"
                                        filter={getWorkerFilter()}
                                        actions={false}
                                        resource="propayWageByBaseWage"
                                        lazy={CONSTANT_DATA.false}
                                        groupBy={['employee_id', 'base_wage']}
                                        fields={commonWorkerTableApiFields}
                                        empty={false}
                                        titleActionProps={{ showCreate: false }}
                                        perPage={200}
                                        showPagination={false}
                                    >
                                        <WorkerTable refetch={refetch} identity={identity}/>
                                    </StyledListGroupWithoutCard>
                                )}
                            </Grid>
                        </Grid>
                    </HasData>
                )}
                {(identity?.user_type === 'worker' || workerPreview.show) && (
                    <HasData list={record?.employee_wage_ids}>
                        <Grid container className="worker-detail-box" spacing={0} sx={{ marginBottom: 2 }}>
                            <Grid item xs={12} lg={12} className="other-worker-grid-cont">
                                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Stack>
                                    <Typography variant="h5">{translate('resources.propays.personal_details')} </Typography>
                                </Stack>
                                </Stack>
                                {!isSmall ? (
                                    <StyledListGroupWithoutCard
                                        title=" "
                                        disableSyncWithLocation={CONSTANT_DATA.true}
                                        component="div"
                                        filter={
                                            getUserFilter()
                                        }
                                        actions={false}
                                        resource="propayWageByBaseWage"
                                        lazy={false}
                                        groupBy={['employee_id', 'base_wage']}
                                        fields={commonWorkerTableApiFields}
                                        empty={false}
                                        titleActionProps={{ showCreate: false }}
                                        perPage={200}
                                        showPagination={false}
                                        className="bonus-card-details"
                                    >
                                        <WorkerTable refetch={refetch} workerPreview={workerPreview}/>
                                    </StyledListGroupWithoutCard>
                                ) : (
                                    <StyledListGroupWithoutCard
                                        title=" "
                                        disableSyncWithLocation={CONSTANT_DATA.true}
                                        component="div"
                                        filter={
                                            getUserFilter()
                                        }
                                        actions={false}
                                        resource="propayWageByBaseWage"
                                        lazy={false}
                                        groupBy={['employee_id', 'base_wage']}
                                        fields={commonWorkerTableApiFields}
                                        empty={false}
                                        titleActionProps={{ showCreate: false }}
                                        perPage={200}
                                        emptyWhileLoading
                                        showPagination={false}
                                        className="bonus-card-details"
                                    >
                                        <WorkerTableResponsive value={record} refetch={refetch} workerPreview={workerPreview} />
                                    </StyledListGroupWithoutCard>
                                )}
                            </Grid>
                        </Grid>
                    </HasData>
                )}

                {(identity?.user_type === 'worker' || workerPreview.show) && otherWorker() && (
                        <HasData list={record.employee_wage_ids}>
                        <Grid container className="worker-detail-box" spacing={0} sx={{ marginBottom: 2 }}>
                          <Grid item xs={12} lg={12} className="other-worker-grid-cont">
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Typography variant="h5"> {translate('resources.propays.other_worker_details')} </Typography>
                            </Stack>
                            <ResourceContextProvider value="workerDetails">
                              <ListGroup
                                title=' '
                                filter={getOtherWorkerDetail()}
                                showPagination={false}
                                perPage={200}
                                actions={false}
                                className="bonus-card-details"
                                groupBy={['employee_id']}
                                fields={['employee_id','hours','bonus','leadpay']}
                              >
                                {isSmall ? <OtherWorkerDetails leadPayAmount={record?.leadpay_amount}/> : <OtherWorkersTable record={record} />}
                              </ListGroup>
                            </ResourceContextProvider>
                          </Grid>
                        </Grid>
                      </HasData>
                    )}

                <HasData list={record.task_ids}>
                    <TaskSection record={record} />
                </HasData>

                <div className="propay-detail-accordion">
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant="h5" noWrap sx={{ paddingBottom: 2 }}> {translate('resources.propays.fields.activity')} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid className="create-date-propay-detail" item xs={12} sm={8} md={4} lg={4}>
                              <FunctionField textAlign="right" source="create_date" label="" render={(record: any) => ( <DateTimeTextField record={record} /> )} />
                            </Grid>
                        </Grid>
                        <Grid className="activity-propay-boxes" container spacing={2}>
                            <Grid item xs={12} lg={12} sx={{ paddingTop: 0 }}> <StatusActivity record={record} /> </Grid>
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <NewPropayChangesShow record={record} />
                </div>
            </Box>
            <Title title={record.name} detaiPage={true}/>
        </>
    );
};

const WorkerTable = (props: any) => {
    const record = useRecordContext(props);
    const { refetch, workerPreview ,identity} = props;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
    return (
        <>
            <StyledDatagrid bulkActionButtons={false} empty={<Empty />}>
                <FunctionField
                    source="employee_id"
                    label="resources.propays.fields.name"
                    sortable
                    render={(record: any) => {
                        return (
                            <ReferenceField
                                source="employee_id"
                                reference="Employee__allEmployeespropayDetailList"
                                link={false}
                            >
                                <UserAvtarAndName
                                    is_remove_bonus={record.is_remove_bonus}
                                    is_propay_assigned_by_themselves={
                                        record.is_propay_assigned_by_themselves
                                    }
                                    sortable={false}
                                    is_void={record.is_void}
                                    is_ot={record.propay_ot_amt}
                                />
                            </ReferenceField>
                        );
                    }}
                />
                <MoneyField
                    source="base_wage"
                    sortable={false}
                    className="no-translate"
                />
                <FormatTimeField
                    source="hours"
                    label="resources.propays.hours"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                <MoneyField
                    textAlign="right"
                    source="base_pay"
                    label="resources.propays.base_pay"
                    sortable={false}
                    groupBy
                    className="no-translate"
                />
                {(record.ot_amt || identity?.company?.include_ot_from_spent_total) ? <MoneyField source="propay_ot_amt" textAlign="right" sortable={false} groupBy/>:<></>}

                <FunctionField
                    source="bonus"
                    textAlign="right"
                    groupBy
                    render={(data: any) => {
                    const isLeadpayEmployee = record?.leadpay_employee_wage_ids?.filter(emp=>emp.employee_id === data.employee_id);
                        return (
                            <>
                        <p>{formatter.format(data.lead_pay ? data.bonus+data.lead_pay:data.bonus)} </p>
                        { isLeadpayEmployee?.length > NUMBER.ZERO ? <Tooltip id='leadpay_tooltip_id' title={<div className='lead-pay-tooltip'><p>LeadPay Amount: {formatter.format(record?.leadpay_amount)}</p>
                        {data?.lead_pay && <p>LeadPay Earned: {formatter.format(data.lead_pay)}</p>}</div>} placement="top" arrow><span className='bonus-lp-cls'>{' '}LP</span></Tooltip>:''}
                        </>
                        );
                    }}
                />
                <MoneyField
                    className="no-translate"
                    source="total_earning"
                    label="resources.propays.total"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                <MoneyField
                    className="no-translate"
                    source="pay_rate"
                    label="resources.propays.propay_rate"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                {record.bonus_split_type === 'by_percentage' && (
                    <PercentField
                        label="resources.propays.bonus_percentage"
                        source="bonus_per"
                    />
                )}
                {/* {record?.has_milestones && <MoneyField
                    className="no-translate"
                    source="ending_balance"
                    label="Balance"
                    textAlign="right"
                    sortable={false}
                />} */}
                <FunctionField
                    label="resources.propays.percentage_increase"
                    source="pay_rate"
                    render={record => <PercentageIncrease record={record} />}
                />
                {record?.status !== 'paid' && (
                    <FunctionField
                        textAlign="right"
                        label="resources.propays.action"
                        render={(recordData: any) => (
                            <>
                                <WorkerActions
                                    record={record}
                                    recordData={recordData}
                                    refetch={refetch}
                                    workerPreview={workerPreview}
                                />
                            </>
                        )}
                    />
                )}
            </StyledDatagrid>
        </>
    );
};

const OtherWorkersTable = (props: any) => {
    const identity = useIdentityContext();
    const record = useRecordContext(props);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
    return (

        <>
            <StyledDatagrid bulkActionButtons={false} empty={<Empty />}>
                <FunctionField
                    source="employee_id"
                    label="resources.propays.fields.name"
                    sortable
                    render={(record: any) => {
                        return (
                            <ReferenceField
                                source="employee_id"
                                reference="Employee__allEmployeespropayDetailList"
                                link={false}
                            >
                                <UserAvtarAndName
                                    is_remove_bonus={record.is_remove_bonus}
                                    is_propay_assigned_by_themselves={
                                        record.is_propay_assigned_by_themselves
                                    }
                                    sortable={false}
                                />
                            </ReferenceField>
                        );
                    }}
                />
                <FormatTimeField
                    source="hours"
                    label="resources.propays.hours"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
               {!identity?.company?.hide_bonuses_from_other_workers && <FunctionField
                    source="bonus"
                    textAlign="right"
                    groupBy
                    render={(data: any) => {
                        return (
                            <>
                         <p>{formatter.format(data.leadpay ? data.bonus+data.leadpay:data.bonus)} </p>
                        { (data?.leadpay && !identity.company.hide_leadpay_bonus_from_workers) ? <Tooltip id='leadpay_tooltip_id' title={<div className='lead-pay-tooltip'><p>LeadPay Amount: {formatter.format(record?.leadpay_amount)}</p><p>LeadPay Earned: {formatter.format(data.leadpay)}</p></div>}
                        placement="top" arrow><span className='bonus-lp-cls'>{' '}LP</span></Tooltip> : ''
                        }
                        </>
                        );
                    }}
                />}
            </StyledDatagrid>

        </>
    );
};

const PercentageIncrease = ({ record }: any) => {
    const amountIncrease = record.pay_rate
        ? record.pay_rate - record.base_wage
        : 0;
    const percentageIncrease =
        (amountIncrease / record.base_wage) * NUMBER.HUNDRED;
    const percentNumber = Number.isNaN(percentageIncrease)
        ? NUMBER.ZERO
        : percentageIncrease;
    return (
        <>
            {`${
                percentNumber === NUMBER.ZERO
                    ? percentNumber
                    : parseFloat(percentNumber.toString()).toFixed(NUMBER.TWO)
            }%`}
        </>
    );
};

export const TaskSection = (props: any) => {
    const { record } = props;
    return (
        <>
            <Grid className="task-detail-sec" container spacing={0}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h5" noWrap>
                        {record?.old_propay_view ? 'Tasks' : 'Notes'}
                    </Typography>
                    <Grid container spacing={2} className='task-tabs-container'>
                        {record?.task_ids?.map((tasks) => (
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <div className='task-tabs-item'>{tasks?.name}</div>
                            </Grid>
                        ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};


export const PropayShow = (props: any) => {
    const [propayRecord, setProPayRecord] = useState(null);
    const location = useLocation();
    const pathnameParts = location.pathname.split('/');
    const id = pathnameParts[pathnameParts.length - 2];
    const {data, loading} = useQuery({
        type: 'getOne',
        resource: 'propay',
        payload: { id:id}
    })
    useEffect(()=>{
        if(!loading && data){
            setProPayRecord(data);
        }
    },[data, loading]);

    return (
        <PageAlertContextProvider>
            <Show className="propay-detail-show" title={' '}>
                <PageAlerts />
                {!propayRecord && loading ? <PropayDetailPageShimmer /> :
                <PropayDetailPage />}
            </Show>
        </PageAlertContextProvider>
    );
};

export const DatagridBodyExpandHeadless = (props: DatagridBodyProps) => {
    return <DatagridBody {...props} row={<DatagridRow headlessExpand />} />;
};


export const BulkPropayActions = (props) => {
    const { total, data, selectedIds } = useListContext();
    const {identity,setBulkAction,setHideAction,propayFilter, bulkAction} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if(!selectedIds.length) {
            setBulkAction('')
        }
    },[selectedIds])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
      setAnchorEl(null);
      setHideAction(true)
    };

    if (identity?.user_type === USER_TYPE.crew_lead) return null

    return (
        <div>
            <Button
                id="bonus-action-menu"
                className='filter-actions-button'
                aria-controls={open ? 'bonus-action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <svg width="4" height="18" viewBox="0 0 4 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 4.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill-rule="evenodd" />
                </svg>
            </Button>
            <Menu
                id="bonus-action-menu"
                anchorEl={anchorEl}
                open={open}
                className='bonus-action-menu'
                onClose={closeMenu}
                disableScrollLock={true}
                MenuListProps={{
                    'aria-labelledby': 'bonus-action-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <MenuItem className='bulk-default-link export-menu-link'>
                <ExportPropay propayFilter={propayFilter} columnOptions={props.columnOptions} identity={identity}/>
                </MenuItem>

                {(total && identity?.user_type !== USER_TYPE.worker) ?
                <>
                <MenuItem className='bulk-action-divider bulk-actions-pbs' disableRipple>Bulk Actions:
                </MenuItem>
                 <MenuItem className='bulk-update-budget bulk-default-link' onClick={()=>{setBulkAction(PROPAY_ACTION_OPTION.updateBudget);closeMenu()}}>
                    <ListItemIcon>
                    {CURRENCY_DOLLAR_ICON()}
                    </ListItemIcon> Update Budget
                </MenuItem>
                <MenuItem className='bulk-update-budget bulk-default-link' onClick={()=>{setBulkAction(PROPAY_ACTION_OPTION.bonusPool);closeMenu()}}>
                    <ListItemIcon>
                    {BONUS_POOL_ICON_02()}
                    </ListItemIcon> Update Bonus Pool
                </MenuItem>

                <MenuItem className='bulk-action-approve bulk-default-link' onClick={()=>{setBulkAction('Approve');closeMenu()}}><ListItemIcon>{COMPLETE_PROPAY_ACTION()}</ListItemIcon> Approve
                </MenuItem>

                <MenuItem className='bulk-action-pending bulk-default-link' onClick={()=>{setBulkAction('Pending');closeMenu()}}> <ListItemIcon>{PENDING_ICON()}</ListItemIcon> {identity?.company?.is_started_status  ? 'Pending or Start' : 'Pending'}
                </MenuItem>
                {/* Will Disable this option until further direction is given */}
                <MenuItem className='bulk-default-link' onClick={()=>{setBulkAction('GroupProPay');closeMenu()}}> <ListItemIcon>{GROUP_EXPORT_ICON()}</ListItemIcon> GroupProPay
                </MenuItem>
                {identity?.company?.is_completed_status && <MenuItem className='bulk-action-complete bulk-default-link' onClick={()=>{setBulkAction('Complete');closeMenu()}}> <ListItemIcon>{COMPLETE_PROPAY_ACTION()}</ListItemIcon> Complete
                </MenuItem>}
                <MenuItem className='bulk-action-delete bulk-default-link' onClick={()=>{setBulkAction('Cancel');closeMenu()}}> <ListItemIcon>{CLOSE_ICON_ACTIONS()}</ListItemIcon> Cancel
                </MenuItem>
                <MenuItem className='bulk-action-delete bulk-default-link' onClick={()=>{setBulkAction('Delete');closeMenu()}}> <ListItemIcon>{BIN_ICON()}</ListItemIcon> Delete
                </MenuItem>
                </>:''}
            </Menu>
        </div>
    );
  }

const PropayList = (props:any) => {
    const { options,propayFilter,setPropayFilter,identity,connectorList } = props;
    const navigate = useNavigate();
    const refresh = useRefresh();
    const notify = useNotify();
    const propayContext = useContext(CustomFilterContext);
    const queryClient = useQueryClient();
    const [bulkAction,setBulkAction]= useState('');
    const [hideAction,setHideAction]= useState(true);
    const [showGroupPropay, setShowGroupPropay] = useState(false);
    const propaySort = JSON.parse(localStorage.getItem('propaySort')) || { field: 'create_date', order: 'DESC' };
    const [scrollCl, setScrollClass] = useState('');
    const [alertModal, setAlertModal] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [selectedListData, setSelectedListData] = useState([]);
    const [successFlag, setSuccessFlag] = useState(false);
    const [statusFilter,setStatusFilter]=useState([])

    useEffect(() => { }, [propayFilter,bulkAction]);

    const refreshList = () => {
        queryClient.refetchQueries();
    }

    const handleCloseGroupPropay = () => {
        setShowGroupPropay(false);
    }

    const showPropay = (PropayId: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('filter');
        if (queryParams.has('page')) {
            queryParams.set('page', '1');
        }
        const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
        queryParams.has('page') ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } }) :
            navigate(`/show/${PropayId}/propay`, { state: { _scrollToTop: true } });
        setTimeout(() => { refresh(); }, NUMBER.ONE_THOUSAND);
    };
    useEffect(() => {
        const handleScroll = () => {
          const currentPosition = window.scrollY || window.pageYOffset;
          const isAtBottom = Math.ceil(currentPosition + window.innerHeight) >= document.body.scrollHeight;
          if (isAtBottom) {
            setScrollClass('bulk-listing-table');
          }else{
            setScrollClass('');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return(()=>{ window.removeEventListener('scroll', handleScroll);})
      }, []);

    const rowStyleForAutomatedPropayWithoutBudget = (record, index) => {
        if (findpropayWithoutBudget(record)) {
            return {
                backgroundColor:'#FEF2C8',
            };
        } else {
            return {
            };
        }
    };

    const CustomRow = (props) => {
        const record = useRecordContext(props);

        return (
            <div className="custom-row">
                <Tooltip title={record.is_group_propay ? "This row is a GroupProPay" : ""} placement="top">
                    <Checkbox disabled={record.is_group_propay} />
                </Tooltip>
                {props.children}
            </div>
        );
    };

    const handleAlertUserForBudget = () => {
        notify(`Please add missing budget in order to see ProPay details.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
    }
    useEffect(()=>{
        const queryExtended = {
            parent_id: { _is_null: true },
            job_id_obj: {
              ...(identity?.allowed_branches?.length > 0 
                && { branch_id: { _in: identity.allowed_branches } } 
               ),
              ...(identity?.allowed_divisions?.length > 0 
                && { division_id: { _in: identity.allowed_divisions } } 
                )
            }
          };
          isAllowedFilters(identity , queryExtended , {} , setPropayFilter);
    },[identity?.user_type])


          return (
            <>
           <ResourceContextProvider value="propays">
           <div className='propay-list-page'>
            <div className='filter-toolbar propay-new-toolbar'>
                <PageAlerts />
                <List
                    filterDefaultValues={{parent_id: {_is_null: true}}}
                    filter={propayFilter}
                    resource='propays'
                    title={'ProPay'}
                    filters={<ResponsiveFilterGusser filters={CustomPropayFilters} />}
                    actions={<ListActions exporter={false} />}
                    sort={propaySort}
                    className='propay-listing propay-listing-columns'
                    disableSyncWithLocation={CONSTANT_DATA.true}
                >
                    <>
                <div className='propay-filter-toolbar'>
                 <EditColumnsOpenDrawer {...props} connectorList={connectorList} showFilter={true} propayExport={identity?.user_type === USER_TYPE.worker} setFilterQuery={setPropayFilter} propayFilter={propayFilter} options={options} identity={identity} statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>
                {(![USER_TYPE.worker, USER_TYPE.crew_lead].includes(identity?.user_type)) ? <div className='filter-action-propay'>
                <span className='bulk-actions-text bonus-bulk-actions-text'>Actions:</span>
                <BulkPropayActions propayFilter={propayFilter}  setBulkAction={setBulkAction} bulkAction={bulkAction} setHideAction={setHideAction} identity={identity} columnOptions={options}/>
                </div>:''}
                </div>
                <PropayIndicators propayFilter={propayFilter} identity={identity} statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>

               {(bulkAction && hideAction && !selectedListData.length) && <div className='close-statement-toolbar'>
                <div className='close-bulk-action-text'>
               <IconButton onClick={()=>{setBulkAction('');setHideAction(true)}} size="medium" className='cross-statement'>
                    <CLOSE_ICON />
                </IconButton>
               Select ProPays to {(identity?.company?.is_started_status && bulkAction === 'Pending') ? `${bulkAction} or Start` : bulkAction}
               </div>
               <Button className='bulk-approve-button' disabled>{(identity?.company?.is_started_status && bulkAction === 'Pending') ? `${bulkAction} or Start` : bulkAction}</Button>
            </div>}
                    <div className='listing-scroll common-listing-scroll'>
                    <Datagrid
                        size="small"
                        rowClick="expand"
                        isRowSelectable={(record) => (!findpropayWithoutBudget(record) && identity?.user_type !== USER_TYPE.worker)}
                        bulkActionButtons={((bulkAction) ? <PropayActions successFlag={successFlag} setSuccessFlag={setSuccessFlag} setSelectedListData={setSelectedListData} selectedListData={selectedListData} setGroupName={setGroupName} groupName={groupName} setAlertModal={setAlertModal} alertModal={alertModal} setShowGroupPropay={setShowGroupPropay} setHideAction={setHideAction} hideAction={hideAction} setBulkAction={setBulkAction} bulkAction={bulkAction} connectorList={connectorList}/> : <PropayBulkAction  connectorList={connectorList} setSelectedListData={setSelectedListData} selectedListData={selectedListData} setShowGroupPropay={setShowGroupPropay} setGroupName={setGroupName} groupName={groupName} successFlag={successFlag} setSuccessFlag={setSuccessFlag} setAlertModal={setAlertModal} alertModal={alertModal} />)}
                        empty={<Empty />}
                        rowStyle={rowStyleForAutomatedPropayWithoutBudget}
                        body={DatagridBodyExpandHeadless}
                        className='data-grid-listing'
                        stickyHeader={true}
                    >
                        {checkforColumnVisibility(options, PROPAY_COLUMN.TITLE) && <FunctionField
                            source="name"
                            label={getColumnLabel(options,PROPAY_COLUMN.TITLE)}
                            className="no-translate"
                            render={record => {
                                return (
                                    <>
                                    {findpropayWithoutBudget(record) && <div className='missing-budget-name'>{ALERT_ICON_WHITE()} ProPay is missing budget</div>}
                                        {record?.name?.length > NUMBER.FOURTY_TWO ? (
                                            <>
                                            <Tooltip title={record?.name} placement="bottom" arrow>
                                                <span className='propay-title-hover' onClick={() => !findpropayWithoutBudget(record) ? showPropay(record.id) : handleAlertUserForBudget()} >
                                                    {truncatePropayListName(record?.name.toString())}
                                                </span>
                                            </Tooltip>
                                            {record?.is_group_propay && <Button className='propay-grouped-btn'>Grouped</Button>}
                                            </>
                                        ) : (
                                            <>
                                            <span className='propay-title-hover' onClick={() => !findpropayWithoutBudget(record) ? showPropay(record.id) : handleAlertUserForBudget()}>{record?.name} </span>
                                            {record?.is_group_propay && <Button className='propay-grouped-btn'>Grouped</Button>}
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        />}
                        {checkforColumnVisibility(options, PROPAY_COLUMN.MANAGER) && <FunctionField
                            source="manager_id"
                            label={getColumnLabel(options, PROPAY_COLUMN.MANAGER)}
                            render={record => {
                                return (
                                    <>
                                     <ManagerFullNameField managerName={record?.manager_id_obj} />
                                    </>
                                );
                            }}
                        />
                        }
                        {checkforColumnVisibility(options, PROPAY_COLUMN.JOB) &&
                        <FunctionField
                            source="job_id"
                            label={connectorList.length > 1 ? `Job-${connectorList[NUMBER.ZERO]?.name || connectorList[NUMBER.ZERO]?.service}` : getColumnLabel(options, PROPAY_COLUMN.JOB)}
                            render={(record) => (record?.is_group_propay && record?.job_ids_obj?.length > NUMBER.ZERO ? <JobIdsChip record={record} />  : <> {record?.job_id ? record?.job_id_obj?.full_name:''} </>)}
                            />
                        }
                        {(connectorList.length > NUMBER.ONE && checkforColumnVisibility(options, PROPAY_COLUMN.SECOND_JOB)) && <FunctionField
                            source="second_job_id"
                            label={`Job-${connectorList[NUMBER.ONE]?.name || connectorList[NUMBER.ONE]?.service}`}
                            render={(record) => (<> {record?.second_job_id ? record?.second_job_id_obj?.full_name :''} </>)}
                        />}

                        {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_HOURS) &&
                            <NumberToTimeField
                            fromPropay={true}
                            source="estimated_hours"
                            label={getColumnLabel(options, PROPAY_COLUMN.BUDGET_HOURS)}
                            className="no-translate"
                            identity={identity}
                            />
                        }
                        {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_AMOUNT) &&
                            <MoneyField fromPropay={true} className="no-translate"  source="budget" label={getColumnLabel(options, PROPAY_COLUMN.BUDGET_AMOUNT)}/>
                        }


                        {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_HOURS) &&
                            <NumberToTimeField
                            source="hours"
                            fromPropay={true}
                            identity={identity}
                            label={getColumnLabel(options, PROPAY_COLUMN.ACTUAL_HOURS)}
                            className="no-translate"
                            />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_AMOUNT) &&
                            <MoneyField fromPropay={true} source="attendance_earning" className="no-translate"
                            label={getColumnLabel(options, PROPAY_COLUMN.ACTUAL_AMOUNT)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.VARIANCE_AMOUNT) &&
                            <MoneyField fromPropay={true} source="remaining_amount" className="no-translate"
                            label={getColumnLabel(options, PROPAY_COLUMN.VARIANCE_AMOUNT)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.VARIANCE_HOURS) &&
                            <NumberToTimeField
                                source="remaining_hours"
                                fromPropay={true}
                                label={getColumnLabel(options, PROPAY_COLUMN.VARIANCE_HOURS)}
                                className="no-translate"
                                identity={identity}
                            />
                        }

                        {connectorList?.some(item => item?.support_options?.import_entities?.includes("job_type")) && checkforColumnVisibility(options, PROPAY_COLUMN.JOB_TYPE) &&
                        <FunctionField
                        source="job_type_ids"
                        label={getColumnLabel(options, PROPAY_COLUMN.JOB_TYPE)}
                        render={(record) => (record?.is_group_propay && record?.job_type_ids_obj?.length > NUMBER.ZERO ? <JobTypeIdsChip fieldName={'job_type_ids_obj'} record={record} />  : <> {record?.job_id_obj?.job_type_id_obj?.name !== 'false' ? record?.job_id_obj?.job_type_id_obj?.name:''} </>)}
                        />

                        }

                        {connectorList?.some(item => item?.service === 'aspire' && item?.status === 'authorized') && checkforColumnVisibility(options, PROPAY_COLUMN.INVOICE_TYPE) &&
                        <FunctionField
                        source="invoice_type_ids"
                        sortBy='invoice_type_ids'
                        label={getColumnLabel(options, PROPAY_COLUMN.INVOICE_TYPE)}
                        render={(record) => <JobTypeIdsChip fieldName={'invoice_type_ids_obj'} record={record} />}
                        />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.APPROVED_BONUSES) &&
                            <MoneyField fromPropay={true} source="performance_bonus" className="no-translate"
                            label={getColumnLabel(options, PROPAY_COLUMN.APPROVED_BONUSES)} />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.COST_CODES) && identity?.company?.can_use_cost_code &&
                            <FunctionField
                            source="cost_code_ids"
                            label={getColumnLabel(options, PROPAY_COLUMN.COST_CODES)}
                            className='milestone-label'
                            data-title='Cost Codes'
                            render={(record: any) =>
                                !findpropayWithoutBudget(record) ? record.cost_code_ids_obj.length > NUMBER.ZERO ? <CostCodeChip record={record}/>
                                 : null : <div className='add-budget-alert'>~</div>
                            }
                        />
                        }

                        {checkforColumnVisibility(options, PROPAY_COLUMN.START_DATE) &&
                        <FunctionField
                            className="no-translate"
                            source="from_date"
                            label={getColumnLabel(options, PROPAY_COLUMN.START_DATE)}
                            sortable
                            render={(record: any) => (
                                 <DateFormat date={record.from_date} showTilde={findpropayWithoutBudget(record)}/>
                            )}
                        />}

                        {checkforColumnVisibility(options, PROPAY_COLUMN.END_DATE) && <FunctionField
                            className="no-translate"
                            source="to_date"
                            label={getColumnLabel(options, PROPAY_COLUMN.END_DATE)}
                            sortable
                            render={(record: any) => (
                                <DateFormat date={record.to_date} showTilde={findpropayWithoutBudget(record)}/>
                            )}
                        />}
                        {checkforColumnVisibility(options, PROPAY_COLUMN.STATUS) &&
                        <FunctionField
                        className="no-translate"
                        source="status"
                        sortable
                        render={(record: any) => (
                             <PropayStatusField record={record} />
                        )}
                        />}

                        {/* {checkforColumnVisibility(options, PROPAY_COLUMN.STATUS_BUTTON) && <FunctionField
                            className="no-translate"
                            label={getColumnLabel(options, PROPAY_COLUMN.STATUS_BUTTON)}
                            source="status"
                            sortable={false}
                            render={(record: any) => (
                                !findpropayWithoutBudget(record) ? <ActionButtons record={record} refresh={refreshList} /> : <div className='add-budget-alert'>~</div>
                            )}
                        />} */}

                         <FunctionField
                            textAlign="right"
                            label={'Actions'}
                            render={(record: any) => (
                                <>
                                    {!findpropayWithoutBudget(record) ? <CardListActions
                                        record={record}
                                        onList={true}
                                        refresh={refreshList}
                                    /> : <CardListActionsProPayWithoutBudget record={record} onList={true}/>}
                                </>
                            )}
                        />

                    </Datagrid>
                    </div>
                    </>

                </List>
            </div>
            </div>
        </ResourceContextProvider>
        {showGroupPropay && <CreateGroupPropay
                    groupName={groupName}
                    setSuccessFlag={setSuccessFlag}
                    successFlag={successFlag}
                    setGroupName={setGroupName}
                    record={{}}
                    selectedListData={selectedListData}
                    //selectedValue={selectedValue}
                    open={showGroupPropay}
                    onClose={handleCloseGroupPropay}
                    />}

        </>
    );
};

export const GroupApproveModal = (props: any) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const options = ['Approve', 'Remove & Approve', 'Delete & Approve'];
    const { isOpen, onClose, selectedGroupData, record, setShowActionModal, handleClick } = props;
    const propayWithZeroHour = selectedGroupData?.grouped_propay_ids?.filter(item => item?.hours === 0);
    const notify = useNotify();

    const handleClickApprove = (action) => {
        switch(action){
            case 'Delete & Approve': {
                if(record?.hours){
                    removeAndDeleteFromGroup(propayWithZeroHour);
                }else{
                    notify(`You can not delete all ProPays. There should be at least 2 ProPays in a group ProPay.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
                }
                return;
            }
            case 'Remove & Approve': {
                removeFromGroup(propayWithZeroHour);
                return;
            }
            default:{
                handleClick({...record, actionName:'Approve'}, false, true);
                return;
            }
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };
    const dataProvider = useDataProvider();
    const {enqueueSnackbar} =  useSnackbar();
    const removeFromGroup = (record) => {
        const payload = {
            grouped_propay_id: Number(props?.record?.id),
            propay_ids: record?.map((item)=>item?.id)
        }
        onClose();
        notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
        POST(PROPAY_BULK_ACTION_API.RemoveGroupPropay, payload).then((res: any) => {
            getSyncIdInfo(res?.bulk_sync_info_id)
        })
    }
    const removeAndDeleteFromGroup = (records) => {
        const payload = {
            propay_ids: records?.map((item)=>item?.id),
            action_name: 'delete'
        }
        onClose();
        notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
        POST(PROPAY_BULK_ACTION_API['Delete'], payload).then((res: any) => {
            if(res.length && res[0]?.is_success){
                enqueueSnackbar('ProPays removed from group ProPay and deleted successfully.', { variant:'success', key: 'propay', autoHideDuration: 6000 });
                handleClick({...record, actionName:'Approve'}, false, true)
            }
        })
    }

    const getSyncIdInfo = (syncId) => {
        const interval = setInterval(() => {
            dataProvider.getOne('protivBulkSyncInfo', { id: syncId }).then((res) => {
                if (res?.data?.status === 'success') {
                    const result = JSON.parse(res?.data?.result);
                    enqueueSnackbar(getFormatedMessage(result), { variant: res?.data?.failure_count > 0 ? 'error' : 'success', key: 'propay', autoHideDuration: 6000 });
                    if(res?.data?.failure_count === 0){
                        handleClick({...record, actionName:'Approve'}, false, true)
                    }
                    clearInterval(interval);
                }
            })
        }, 1500);
    }

    return (
        <>
            <StyledDialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                className='common-dialog-modal alert-group-propay'
            >
                <DialogTitle id="grouped-propay-details-title">
                    <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="Grouped ProPays ProPay Details"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="approved-dialog-description">
                        {/* <div className='approved-propay-title approved-propay-grey-title'>Grouped ProPays</div> */}
                        <div className='approved-detail-accordion'>
                            <div className='approve-detail-body'>
                                <div className='approved-propay-title approved-propay-grey-title'>Approve Grouped ProPay</div>
                                <p>These ProPays doesn't have any hours, do you want to proceed?</p>
                                {
                                    propayWithZeroHour?.map((item, index) => {
                                        return (<div className='approved-alert approved-alert-error' key={item.id}>
                                            <div className='approved-alert-head approved-alert-grey-text'>
                                                <span className='approved-alert-head-space'>{index + 1}.</span>{item?.name}
                                                <Button className='approved-alert-btn'>{APPROVED_CLOSE_ICON()}</Button>
                                            </div>
                                            <div className='i-j-t-body'>
                                                {item?.invoice_type_ids_obj?.length ? <div className='i-j-t-b-text'>
                                                    <strong>Invoice Type:</strong>
                                                    {item?.invoice_type_ids_obj[0]?.name}
                                                </div> : ''}
                                                {item?.job_type_ids_obj?.length ? <div className='i-j-t-b-text'>
                                                    <strong>Job Type:</strong>
                                                    {item?.job_type_ids_obj[0]?.display_name}
                                                </div> : ''}
                                            </div>
                                            <div className='approved-alert-body'>
                                                No hours entered!
                                            </div>
                                        </div>)
                                    })
                                }

                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='cancel-proceed-actions'>
                    <Button className='cancel-button' onClick={() => onClose()}>Cancel</Button>
                    {/* <Button className='update-button' onClick={e => handleClick(record, false, true)}>Proceed</Button> */}
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                        className='approve-btn-gp'
                    >
                        <Button onClick={()=>handleClickApprove(options[selectedIndex])}>{options[selectedIndex]}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>

                    <Popper
                        open={open}
                        className='approve-pop-over'
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </DialogActions>
            </StyledDialog>
        </>
    );
}

export const AlertModal = (props:any) => {

    const { isOpen, onClose, selectedData, showMessage, setShowGroupPropay } = props;

    const filteredData = selectedData?.filter(el => !el?.has_milestones && el?.status !== 'approved' && el?.status !== 'closed' && !el?.is_group_propay);

    return (
    <StyledDialog
    open={isOpen}
    title='GroupProPay Selections'
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    className='common-dialog-modal alert-group-propay'
>
    <DialogTitle id="grouped-propay-details-title">
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Box>GroupProPay Selections</Box>
            <IconButton
                sx={{ top: -2, paddingLeft: 2 }}
                color="primary"
                aria-label="Grouped ProPays ProPay Details"
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </Stack>
    </DialogTitle>
    <DialogContent>
    {selectedData.length > 1 &&  <div>These ProPays cannot be grouped, would you like to exclude them and proceed ?</div>}

        <DialogContentText id="approved-dialog-description">
            <>
           {selectedData?.length >= 2 ?
           <>
            <div className='approved-detail-accordion'>
                <div className='approve-detail-body'>
                   {/* {
                     filteredData?.map((propayObj, propayIndex) => (
                        <div key={propayIndex} className='approved-alert approved-alert-info'>
                        <div className='approved-alert-head approved-alert-grey-text'>
                            <span className='approved-alert-head-space'>{propayIndex + 1}.</span>{propayObj?.name}
                            <Button className='approved-alert-btn'>{STATUS_ICON()}</Button>
                        </div>
                        <div className='approved-alert-body approved-alert-grey-text'>
                            Grouped
                        </div>
                    </div>
                    ))
                   } */}
                   {/* <div className='approved-propay-title approved-xpropay-grey-title'>Unsuccessful ProPays</div> */}
                   {
                       showMessage?.map((messageValue, messageIndex) => (
                            <div key={messageIndex} className='approved-alert approved-alert-error'>
                            <div className='approved-alert-head approved-alert-grey-text'>
                                <span className='approved-alert-head-space'>{messageIndex + 1}.</span>{messageValue.message}
                                <Button className='approved-alert-btn'>{APPROVED_CLOSE_ICON()}</Button>
                            </div>
                            <div className='approved-alert-body'>
                                {messageValue?.name}
                            </div>
                            </div>

                    ))
                   }
                   </div>
            </div>
           </>
            :
            <div className='approved-propay-title approved-propay-grey-title'>Can't Group a Single ProPay</div>
        }
        {selectedData.length > 1 && <div className='rem-propay-grouped'>{filteredData.length} Remaining ProPays to be grouped.</div>}
            </>
        </DialogContentText>
    </DialogContent>
    <DialogActions className='button-justified d-a-group-propay'>
    <Button className='cancel-button' onClick={()=>onClose()}>Cancel</Button>
   {selectedData.length > 1 && <Button className='update-button' onClick={() => {
        onClose()
        setShowGroupPropay(true)
    }}>Proceed</Button>}
    </DialogActions>
</StyledDialog>
    )
}

/*Handling grid and list Type check */
export const PropayListType = (props: any) => {
    const { listingType } = props;
    const isMobileDevice = isMobile();
    const dataProvider = useDataProvider();
    const [useFieldsData, setUserFileds] = useState([]);
    const [mobilePropayFilter,setMobilePropayFilter] = useState(null);
    const [propayFilter, setPropayFilter] = useState({});
    const { identity } = useGetIdentityOptimized();
    const [connectorList,setConnectorList]= useState([]);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => {
        const dashboardApiCalls = async () => {
            let userFields = await dataProvider.getList('userFields',{pagination: { page: NUMBER.ONE, perPage: NUMBER.FIFTY },
                                        sort:{field: 'field_id', order: 'ASC'},filter:{model: {_eq: 'protiv.propay'}}});
            userFields?.data && userFields?.data?.forEach((item,i)=>{
                if( item.field_id === PROPAY_COLUMN.TITLE ){
                    userFields?.data?.splice(i, NUMBER.ONE);
                    userFields?.data?.unshift(item);
                }
            });
          //This will always show the title column for every User.
          //TODO: Remove when all existing data is migrated
          if (userFields?.data) {
            userFields = {
              ...userFields,
              data: userFields.data.map(column => {
                if (column.field_id === 14 || column.field_id === 25) {
                  return { ...column, is_visible: true };
                }
                return column;
              })
            }
          }

          if(userFields?.data) {
            const filteredData = userFields?.data.filter(item => item.field_id !== PROPAY_COLUMN.STATUS_BUTTON && item.field_id !== PROPAY_COLUMN.ACTION);
            const filterSecondJob = filteredData.filter(item => item.field_id !== PROPAY_COLUMN.SECOND_JOB)
              setUserFileds(_.cloneDeep(connectorList.length === 2 ? filteredData:filterSecondJob))
          } else {
              setUserFileds(_.cloneDeepWith(userFields?.data));
          }
        }
        dashboardApiCalls();
    }, [dataProvider,connectorList]);

    useEffect(()=>{
        const queryExtended = {
            parent_id: { _is_null: true },
            job_id_obj: {
              ...(identity?.allowed_branches?.length > 0 
                && { branch_id: { _in: identity.allowed_branches } } 
               ),
              ...(identity?.allowed_divisions?.length > 0 
                && { division_id: { _in: identity.allowed_divisions } } 
                )
            }
          }
        isAllowedFilters(identity , queryExtended , {} , setPropayFilter )
    },[identity?.user_type])



    return (
        <>
            {listingType === 'List' && !isSmall ? (

                <PropayList connectorList={connectorList} identity={identity} setPropayFilter={setPropayFilter} propayFilter={propayFilter} options={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} userFields={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} setUserFileds={setUserFileds}/>
            ) : (
                <div className="propay-page-card filter-toolbar" id='card-list-view'>
                    <ResourceContextProvider value="propays">
                        <>
                            <PageAlerts />
                            <List filterDefaultValues={{parent_id: {_is_null: true}}}
                                filter={isSmall ? mobilePropayFilter:propayFilter}
                                resource='propays'
                                title='ProPay'
                                filters={!isSmall ? <ResponsiveFilterGusser filters={CustomPropayFilters}/>:<></>}
                                actions={<ListActions exporter={false} />}
                                sort={{ field: 'create_date', order: 'DESC' }}
                                emptyWhileLoading
                                disableSyncWithLocation={CONSTANT_DATA.true}
                            >
                              <>
                                {isSmall ? <>
                                 <PropayFilterMobile connectorList={connectorList} identity={identity} setMobilePropayFilter={setMobilePropayFilter} mobilePropayFilter={mobilePropayFilter}/>
                                <GroupPropayCardResponsive showQuickFilter={true} propayFilter={propayFilter} connectorList={connectorList} onDashboard={false} showExport={!isMobileDevice} options={useFieldsData} setMobilePropayFilter={setMobilePropayFilter}/>
                                <ResponsivePropayAction setUserFileds={setUserFileds} columnOptions={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} identity={identity} propayFilter={mobilePropayFilter}/>
                                 </> :
                                 <>
                                  <EditColumnsOpenDrawer connectorList={connectorList} {...props} propayExport={false} showFilter={true} setFilterQuery={setPropayFilter} propayFilter={propayFilter}  userFields={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))}
                                 options={_.cloneDeepWith(useFieldsData?.filter((item)=>item?.field_id !== PROPAY_COLUMN.STATUS_BUTTON))} setUserFileds={setUserFileds}/>
                                 <CardListView propayFilter={propayFilter} connectorList={connectorList} onDashboard={false} showExport={!isMobileDevice} options={useFieldsData}/>
                                 </>
                                 }
                              </>
                            </List>
                        </>
                    </ResourceContextProvider>
                </div>
            )}
        </>
    );
};

export const PropayTab = (props: any) => {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route
                    path=""
                    element={
                        <>
                            <PropayListType listingType={props.type} />
                            <Outlet />
                        </>
                    }
                />
                <Route
                    path=":id/show/changes/:id/show"
                    element={
                            <PropayChanges />
                    }
                />
            </Route>
        </Routes>
    );
};
