import ActionCheck from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmClasses, useNotify,useMutation,CRUD_UPDATE } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { FormGroup, IconButton, Stack } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import { decimalValue } from '../../utils/Constants/ConstantData';
import { useQueryClient } from 'react-query';
import { getMaxHours } from '../../dashboard/Calculator';
import get from 'lodash/get';
import _ from 'lodash';
import { Switch } from '@mui/material';
import { INFO_ICON_OUTLINED } from '../../utils/Constants/ClassName';
const milestonebonus = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 100,
        label: '100%',
    },
];



export const MilestonesMarkAsCompleteModal = (props: any) => {
    const {
        isOpen = false,
        loading,
        title,
        content,
        confirmColor = 'primary',
        ConfirmIcon = ActionCheck,
        onClose,
        onConfirm,
        deficit_amount,
        ButtonOneText,
        buttonTwoText,
        record,
        refetch,
        dialogRefs,
        setEmployeeData,
        employeeData,
    } = props;

    const queryClient = useQueryClient();
    const [mutate] = useMutation();
    const notify = useNotify();
   const [isApplyDefict,setIsApplyDeficit] = useState(false);
   const [bonusPercent,setBonusPercent]=useState(record.bonus_percent);
   const [performanceBonus,setPerformanceBonus]=useState(record.performance_bonus);
   const [reserve,setReserve]=useState(record.reserve_amount);
   const [reservePercent,setReservePercent]=useState(record.reserve_percent);
   const [isRestricted,setIsRestricted]=useState(false);
   const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });
    const valuetext =(value: number)=> {
       return `${value}%`;
    }
    useEffect(()=>{
        if(['pending', 'started','completed']?.includes(record.status)){
            calculate(record.bonus_percent)
        }
    },[])

    useEffect(()=>{},[bonusPercent,performanceBonus,reserve,reservePercent,isRestricted])

    useEffect(()=>{
        if(employeeData?.callApprovePropay){
            handleConfirm(true);
            setEmployeeData((prev)=>({...prev, action:'',callApprovePropay:false}))
        }
    },[employeeData?.callApprovePropay])

    const handleConfirm =(forceUpdate?:boolean)=>{
        const workerWithoutWage = record?.employee_wage_ids?.filter((workerRec)=> !workerRec?.base_wage && workerRec?.hours)
        if(workerWithoutWage?.length && !forceUpdate){
          dialogRefs?.current?.open();
          setEmployeeData((prev)=>({...prev,employeeRecord:workerWithoutWage, propayRecord:record, action:'approveMilestone'}))
        }else{
             mutate(
                {
                    type: 'update',
                    resource: 'propays',
                    payload: {id: record.id, action:('approveMilestone'),bonus_percent:bonusPercent,is_restricted:isRestricted,is_apply_deficit:isApplyDefict, data: { } }
                },
                {
                    mutationMode: 'pessimistic',
                    action: CRUD_UPDATE,
                    onSuccess: (
                        data: any,
                        variables: any = {}
                    ) => {
                     refetch();
                     queryClient.invalidateQueries(['propays', 'getList']);
                      onClose();
                    },
                    onFailure: error => {
                        notify(`Failure ! ${error.message}`);
                    }
                }
                );
            }
        }

    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

const calculate = (value)=>{
setBonusPercent(value)
let res = Math.abs(NUMBER.HUNDRED - value);
setReservePercent(res)
let resAmount = ((res / NUMBER.HUNDRED) * record.available_bonus);
setReserve(resAmount.toFixed(NUMBER.TWO))
let bonusAmount =((value / NUMBER.HUNDRED) * record.available_bonus);
setPerformanceBonus(bonusAmount.toFixed(NUMBER.TWO))
}
    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal milestone-completed-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {record.name}
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="upload picture"
                        disabled={loading}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
             {deficit_amount === 0 ?  <>
               {(record.available_bonus > NUMBER.ZERO || reserve )  && <div className="milestone-congrats-msg">
                    Congratulations! Your team save {decimalValue(record.estimated_hours - record.hours)} hrs. <br />
                    They have an available bonus of {formatter.format(decimalValue(record.available_bonus || reserve))}
                </div>}

                <div className="bonus-amount-text">
                    Set the bonus amount you wish to pay:
                </div>
                <div className="bonus-percent-text">
                    Bonus ({decimalValue(bonusPercent)}%){' '}
                    <span>
                        {formatter.format(decimalValue(performanceBonus))} / <span className="bonus-percent-neg">{' '}{formatter.format(decimalValue(reserve))}</span>
                    </span>
                    {' '}Reserve ({decimalValue(reservePercent)}%)
                </div>
                </> :
                <>
                {(record.available_bonus > NUMBER.ZERO || reserve )  && <div className="milestone-congrats-msg">
                    Congratulations! Your team save {decimalValue(record.estimated_hours - record.hours)} hrs. <br />
                    They have an available bonus of {formatter.format(decimalValue(record.available_bonus || reserve))}
                </div>}
                {/* <div className="select-option-milestone">
                  Deficit from previous workers of -{formatter.format(decimalValue(deficit_amount))} has not been recovered.
                </div>
                <div className="calculate-bonus-item apply-deficit-item">
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Apply deficit"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                value="Apply deficit"
                                control={<Radio />}
                                label="Apply deficit"
                                onClick={()=>setIsApplyDeficit(false)}
                            />
                            <FormControlLabel
                                value="Don’t apply deficit"
                                control={<Radio />}
                                label="Don’t apply deficit"
                                onClick={()=>setIsApplyDeficit(true)}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className='deficit-row'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.99993 15V10.625M9.99993 10.625C10.4216 10.6254 10.8416 10.5724 11.2499 10.4675M9.99993 10.625C9.5783 10.6254 9.15831 10.5724 8.74993 10.4675M11.8749 16.6992C10.636 16.9345 9.36386 16.9345 8.12493 16.6992M11.2499 18.685C10.4189 18.772 9.58099 18.772 8.74993 18.685M11.8749 15V14.84C11.8749 14.0209 12.4233 13.3209 13.1316 12.91C14.3216 12.2209 15.2511 11.1585 15.7761 9.88757C16.3011 8.61659 16.3923 7.20794 16.0355 5.87989C15.6787 4.55185 14.8939 3.37854 13.8026 2.54176C12.7114 1.70499 11.3747 1.25146 9.99951 1.25146C8.62438 1.25146 7.28762 1.70499 6.19638 2.54176C5.10513 3.37854 4.32032 4.55185 3.96354 5.87989C3.60676 7.20794 3.69793 8.61659 4.22292 9.88757C4.74792 11.1585 5.67743 12.2209 6.86743 12.91C7.57576 13.3209 8.12493 14.0209 8.12493 14.84V15" stroke="#FD9C14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
How rollover deficit works?
<span className='learn-more-deficit'>Learn more</span>
                </div> */}
                <div className="bonus-percent-text">
                    Bonus ({decimalValue(bonusPercent)}%){' '}
                    <span>
                        {formatter.format(decimalValue(performanceBonus))} / <span className="bonus-percent-neg">{' '} {formatter.format(decimalValue(reserve))}</span>
                    </span>
                     {' '}Reserve ({reservePercent || NUMBER.ZERO}%)
                </div>
                </> }
                <div className="milestone-slider">
                    <Slider
                        aria-label="Milestone Bonus Marks"
                        defaultValue={bonusPercent}
                        onChange={(e,val)=>{calculate(val)}}
                        getAriaValueText={valuetext}
                        step={1}
                        valueLabelDisplay="auto"
                        marks={milestonebonus}
                    />
                </div>

                <div className='restrict-further-entries'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M11 7.5V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7.5M4.5 15H11.5C11.8978 15 12.2794 14.842 12.5607 14.5607C12.842 14.2794 13 13.8978 13 13.5V9C13 8.60218 12.842 8.22064 12.5607 7.93934C12.2794 7.65804 11.8978 7.5 11.5 7.5H4.5C4.10218 7.5 3.72064 7.65804 3.43934 7.93934C3.15804 8.22064 3 8.60218 3 9V13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15Z" stroke="#111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                Restrict further entries? &nbsp;
                {/* <span className='learn-more-restrict'>Learn more</span> */}
                <FormGroup className="custom-switch">
                 <Switch  onChange={()=>setIsRestricted(!isRestricted)} checked={isRestricted}/>
                </FormGroup>
                </div>
                <div className="info-alert-tagline g-m-i-a-t">
                    {INFO_ICON_OUTLINED()} Reserves are used against future deficits - The final balance will release at your discretion.
                </div>

            </DialogContent>
            <DialogActions className='Milestone-MuiDialogActions-root'>
                <Button
                    disabled={loading}
                    onClick={onClose}
                    className={classnames('ra-confirm cancel-ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    {ButtonOneText ? ButtonOneText : 'Cancel'}
                </Button>
                <Button
                    disabled={loading}
                    onClick={()=>handleConfirm(false)}
                    className={classnames('ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                    autoFocus
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    {buttonTwoText ? buttonTwoText : 'Approve'}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

const PREFIX = 'RaConfirm';

export const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(
                theme.palette.error.main,
                NUMBER.ZERO_POINT_ONE_TWO
            ),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
