import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import {  FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import _ from 'lodash';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import { useIdentityContext } from '../components/identity';

const CustomDivisionFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag}=props;
    const [search,setSearch]=useState('');
    const divisionContext = useContext(CustomFilterContext);
    const [divisionList,setDivisionList]=useState([]);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    const [totalDivisions,setTotalDivision]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.division);
    const [divisionListName,setDivisionListName]=useState({name:'',ids:prevIds[NUMBER.ZERO]?.selected_ids ? prevIds[NUMBER.ZERO].selected_ids:[]});
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [codeValues,setCodeValue]=useState(prevIds.length ? prevIds[0].selected_obj:{});
    const identity = useIdentityContext();

    useEffect(()=>{
        if(divisionContext.allDivision.length > NUMBER.ZERO){
            setDivisionList(divisionContext.allDivision);
        }else{
            getDivisionList(NUMBER.TEN,'');
        }
    },[])
    const getDivisionList = (perCount,text)=>{
        setSearch(text);
        const filterBasedOnManager = 
        identity.user_type === 'manager' && identity?.allowed_divisions?.length > 0 
        && { id: { _in: identity.allowed_divisions } };
        let query = text ? { name: { _ilike: text } , type: { _eq: 'division' } , ...filterBasedOnManager}:{  type: { _eq: 'division' } ,  ...filterBasedOnManager};
        setLoading(true);
        dataProvider.getList('Branch__DropdownList', {
            pagination: { page: NUMBER.ONE, perPage: perCount },
            sort: { field: 'id', order: 'DESC' },
            filter: query,
        }).then(res=>{
             setDivisionList(res.data);
             setLoading(false);
             setCurrentCount(perCount);
             setTotalDivision(res.total);
            !text && divisionContext.setAllDivision(res.data);
        })
    }

    useEffect(()=>{},[divisionListName,divisionList])
    const applyFilter = ()=>{
        let len = divisionListName.ids.length;
        let ct = len > NUMBER.ONE ? divisionListName.ids.length - NUMBER.ONE :NUMBER.ZERO;
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.division).length > NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.division);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.division,name:divisionListName.name,count:ct,selected_ids:divisionListName.ids,selected_obj:codeValues}]);
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.division,name:divisionListName.name,count:ct,selected_ids:divisionListName.ids,selected_obj:codeValues}]);
        }
        closeSubFilter()
    }

    const toggleDivision = (isChecked:any,codeId:any,code:string)=>{
        let ids = divisionListName.ids.filter(el=>el !== codeId);
         if(isChecked){
            codeValues[codeId]=code;
            setCodeValue(codeValues)
            setDivisionListName({name:code,ids:[...ids,codeId]});
         }else{
            setDivisionListName({name:ids.length ? codeValues[ids[NUMBER.ZERO]]:'',ids:ids});
         }
    }

    const isPrevChecked = (empId)=> {
      const values = searchTag ? searchTag?.filter(element=>element.tag === FILTER_OPTIONS.division):[];
      return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(empId) : false;
    }
    const handleLoadMore = (element)=>{
        if(element.scrollTop + element.offsetHeight >= element.scrollHeight && !loading && (divisionList.length < totalDivisions || !totalDivisions) ){
            getDivisionList(currentCount+NUMBER.TEN,search);
        }
    }

    return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search Division"
                    id="division-search"
                    name="division-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{getDivisionList(currentCount || NUMBER.TEN,e.target.value)}}
                />
               {search && <Button className='clear-search-filter'>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { divisionList.length > NUMBER.ZERO   ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>handleLoadMore(element.currentTarget)}>
                    {' '}
                    {divisionList.map(emp => {
                        return (
                            <MenuItem key={emp.id}>
                                <FormControlLabel onChange={(e,checked)=>toggleDivision(checked,emp?.id,emp?.name)}
                                    control={<Checkbox  defaultChecked={isPrevChecked(emp?.id)} />}
                                    label={emp?.name}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
              {(!divisionList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !divisionListName.ids.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomDivisionFilter);