import { useState ,useEffect, useContext, useCallback} from "react";
import { useDataProvider } from "react-admin"
import {
    Button,
    Dialog,
    DialogContent,
    MenuItem,
    Stack,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogTitle,
    IconButton,
    Typography,
    FormGroup,
    Switch
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { COMPARISON_OPTIONS, USER_TYPE,FILTER_OPTIONS, SEARCH_PLACEHOLDER } from "../../utils/Constants/ConstantData";
import { TagPicker } from 'rsuite';
import { NUMBER } from "../../utils/Constants/MagicNumber";
import _ from 'lodash';
import { DateRangeInputFilter } from "../../components/fields/DateRangeInputFilter";
import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { Input } from "@mui/material";
import { getCustomFilterQuery } from "../../components/fields";
import { VALIDATION } from "../../utils/Constants/ValidationMessages";
import { convertToFloatHours } from "../payrolls/weeklyEntries";
import { convertNumToTime } from "../../utils/formatter";
import { Form } from 'react-final-form';
import { CustomFilterContext } from "../../layout/CustomFilterContext";
import { CLOSE_ICON } from "../../utils/Constants/ClassName";
import { InfoLabel } from "../../components/fields/InfoLabel";
import { PROPAY_CONSTANT } from "../../constants";
import { getPropaySearchFilterQuery } from "./propay_utils";
import { CustomConditionInput, CustomSingleDateFilterMobile } from "../protivEmployeeBonusStatement/MobileViewFiter";
import { Badge } from "@mui/material";
import { getFilterCount } from "../../utils/Constants/CommonFunctions";
import debounce from 'lodash/debounce';

export const PROPAY_OPTIONS = [
    { id: 'open', name: 'Open' },
    { id: 'pending', name: 'Pending' },
    { id: 'started', name: 'Started' },
    { id: 'completed', name: 'Completed' },
    { id: 'approved', name: 'Approved' },
    { id: 'paid', name: 'Closed' },
    { id: 'cancelled', name: 'Cancelled' },
];
const PropayFilterMobile = ({showLimitedFilter=false,setMobilePropayFilter,identity,mobilePropayFilter,connectorList=[]})=>{
    const [open,setOpen]= useState(false);
    const propayContext = useContext(CustomFilterContext);
    const [search,setSearch] = useState('');
    const [customFilter,setCustomFilter] = useState([]);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [isPropayNoBonusEnabled,setIsPropayNoBonusEnabled] = useState(false);
    const [propayFilterCount,setPropayFilterCount]=useState(NUMBER.ZERO)
    const defaultFilter = [{tag:FILTER_OPTIONS.status,selected_ids:['open']}];

    useEffect(()=>{
        if(!showLimitedFilter){
        const prevAppliedFilters  = localStorage.getItem(PROPAY_CONSTANT.mobileAppliedFilter);
        const getPrevSearch = localStorage.getItem(PROPAY_CONSTANT.search);
        const prevSearch = getPrevSearch || '';
        if(prevAppliedFilters === null){
            setMobilePropayFilter(prevSearch ? getPropaySearchFilterQuery(prevSearch):{});
        }else{
            const parsedPrevFilters = JSON.parse(prevAppliedFilters);
            setCustomFilter(parsedPrevFilters);
            const prvQyery = getCustomFilterQuery(parsedPrevFilters,connectorList,showLimitedFilter , identity);
            setMobilePropayFilter(prevSearch ? {...prvQyery,...getPropaySearchFilterQuery(prevSearch)}:prvQyery);
            getNoBonusState(parsedPrevFilters);
        }
        setSearch(prevSearch);
    }
    },[])

const getNoBonusState = (tagList=[])=>{
    const prevPropayNoBonus = tagList?.filter(item=>item.tag === FILTER_OPTIONS.propay_no_bonus);
    setIsPropayNoBonusEnabled(prevPropayNoBonus?.length > NUMBER.ZERO)
}
  const applyFilter =(searchText:string)=>{
    const filteredData = customFilter.filter(item=>item.selected_ids !== null && (Array.isArray(item.selected_ids) ? item.selected_ids?.length > NUMBER.ZERO :item.selected_ids ) );
    let filterQuery = getCustomFilterQuery(filteredData,connectorList,showLimitedFilter , identity);
    setMobilePropayFilter(searchText ? {...filterQuery,...getPropaySearchFilterQuery(searchText)}:filterQuery);
    !showLimitedFilter && localStorage.setItem(PROPAY_CONSTANT.mobileAppliedFilter,JSON.stringify(filteredData));
    setOpen(false);
  }
  const updateStatusValue = (value:string[])=>{
    let update = customFilter.filter(item=>item.tag !== FILTER_OPTIONS.status);
    setCustomFilter(_.concat(update,[{tag:FILTER_OPTIONS.status,selected_ids:value}]));
}
const handleSubmit = (dates:any)=>{
    if(dates._gte || dates._lte){
        !showLimitedFilter && localStorage.setItem(PROPAY_CONSTANT.dateTimeFilter,JSON.stringify(dates))
    }
}

const getStatusDefaultValue = ()=>{
    let statusItem = customFilter.filter(item=>item.tag === FILTER_OPTIONS.status);
    if(statusItem.length > 0){
     return statusItem[0].selected_ids
    }
    return []
}

const handleSearch = (text:string)=>{
    setSearch(text);
    localStorage.setItem(PROPAY_CONSTANT.search,text)
    handleDebounce(text)
}

const handleDebounce = useCallback(debounce((searchText) => {
    setMobilePropayFilter({...mobilePropayFilter,...getPropaySearchFilterQuery(searchText)})
},NUMBER.ONE_THOUSAND), []);

const onToggle = (value)=>{
    setIsPropayNoBonusEnabled(value);
    const updateFilter = customFilter.filter(item=>item.tag !== FILTER_OPTIONS.propay_no_bonus);
    if(value){
        setCustomFilter([...updateFilter,{tag:FILTER_OPTIONS.propay_no_bonus,selected_ids:value}]);
    }else{
        setCustomFilter(updateFilter);
    }
}
const onClose = ()=>{
    setOpen(false);
    const prevAppliedFilters  = localStorage.getItem(PROPAY_CONSTANT.mobileAppliedFilter);
    setCustomFilter(prevAppliedFilters ? JSON.parse(prevAppliedFilters):[])
    getNoBonusState(JSON.parse(prevAppliedFilters))
}
const propayStatusOptions = PROPAY_OPTIONS.filter((item)=>{
    if ((item?.id === 'started' && !identity?.company?.is_started_status) ||
            (item?.id === 'completed' && !identity?.company?.is_completed_status)) {
            return false;
        }
        return true;
  })

  const hasJobTypeEnabled = connectorList.some(item => item?.support_options?.import_entities?.includes("job_type"))
  const hasInvoiceTypeEnabled = connectorList.some(item => item?.service === 'aspire' && item?.status === 'authorized')

  const prevApplied  = JSON.parse(localStorage.getItem(PROPAY_CONSTANT.mobileAppliedFilter));
  const dateFilter = JSON.parse(localStorage.getItem(PROPAY_CONSTANT.dateTimeFilter))

  const resetAll = ()=>{
    if(!showLimitedFilter){
        const prevSearch = localStorage.getItem(PROPAY_CONSTANT.search);
        localStorage.removeItem(PROPAY_CONSTANT.mobileAppliedFilter);
        localStorage.removeItem(PROPAY_CONSTANT.dateTimeFilter);
        setMobilePropayFilter(prevSearch ? getPropaySearchFilterQuery(prevSearch):{})
    }else{
        setMobilePropayFilter({})
    }
    setCustomFilter([]);
    setIsPropayNoBonusEnabled(false)
    setOpen(false);
  }

useEffect(() => {
    if(prevApplied !== null ){
        setPropayFilterCount(prevApplied?.length);
    }else{
        setPropayFilterCount(NUMBER.ZERO);
    }
    dateFilter !== null && setPropayFilterCount(prev=>prev+1)
    
}, [prevApplied,dateFilter]);

    return(
        <>
        <div className="filter-mobile-search-row">
        <div className="propay-filter-search">
            <Input type="text" value={search} onChange={(e)=>handleSearch(e.target.value)} placeholder={SEARCH_PLACEHOLDER.propay} className="propay-search" />
        </div>
        <IconButton className={`filter-mobile-btn ${propayFilterCount > 0 ? 'filter-badge':''}`} onClick={()=>{setOpen(true)}}><FilterAlt color="primary" />
        {(!showLimitedFilter && propayFilterCount > 0) && <Badge className='custom-badge-btn' color='primary' badgeContent={getFilterCount(propayFilterCount)} max={NUMBER.FIVE}></Badge>}
        </IconButton>
        </div>
        <Dialog className={`${isMenuOpen ? 'remove-modal-scroll':''} filter-modal-mobile`} open={open} onClose={()=>onClose()}>
        <DialogTitle sx={{p:2,}}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                            Filters
                                <IconButton color="primary" onClick={()=>onClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                <DialogContent>
                {!showLimitedFilter && <div className="ent-req-stack">{FILTER_OPTIONS.propay_no_bonus} <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
                              <Typography sx={{ fontSize: 10 }}>
                              Toggle will show all jobs with ProPays that are not yet matched.
                              </Typography>
                            </InfoLabel>
                            <FormGroup className="custom-switch">
                                <Switch
                                onChange={(e, val) => onToggle(val)} checked={isPropayNoBonusEnabled}
                                />
                </FormGroup></div>}
                {!showLimitedFilter && <><div className="filter-label-title">Status</div>
                <TagPicker menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" data={propayStatusOptions} onChange={(e)=>updateStatusValue(e)}
                 valueKey={'id'} onOpen={()=>{setMenuOpen(true); document.body.classList.add("tag-picker-body")}} onClose={()=>{setMenuOpen(false); document.body.classList.remove("tag-picker-body")}} labelKey={'name'} style={{ width: 300 }} placeholder='Select Status' defaultValue={getStatusDefaultValue()}/></>}

                <CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.worker} resource={'employees'} filterQuery={{ active: {_eq: false}, _or: {active: {_eq: true}}}}
                label={'name'} value={'id'} renderData={propayContext?.allWorkers} setRenderData={propayContext?.setAllWorkers}/>

                {!showLimitedFilter && <CustomTagInput  setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.manager} resource={'employees'}
                filterQuery={{ user_type: { _in: [USER_TYPE.manager,USER_TYPE.admin] }, active: { _eq: true }}} label={'name'} value={'id'} renderData={propayContext?.allManagers} setRenderData={propayContext?.setAllManagers}/>}

                <CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.propay} resource={'propays'}
                filterQuery={{parent_id: {_is_null: true}}} label={'name'} value={'id'} renderData={propayContext?.allPropays} setRenderData={propayContext?.setAllPropays}/>

                <CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.job} resource={'jobs'}
                filterQuery={{active: { _eq: true }}} label={'display_name'} value={'id'}  renderData={propayContext?.allJobs} setRenderData={propayContext?.setAllJobs}/>

                {hasJobTypeEnabled && <CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.job_type} resource={'JobType__FilterList'}
                                filterQuery={{company_id: { _eq: identity?.company_id }}} label={'name'} value={'id'}  renderData={propayContext?.allJobTypes} setRenderData={propayContext?.setAllJobTypes}/>}

                {hasInvoiceTypeEnabled && <CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.invoice_type} resource={'InvoiceType__allInvoiceTypesFilterList'}
                                filterQuery={{}} label={'name'} value={'id'}  renderData={propayContext?.allInvoiceTypes} setRenderData={propayContext?.setAllInvoiceTypes}/>}

                <CustomSingleDateFilterMobile name={FILTER_OPTIONS.started_on} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
                <CustomSingleDateFilterMobile name={FILTER_OPTIONS.ended_on} customFilter={customFilter} setCustomFilter={setCustomFilter}/>

                {!showLimitedFilter && <><CustomTagInput setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.cost_code} resource={'costcodes'} filterQuery={{active: { _eq: true }}}
                label={'code'} value={'id'} renderData={propayContext?.allCostCodes} setRenderData={propayContext?.setAllCostCodes}/>

                <CustomConditionInput name={FILTER_OPTIONS.budget} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
               {identity?.company?.hours_format === 'by_decimal' ?  <CustomConditionInput name={FILTER_OPTIONS.hours_worked} customFilter={customFilter} setCustomFilter={setCustomFilter}/>:  <CustomHoursCondition name={FILTER_OPTIONS.hours_worked} customFilter={customFilter} setCustomFilter={setCustomFilter}/>}

               {identity?.company?.hours_format === 'by_decimal' ?  <CustomConditionInput name={FILTER_OPTIONS.budget_hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>: <CustomHoursCondition name={FILTER_OPTIONS.budget_hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>}
                 <CustomConditionInput name={FILTER_OPTIONS.variance_amount} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
               {identity?.company?.hours_format === 'by_decimal' ?  <CustomConditionInput name={FILTER_OPTIONS.variance_hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>:  <CustomHoursCondition name={FILTER_OPTIONS.variance_hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>}
                <Form onSubmit={()=>{}}
                render={()=><DateRangeInputFilter
                className="date-input-filter"
                source="from_date"
                alwaysOn
                onChange={handleSubmit}
                pageType={!showLimitedFilter ? 'propay':''}
                />}>
                </Form>
                </>}
                </DialogContent>
                <div className="responsive-apply-filter-div">
                <Button className="responsive-apply-filter-btn reset-a-f-btn" fullWidth size="large"  variant="contained" color="primary" onClick={()=>resetAll()}>Reset All</Button>
                <Button className="responsive-apply-filter-btn" fullWidth size="large" type="submit" variant="contained" color="primary" onClick={()=>applyFilter(search)}>Apply Filter</Button>
                </div>
        </Dialog>
        </>
    )
}

export const CustomTagInput = ({name,resource,filterQuery,label,value,customFilter,setCustomFilter,renderData,setRenderData,setMenuOpen})=>{
    let prevSelectedValue = customFilter.filter(item=>item.tag === name);
    const [defaultValue,setDefaultValue]=useState(prevSelectedValue.length > 0 ? prevSelectedValue[0].selected_ids:[]);
    const dataProvider = useDataProvider();

    useEffect(()=>{
        if(!renderData.length){
            dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND},
                sort: { field: label, order: 'ASC' },
                filter: filterQuery,
            }).then(res=>{
                setRenderData(res.data);
            })
        }
    },[])

    const updateSelectedValue = (value)=>{
        let update = customFilter.filter(item=>item.tag !== name);
         setCustomFilter(_.concat(update,[{tag:name,selected_ids:value}]));
    }

   return (<>
   <div className="filter-label-title">{name}</div>
   <TagPicker menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" placeholder={`Select ${name}`} searchable={true} data={renderData} valueKey={value} labelKey={label} onChange={(e)=>updateSelectedValue(e)}
   defaultValue={defaultValue} onOpen={()=>{setMenuOpen(true); document.body.classList.add("tag-picker-body")}} onClose={()=>{setMenuOpen(false); document.body.classList.remove("tag-picker-body")}}
   style={{ width: 300 }} />
   </>
   )
}


export const CustomHoursCondition = ({name,customFilter,setCustomFilter})=>{
    const inBetween = ['_nin', '_in'];
    let prevInput = customFilter.filter(fl=>fl.tag === name);
    let prevHoursCondition = prevInput.length > 0 ? prevInput[0].value:'';
    const [budgetCondition, setBudgetCondition] = useState(prevHoursCondition);
    const [showSecondInput,setShowSecondInput] = useState(inBetween.includes(prevHoursCondition));
    const prevFirstTime  = inBetween.includes(prevHoursCondition) ? convertNumToTime(prevInput[0].selected_ids[0]).split(':') : prevInput.length ? convertNumToTime(prevInput[0].selected_ids).split(':'):'';
    const prevSecondTime = inBetween.includes(prevHoursCondition) ? convertNumToTime(prevInput[0].selected_ids[1]).split(':') : '';
    const [hoursInput, setHoursInput] = useState(prevFirstTime[0] || '');
    const [otherHoursInput, setOtherHoursInput] = useState(prevSecondTime[NUMBER.ZERO] || '');
    const [minutesInput, setMinutesInput] = useState(prevFirstTime[1] || '');
    const [otherMinutesInput, setOtherMinutesInput] = useState(prevSecondTime[1] || '');
    const [isExpanded,setIsExpanded] = useState(prevInput.length > 0);

    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowSecondInput(inBetween.includes(event.target.value));
    };
    const checkError=(value)=>{
        if (value && parseInt(value) > NUMBER.FIFTY_NINE) {
            return true;
        }else{
            return undefined;
        }
    }
    const handleClear = ()=>{
        let updateFilter = customFilter.filter(fl=>fl.tag !== name);
        setCustomFilter(updateFilter);
        setBudgetCondition('');
        setHoursInput('');
        setOtherHoursInput('');
        setMinutesInput('');
        setOtherMinutesInput('');
        setShowSecondInput(false)
       }

    useEffect(()=>{
        let selectValue: any;
        if (budgetCondition && inBetween.includes(budgetCondition)) {
            selectValue = [convertToFloatHours(hoursInput,minutesInput), convertToFloatHours(otherHoursInput,otherMinutesInput)];
        }else{
           selectValue = convertToFloatHours(hoursInput,minutesInput);
        }
        let update = customFilter.filter(item=>item.tag !== name);
        setCustomFilter(_.concat(update,[{tag:name,value:budgetCondition,selected_ids:selectValue}]));
    },[budgetCondition,hoursInput,otherHoursInput,minutesInput,otherMinutesInput])
    return(<Accordion className="filter-accordion" expanded={isExpanded}  onChange={(_,expanded)=>setIsExpanded(expanded)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="options-content"
            id="options-header"
        >
           {name}
        </AccordionSummary>
        <AccordionDetails id="options-content" aria-labelledby="options-header">
        {(budgetCondition || hoursInput ||otherHoursInput ||minutesInput || otherMinutesInput) && <Button className="clear-budget-value" onClick={()=>handleClear()}>
            {CLOSE_ICON()}
        </Button>}
                <div className="filter-input">
                    <Select
                        className="budget-hour-floating-hide"
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                        onChange={handleChange}
                        fullWidth
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </div>
                <Stack className='hh-mm-select filter-input'>
                <TextField
                    label="HH"
                    variant="outlined"
                    size="small"
                    value={hoursInput}
                    type="number"
                    onChange={e => setHoursInput(e.target.value)}
                />
                <span className='hh-mm-colon'>:</span>
                <TextField
                    label="MM"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={minutesInput}
                    error={checkError(minutesInput)}
                    onChange={e => setMinutesInput(e.target.value)}
                    helperText={checkError(minutesInput) ? VALIDATION.MINUTES_VALIDATION:''}
                />
            </Stack>
            {showSecondInput && (
                <>
                    <div className="filter-input">And</div>
                    <Stack className='hh-mm-select filter-input'>
                <TextField
                    label="HH"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={otherHoursInput}
                    onChange={e => setOtherHoursInput(e.target.value)}
                />
                <span className='hh-mm-colon'>:</span>
                <TextField
                    label="MM"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={otherMinutesInput}
                    error={checkError(otherMinutesInput)}
                    onChange={e => setOtherMinutesInput(e.target.value)}
                    helperText={checkError(otherMinutesInput) ? VALIDATION.MINUTES_VALIDATION:''}
                />
            </Stack>
                </>
            )}
        </AccordionDetails>
    </Accordion>)
}

export default PropayFilterMobile;