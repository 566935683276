import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Button,
    FormGroup,
    Grid,
    IconButton,
    Link,
    Skeleton,
    Stack,
    Switch,
    Tooltip,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { InfoLabel } from '../components/fields/InfoLabel';
import {
    ARROW_UP_RIGHT_ICON,
    BONUS_EARNED_ICON,
    CLOCK_ICON,
    CURRENCY_DOLLAR_ICON,
    DASH_SETTING_ICON,
    DASH_TOGGLE_ICON,
    DRAG_DROP_ICON,
    EYE_VISIBLE_ICON,
    MENU_CHECK_ICON,
    NO_ENOUGH_ICON,
    PROPAY_DONE_ICON,
    REVENUE_PER_HOUR_ICON,
    REVENUE_PRODUCED_ICON,
    USER_CLOCK_ICON,
} from '../utils/Constants/ClassName';
import { CRUD_UPDATE, useDataProvider, useMutation, useUpdate } from 'react-admin';
import { PROPAY_COUNT_FILTER_OPTIONS, handleChangeApiFilter } from './DashboardCountCards';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { capitalizeFirstLetter, formatter } from '../utils/Constants/ConstantData';
import { Carousel, TagPicker } from 'rsuite';
import { ContactsOutlined } from '@mui/icons-material';
import { useIdentityContext } from '../components/identity';
import { LabelAvatars } from '../layout/CardListView';
import { applyRedColor } from '../utils/Constants/CommonFunctions';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { post } from '../resources/onboard/PaymentProvider';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DASHBOARD_LEADER_BOARD_OPTIONS = [
    { label: 'Saved Hours', value: 'Saved Hours', key:'saved_hours' },
    { label: 'Net Saved Hours', value: 'Net Saved Hours', key:'net_saved_hours' },
    { label: 'Hours Worked', value: 'Hours Worked', key:'hours_worked' },
    { label: 'ProPay Done', value: 'ProPay Done', key:'propays_done' },
    { label: 'Bonus Wage', value: 'Bonus Wage', key:'bonus_wage' },
    { label: 'Revenue Per Hour', value: 'Revenue Per Hour', key:'revenue_per_hour' },
    { label: 'Revenue Produced', value: 'Revenue Produced', key:'revenue_produced' },
    { label: 'Bonuses Earned', value: 'Bonuses Earned', key:'bonuses_earned' },
];

const groupByFields = {
    crewLead:'crew_lead',
    manager:'manager',
    worker: 'workers'
}

const groupByLabel = {
    crewLead:'Crew Lead',
    manager:'Manager',
    worker:''
}

const ShowToTagPickerdata = ['Admin', 'Manager', 'Crew Lead', 'Worker'].map(
    item => ({ label: item, value: item })
  );

const renderComponent = (isUpdate,setIsUpdate,value: string, filter?:any, selectValue?:any, handleSelect?:any , showPermission=false, fullScreen=false, refetchCount=0 , leaderboardFilter?: any) => {
    switch (value) {
        case 'Saved Hours':
            return <SavedHours filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter}/>;
        case 'Net Saved Hours':
            return <NetSavedHours filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter} />;
        case 'Bonus Wage':
            return <BonusWage filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter}/>;
        case 'ProPay Done':
            return <ProPayDone filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount}  leaderboardFilter={leaderboardFilter}/>;
        case 'Revenue Per Hour':
            return <RevenuePerHour isUpdate={isUpdate} setIsUpdate={setIsUpdate} filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter} />;
        case 'Hours Worked':
            return <HoursWorked filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter} />;
        case 'Bonuses Earned':
            return <BonusesEarned filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter}/>;
        case 'Revenue Produced':
            return <RevenueProduced isUpdate={isUpdate} setIsUpdate={setIsUpdate} filter={filter} selectValue={selectValue} handleSelect={handleSelect} showPermission={showPermission} fullScreen={fullScreen} refetchCount={refetchCount}  leaderboardFilter={leaderboardFilter}/>;
        default:
            return <></>;
    }
};

const DashboardLeaderboard = (props: any) => {
    const { companyData, fullScreen, refetchCount , leaderboardFilter } = props;
    const identity = useIdentityContext();
    const [filterval, setFilterval] = React.useState('All');
    const [showLeaderboard, setShowLeaderboard] = React.useState(true);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [filter, setFilter] = React.useState({
        parent_id: { _is_null: true },
    });
    const [dashboardLeaderBoard, setDashboardLeaderBoard] = React.useState([]);
    const [LeaderboardSelect, setLeaderboardSelect] = React.useState<any>({});

    React.useEffect(()=>{
        if(companyData?.leaderboards_config){
            if(fullScreen){
                const item = sessionStorage.getItem('leaderboard_select');
                if(item){
                    setLeaderboardSelect(JSON.parse(item))
                }else{
                    sessionStorage.setItem('leaderboard_select', companyData?.leaderboards_config)
                    setLeaderboardSelect(JSON.parse(companyData?.leaderboards_config));
                }
            }else{
                setLeaderboardSelect(JSON.parse(companyData?.leaderboards_config));
            }
        }
    },[companyData, fullScreen]);

    React.useEffect(()=>{
        const savedFilters = JSON.parse(fullScreen ? localStorage.getItem('Dashboard_Filters') : localStorage.getItem('Dashboard_Filters'))
        if(savedFilters){
            handleChangeApiFilter({target:{value:savedFilters?.leaderBoard ? savedFilters?.leaderBoard : 'All'}}, {props:{value:savedFilters?.leaderBoard ? savedFilters?.leaderBoard :'All'}},setFilterval, setFilter, 'leaderBoard')
        }else{
            handleChangeApiFilter({target:{value:'All'}}, {props:{value:'All'}},setFilterval, setFilter, 'leaderBoard')
        }
    },[])

    React.useEffect(() => {
        const dashboardIndicators = fullScreen ? sessionStorage?.getItem('dashboard_leaderboard') : localStorage?.getItem('dashboard_leaderboard');
        if(!dashboardIndicators){
            const visibleoptions = DASHBOARD_LEADER_BOARD_OPTIONS?.map((item, index)=>({...item, is_visible: true, just_saved:false}));
            fullScreen ? sessionStorage.setItem('dashboard_leaderboard', JSON.stringify(visibleoptions)) : localStorage.setItem('dashboard_leaderboard', JSON.stringify(visibleoptions));
            setDashboardLeaderBoard(visibleoptions);
        }else{
            setDashboardLeaderBoard(JSON.parse(dashboardIndicators));
        }
    }, []);

    const [Leaderboardopen, LeaderboardsetOpen] = React.useState(false);
    const LeaderboardhandleClickOpen = () => {
        LeaderboardsetOpen(true);
    };
    const LeaderboardhandleClose = () => {
        LeaderboardsetOpen(false);
    };

    const handleClassAdd = () => {
        const leaderboradGrids = dashboardLeaderBoard?.filter((item)=>(LeaderboardSelect[item?.key]?.show_to?.includes('All') || LeaderboardSelect[item?.key]?.show_to?.includes(capitalizeFirstLetter(identity?.user_type?.toString())) || identity?.user_type ==='admin' || identity?.employee_id?.toString() === 'false') && item?.is_visible)
        switch(leaderboradGrids?.length){
            case 1:{
                return `lb-four-item lb-single-item`
            }
            case 2:{
                return `lb-four-item lb-two-item`
            }
            case 3:{
                return `lb-four-item lb-three-item`
            }
            default:{
                return `lb-four-item`
            }
        }
    }

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );    

    return (
        <>
            <div className="dashboard-box-panel leader-board-sec">
                <div className="leader-board-head">
                    <div className="dashboard-sub-head">
                        {/* {DRAG_DROP_ICON()} */}
                        Leaderboard
                    </div>
                    <div className='leader-board-hd-rt'>
                    <FormControl className="overview-select overview--small-select">
                            <Select
                                value={filterval}
                                onChange={(e, name)=>handleChangeApiFilter(e, name,setFilterval, setFilter, 'leaderBoard')}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {PROPAY_COUNT_FILTER_OPTIONS?.map(item => (
                                    <MenuItem
                                        value={item?.value}
                                        className={`${
                                            item?.value === filterval
                                                ? 'menu-active-selected'
                                                : ''
                                        }`}
                                    >
                                        {item?.label}{' '}
                                        {item?.value === filterval
                                            ? MENU_CHECK_ICON()
                                            : ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    <div className="dash-overview-setting" onClick={LeaderboardhandleClickOpen}>
                        {DASH_SETTING_ICON()}
                    </div>
                    <Button
                    className={`dash-toggle ${
                        showLeaderboard ? '' : 'off-dash-toggle'
                    }`}
                     onClick={() => setShowLeaderboard(!showLeaderboard)} disableRipple>
                        {DASH_TOGGLE_ICON()}
                    </Button>
                    </div>

                </div>

                <div className={`${showLeaderboard ? 'leader-board-row' : 'leader-board-row dash-hide-grid'}`}>

                {!isSmall && dashboardLeaderBoard?.length ? dashboardLeaderBoard?.filter((item) => LeaderboardSelect[item?.key]?.show_to?.includes('All') || LeaderboardSelect[item?.key]?.show_to?.includes(capitalizeFirstLetter(identity?.user_type?.toString())) || (identity?.user_type === 'admin' || identity?.employee_id?.toString() === 'false'))?.map((Leaderboard) => {
                    return (
                        <>
                            {Leaderboard?.is_visible && <div className={handleClassAdd()}>
                                {renderComponent(isUpdate,setIsUpdate,Leaderboard?.value, filter, LeaderboardSelect,'', false, fullScreen, refetchCount , leaderboardFilter)}
                            </div>}
                        </>
                    )
                }
                ) : ''}

                {isSmall ? <CustomCarousel items={dashboardLeaderBoard} LeaderboardSelect={LeaderboardSelect} dashboardLeaderBoard={dashboardLeaderBoard} isUpdate={isUpdate} setIsUpdate={setIsUpdate} filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} leaderboardFilter={leaderboardFilter} />:''}

                </div>
            </div>
            {Leaderboardopen && <DashboardLeaderboardSetting fullScreen={fullScreen} LeaderboardSelect={LeaderboardSelect} setLeaderboardSelect={setLeaderboardSelect} open={Leaderboardopen} handleClose={LeaderboardhandleClose} companyData={companyData} allLeaderBoards={dashboardLeaderBoard} setDashboardLeaderBoard={setDashboardLeaderBoard} leaderboardFilter={leaderboardFilter}  />}
            </>
    );
};

export default DashboardLeaderboard;

const CustomCarousel = ({ items, dashboardLeaderBoard, LeaderboardSelect, isUpdate, setIsUpdate, filter, fullScreen, refetchCount , leaderboardFilter }) => {
    const identity = useIdentityContext();
    const data = dashboardLeaderBoard?.filter((item) => item?.is_visible && (LeaderboardSelect[item?.key]?.show_to?.includes('All') || LeaderboardSelect[item?.key]?.show_to?.includes(capitalizeFirstLetter(identity?.user_type?.toString())) || (identity?.user_type === 'admin' || identity?.employee_id?.toString() === 'false')));

    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
        // adaptiveHeight: true,
    };

    return (
        <div className="slider-container">
        <Slider {...settings}>
            {dashboardLeaderBoard?.length ? data?.map((Leaderboard, index) => {
                         return (
                            <>
                                {renderComponent(isUpdate, setIsUpdate, Leaderboard?.value, filter, LeaderboardSelect, '', false, fullScreen, refetchCount , leaderboardFilter)}
                            </>
                         )
                     }
            ) : ''}
        </Slider>
        </div>
    );
};

const DashboardLeaderboardSetting = ({ fullScreen, open, handleClose, allLeaderBoards, setDashboardLeaderBoard, companyData, LeaderboardSelect, setLeaderboardSelect , leaderboardFilter  }) => {
    const [allLeaderboard, setAllLeaderboard] = React.useState([]);
    const identity = useIdentityContext();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [update] = useUpdate();
    React.useEffect(() => {
        if (allLeaderBoards?.length) {
            setAllLeaderboard(allLeaderBoards);
        }
    }, [allLeaderBoards, allLeaderBoards?.length])

    const handleChange = (index) => (event) => {
        const newIndicators = allLeaderboard?.map((indicator, i) => {
            if (i === index) {
                return { ...indicator, is_visible: event.target.checked };
            }
            return indicator;
        });
        setAllLeaderboard(newIndicators);
    };

    const handleClickSave = () => {
        if(fullScreen){
            sessionStorage.setItem('dashboard_leaderboard', JSON.stringify(allLeaderboard));
            sessionStorage.setItem('leaderboard_select', JSON.stringify(LeaderboardSelect));
        }else{
            localStorage.setItem('dashboard_leaderboard', JSON.stringify(allLeaderboard));
        }

        setDashboardLeaderBoard(allLeaderboard);
        !fullScreen && update(
            'company',
            { id: companyData?.id, data: {id:companyData?.id, leaderboards_config: JSON.stringify(LeaderboardSelect)}, previousData: {id:companyData?.id, leaderboards_config: JSON.stringify(companyData?.leaderboards_config)} },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                },
                onError: (error: any) => {
                },
            }
        );
        handleClose();
    };

    const LeaderboardhandleChange = (val:any,key?:string) => {
        if(key){
            setLeaderboardSelect((prev)=>({...prev, [key]:val}));
        }else{
            setLeaderboardSelect((prev)=>({visible_count:prev?.visible_count, ...val}));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            className="create-propay-modal dash-indicator-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    width="100%"
                >
                    <IconButton
                        sx={{ top: -2, padding: 0 }}
                        color="primary"
                        aria-label="Leaderboard"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent className="dash-dialog-cont">
                <div className="indicator-heading">Leaderboard</div>
                <div className="indicator-paragraph">
                    Toggle off one tile to activate another.
                </div>
                {(identity?.user_type === 'admin' || identity?.employee_id?.toString() === 'false') && <div className="indicator-paragraph">
                    <strong className="indicator-p-strong">
                        Visible Options:
                    </strong>{' '}
                    Number of people on the list
                    <FormControl className="ld-visible-select">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={LeaderboardSelect?.visible_count || 2}
                            onChange={(e)=>LeaderboardhandleChange(e?.target?.value,'visible_count')}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            size="small"
                        >
                            {
                                Array.from({ length: 19 }, (_, i) => i + 2)?.map(option => (
                                    <MenuItem key={option} value={option?.toString()}>{option}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>}
                <Grid container spacing={2} className="indicators-flex">
                        {allLeaderboard?.length ? allLeaderboard?.filter((item)=>LeaderboardSelect[item?.key]?.show_to?.includes('All') || LeaderboardSelect[item?.key]?.show_to?.includes(capitalizeFirstLetter(identity?.user_type?.toString())) || identity?.user_type ==='admin' || identity?.employee_id?.toString() === 'false')?.map((item, index) => (
                            <Grid item xs={12} sm={4} md={3}>
                            <div className={`indicators-flex-item ${item?.is_visible ? 'active-ind-flex-item' : ''}`}>
                                <div className="indicators-flex-head">
                                    <div className="eye-visible-item">
                                        {EYE_VISIBLE_ICON()} Visible
                                    </div>
                                    <FormGroup className="custom-switch custom-switch-yes">
                                        <Switch
                                            checked={item?.is_visible}
                                            onChange={handleChange(index)}
                                            disabled={item?.is_visible && allLeaderboard?.filter(indicator => indicator?.is_visible)?.length === 1}
                                        />
                                    </FormGroup>
                                </div>
                                {renderComponent(isUpdate,setIsUpdate,item?.value, null, LeaderboardSelect, LeaderboardhandleChange, (identity?.user_type ==='admin' || identity?.employee_id?.toString() === 'false'), fullScreen, 1 , leaderboardFilter)}

                            </div>
                            </Grid>
                        )) : <></>}

                </Grid>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button
                    className="cancel-button"
                    onClick={()=>
                        {
                            handleClose()
                            setLeaderboardSelect(fullScreen ? JSON.parse(sessionStorage.getItem('leaderboard_select')) : JSON.parse(companyData?.leaderboards_config))
                        }}
                >
                    Cancel
                </Button>
                <LoadingButton className="update-button" onClick={handleClickSave}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

const SavedHours = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [view, setView] = React.useState(selectValue?.saved_hours?.group_by);
    const [groupedData, setGroupedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
        if(selectValue?.saved_hours?.group_by){
            setView(selectValue?.saved_hours?.group_by)
        }
    },[selectValue?.saved_hours?.group_by]);


    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    group_by: groupByFields[view] ? groupByFields[view] : '', start_date:filter?.start_date?._gte, end_date:filter?.to_date?._lte,
                    ...leaderboardFilter
                };
                const resp = await (post('/api/leaderboard/saved_hours', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({savedHours:{data:resp?.saved_hours_list, total:resp?.saved_hours_list?.length}});
                    setLoading(false);
                } else {
                    setResponse({savedHours:{data:[], total:0}});
                    setLoading(false);
                }
              } catch (error) {
                setResponse({savedHours:{data:[], total:0}})
                setLoading(false);
              }
        }
        if((fullScreen && refetchCount ===1 && view) || (!fullScreen && view)){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, view , leaderboardFilter]);

    const LeaderboardhandleChange = (key:string, val) => {
        const config = { ...selectValue, [key]: { ...selectValue[key], group_by: val } }
        handleSelect(config)
        setView(val);
    };

    React.useEffect(() => {
            const modifiedData = response?.savedHours?.data?.slice(0, selectValue?.visible_count || 2);
            setGroupedData(modifiedData);
    }, [response?.savedHours?.data, selectValue?.visible_count]);

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
                Saved Hours
                <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    The difference in hours between estimated and actual job time, only for jobs under budget.
                    </Typography>
                </InfoLabel>
            </div>
            {showPermission ?
            <>
            <div className="group-show-select">
                <div className="group-show-label">
                    Group by:
                </div>
                <FormControl
                    className="ld-visible-select"
                    fullWidth
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={view}
                        onChange={
                            (e)=>LeaderboardhandleChange('saved_hours', e?.target?.value)
                        }
                        displayEmpty
                        inputProps={{
                            'aria-label':
                                'Without label',
                        }}
                        size="small"
                    >
                        <MenuItem value='manager'>
                            Manager
                        </MenuItem>
                        <MenuItem value='crewLead'>
                            Crew Lead
                        </MenuItem>
                        <MenuItem value='worker'>
                            None
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!fullScreen ? <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'saved_hours'}
                />
            </div> : <></>}

            </> :
            groupByLabel[view] ?
            <div className="group-show-select">
                <div className="group-show-label group-by-label">
                    <strong>Grouped by:</strong> {groupByLabel[view]}
                </div>
            </div>
            : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                        {/* Repeat this div */}
                        {
                        !loading ?
                        (groupedData?.length ? groupedData?.map((item, index) => {
                            return(
                            <div className="lb-list-li no-translate">
                                <div className="lb-list-no">
                                    #{index<9 ? `0${index+1}` : index+1}
                                </div>
                                <div className="lb-list-user">
                                    <LabelAvatars name={item?.name?.toString()}/>
                                    <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={`lb-list-hour ${applyRedColor(Number(item?.value) < 0)}`}>
                                    {item?.value} h{' '}
                                </div>
                            </div>
                        )}):
                        <div className='lb-no-result'>
                            {CLOCK_ICON()}
                            There is no saved hours data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
        )
}

const ShowToTagPicker = (props) => {
    const { selectedTags, setSelectedTags, fieldName } = props

    const handleChange = (newTags) => {
        if (newTags.includes("Admin")) {
            setSelectedTags({
                ...selectedTags,
                [fieldName]: { ...selectedTags[fieldName], show_to: newTags.filter(tag => tag !== "All") }
            });
        } else {
            return;
        }
    };

    return (
        <>
            <div className="group-show-label">Make available to:</div>
            <TagPicker
                className='show-to-tag-picker'
                size="md"
                onClean={()=>{}}
                menuClassName='show-to-tag-picker-menu'
                data={ShowToTagPickerdata}
                block
                value={selectedTags[fieldName]?.show_to || []}
                onChange={handleChange}
            />
        </>
    );
}

const NetSavedHours = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [view, setView] = React.useState(selectValue?.saved_hours?.group_by);
    const [groupedData, setGroupedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
        if(selectValue?.net_saved_hours?.group_by){
            setView(selectValue?.net_saved_hours?.group_by)
        }
    },[selectValue?.net_saved_hours?.group_by]);


    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    group_by: groupByFields[view] ? groupByFields[view] : '', start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte ,
                    ...leaderboardFilter,

                };
                const resp = await (post('/api/leaderboard/net_saved_hours', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({ savedHours: { data: resp?.net_saved_hours_list, total: resp?.net_saved_hours_list?.length } });
                    setLoading(false);
                } else {
                    setResponse({ savedHours: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setResponse({ savedHours: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if ((fullScreen && refetchCount === 1 && view) || (!fullScreen && view)) {
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, view , leaderboardFilter]);

    const LeaderboardhandleChange = (key:string, val) => {
        const config = { ...selectValue, [key]: { ...selectValue[key], group_by: val } }
        handleSelect(config)
        setView(val);
    };

    React.useEffect(() => {
            const modifiedData = response?.savedHours?.data?.slice(0, selectValue?.visible_count || 2);
            setGroupedData(modifiedData);
    }, [response?.savedHours?.data, selectValue?.visible_count]);

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
                Net Saved Hours
                <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    The difference in hours between estimated and actual job time for jobs under and over budget.
                    </Typography>
                </InfoLabel>
            </div>
            {showPermission ?
            <>
            <div className="group-show-select">
                <div className="group-show-label">
                    Group by:
                </div>
                <FormControl
                    className="ld-visible-select"
                    fullWidth
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={view}
                        onChange={
                            (e)=>LeaderboardhandleChange('net_saved_hours', e?.target?.value)
                        }
                        displayEmpty
                        inputProps={{
                            'aria-label':
                                'Without label',
                        }}
                        size="small"
                    >
                        <MenuItem value='manager'>
                            Manager
                        </MenuItem>
                        <MenuItem value='crewLead'>
                            Crew Lead
                        </MenuItem>
                        <MenuItem value='worker'>
                            None
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!fullScreen ? <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'net_saved_hours'}
                />
            </div> : <></>}
            </> :
            groupByLabel[view] ?
            <div className="group-show-select">
                <div className="group-show-label group-by-label">
                    <strong>Grouped by:</strong> {groupByLabel[view]}
                </div>
            </div>
            : <></>}
            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                        {
                        !loading ?
                        (groupedData?.length ? groupedData?.map((item, index) => {
                        return(
                        <div className="lb-list-li no-translate">
                            <div className="lb-list-no">
                                #{index<9 ? `0${index+1}` : index+1}
                            </div>
                            <div className="lb-list-user">
                                <LabelAvatars name={item?.name}/>
                                <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`lb-list-hour ${applyRedColor(Number(item?.value) < 0)}`}>
                                {item?.value} h{' '}
                            </div>
                        </div>
                         )}):
                         <div className='lb-no-result'>
                            {CLOCK_ICON()}
                            There is no saved hours data to analyze
                        </div>
                         ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>


                </div>
            </div>

        </div>
        </div>
    )
}

const BonusWage = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(true);
    const [response, setResponse] = React.useState({pendingBonus:{data:[],total:NUMBER.ZERO}});

    React.useEffect(() => {
        const pendingBonusApiCall = async () => {
            setLoading(true);
            try {
                const payload = {
                    start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte,
                    ...leaderboardFilter,
                };
                const resp = await (post('/api/leaderboard/bonus_wage', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({ pendingBonus: { data: resp?.bonus_wage_list, total: resp?.bonus_wage_list?.length } });
                    setLoading(false);
                } else {
                    setResponse({ pendingBonus: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setResponse({ pendingBonus: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            pendingBonusApiCall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount , leaderboardFilter]);

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
            Bonus Wage
            <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Wages including bonuses earned on workers' pending and closed bonus statements.
                    </Typography>
            </InfoLabel>
            </div>
            {showPermission && !fullScreen ?
            <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'bonus_wage'}
                />
            </div>
            : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {!loading ?
                        (response?.pendingBonus?.total ? response?.pendingBonus?.data?.slice(0, selectValue?.visible_count ? selectValue?.visible_count : 2)?.map((item, index) =>{
                            return (
                        <div className="lb-list-li no-translate">
                            <div className="lb-list-no">
                                #{index<9 ? `0${index+1}` : index+1}
                            </div>
                            <div className="lb-list-user">
                                <LabelAvatars name={item?.name}/>
                                <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="lb-list-hour">
                                {formatter.format(item?.value)}{' '}
                            </div>
                        </div>
                        )}):
                        <div className='lb-no-result lb-no-bonus-wage'>
                            {CURRENCY_DOLLAR_ICON()}
                            There is no bonus wage data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

const ProPayDone = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [view, setView] = React.useState(selectValue?.propays_done?.group_by);
    const [groupedData, setGroupedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
        if(selectValue?.propays_done?.group_by){
            setView(selectValue?.propays_done?.group_by)
        }
    },[selectValue?.propays_done?.group_by]);

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    group_by: groupByFields[view] ? groupByFields[view] : '', start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte ,
                    ...leaderboardFilter
                };
                const resp = await (post('/api/leaderboard/propay_done', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({ savedHours: { data: resp?.propay_done_list, total: resp?.propay_done_list?.length } });
                    setLoading(false);
                } else {
                    setResponse({ savedHours: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setResponse({ savedHours: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if ((fullScreen && refetchCount === 1 && view) || (!fullScreen && view)) {
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, view , leaderboardFilter]);

    const LeaderboardhandleChange = (key:string, val) => {
        const config = { ...selectValue, [key]: { ...selectValue[key], group_by: val } }
        handleSelect(config)
        setView(val);
    };

    React.useEffect(() => {
            const modifiedData = response?.savedHours?.data?.slice(0, selectValue?.visible_count || 2);
            setGroupedData(modifiedData);
    }, [response?.savedHours?.data, selectValue?.visible_count]);

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
                ProPays Done
            <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Count of all ProPays approved or Closed.
                    </Typography>
            </InfoLabel>
            </div>
            {showPermission ?
            <>
            <div className="group-show-select">
                <div className="group-show-label">
                    Group by:
                </div>
                <FormControl
                    className="ld-visible-select"
                    fullWidth
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={view}
                        onChange={
                            (e)=>LeaderboardhandleChange('propays_done', e?.target?.value)
                        }
                        displayEmpty
                        inputProps={{
                            'aria-label':
                                'Without label',
                        }}
                        size="small"
                    >
                        <MenuItem value='manager'>
                            Manager
                        </MenuItem>
                        <MenuItem value='crewLead'>
                            Crew Lead
                        </MenuItem>
                        <MenuItem value='worker'>
                            None
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!fullScreen ? <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'propays_done'}
                />
            </div> : <></>}
            </> :
            groupByLabel[view] ?
            <div className="group-show-select">
                <div className="group-show-label group-by-label">
                    <strong>Grouped by:</strong> {groupByLabel[view]}
                </div>
            </div>
            : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {
                        !loading ?
                        (groupedData?.length ? groupedData?.map((item, index) => {
                            return(
                        <div className="lb-list-li no-translate">
                            <div className="lb-list-no">
                                #{index<9 ? `0${index+1}` : index+1}
                            </div>
                            <div className="lb-list-user">
                                <LabelAvatars name={item?.name}/>
                                <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="lb-list-hour">
                                {item?.value}{' '}
                            </div>
                        </div>
                        )}):
                        <div className='lb-no-result'>
                            {PROPAY_DONE_ICON()}
                            There is no ProPay closed to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>

        </div>
        </div>
    )
}

const HoursWorked = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.ZERO}});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte,
                    ...leaderboardFilter,
                };
                const resp = await (post('/api/leaderboard/worked_hours', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({ savedHours: { data: resp?.worked_hours_list, total: resp?.worked_hours_list?.length } });
                    setLoading(false);
                } else {
                    setResponse({ savedHours: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setResponse({ savedHours: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount , leaderboardFilter]);

  const workerTotalHours = response?.savedHours?.total ? response?.savedHours?.data : [];

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
                Hours Worked
                <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    The sum of all hours recorded from attendance data by a user.
                    </Typography>
                </InfoLabel>
            </div>
            {showPermission && !fullScreen ?
            <>
            <div className="group-show-select">
            <ShowToTagPicker
            selectedTags={selectValue}
            setSelectedTags={handleSelect}
            fieldName={'hours_worked'}
            />
            </div>
            </> : <></>}
            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {
                        !loading ?
                        (workerTotalHours?.length ? workerTotalHours?.slice(0, selectValue?.visible_count ? selectValue?.visible_count : 2)?.map((item, index) => {
                            return(
                        <div className="lb-list-li no-translate">
                            <div className="lb-list-no">
                                #{index<9 ? `0${index+1}` : index+1}
                            </div>
                            <div className="lb-list-user">
                                <LabelAvatars name={item?.name}/>
                                <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="lb-list-hour">
                                {item?.value} h{' '}
                            </div>
                        </div>
                        )}):
                        <div className='lb-no-result'>
                            {USER_CLOCK_ICON()}
                            There is no hours worked data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

const BonusesEarned = ({ filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(true);
    const [response, setResponse] = React.useState({pendingBonus:{data:[],total:NUMBER.ZERO}});

    React.useEffect(() => {
        const pendingBonusApiCall = async () => {
            setLoading(true);
            try {
                const payload = {
                    start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte,
                    ...leaderboardFilter,
                };
                const resp = await (post('/api/leaderboard/bonus_earned', payload)) as any;
                if (resp?.status === 'success') {
                    setResponse({ pendingBonus: { data: resp?.bonus_earned_list, total: resp?.bonus_earned_list?.length } });
                    setLoading(false);
                } else {
                    setResponse({ pendingBonus: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setResponse({ pendingBonus: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            pendingBonusApiCall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount , leaderboardFilter]);

    return (
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
                Bonuses Earned
                <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Total bonuses earned on workers' pending and closed bonus statements.
                    </Typography>
                </InfoLabel>
            </div>
            {showPermission && !fullScreen ?
            <>
            <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'bonuses_earned'}
                 />
            </div>
            </> : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {
                        !loading ?
                        (response?.pendingBonus?.total ? response?.pendingBonus?.data?.slice(0, selectValue?.visible_count ? selectValue?.visible_count : 2)?.map((item, index) => {
                            return(
                        <div className="lb-list-li no-translate">
                            <div className="lb-list-no">
                                #{index<9 ? `0${index+1}` : index+1}
                            </div>
                            <div className="lb-list-user">
                                <LabelAvatars name={item?.name}/>
                                <div className="lb-list-user-name">
                                    <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                    <span className="no-translate">
                                    {item?.name}
                                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`lb-list-hour ${applyRedColor(Number(item?.value) < 0)}`}>
                                {formatter.format(item?.value)}{' '}
                            </div>
                        </div>
                        )}):
                        <div className='lb-no-result'>
                            {BONUS_EARNED_ICON()}
                            There is no bonus earned data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

const RevenueProduced = ({ isUpdate, setIsUpdate, filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({RevenueProduced:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [revResponse, setRevResponse] = React.useState({RevenueProduced:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [view, setView] = React.useState(selectValue?.saved_hours?.group_by);
    const [groupedData, setGroupedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const resource = 'Propay__allPropaysRevenueQuery';
    const paidFilter = { parent_id: { _is_null:true }, status: { _in: ['approved', 'paid']}, job_id_obj: {revenue: {_eq: 0}}}

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    group_by: groupByFields[view] ? groupByFields[view] : '', start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte ,
                    ...leaderboardFilter
                };
                const resp = await (post('/api/leaderboard/revenue_produced', payload)) as any;
                if (resp?.status === 'success') {
                    setRevResponse({ RevenueProduced: { data: resp?.revenue_produced_list, total: resp?.revenue_produced_list?.length } });
                    setLoading(false);
                } else {
                    setRevResponse({ RevenueProduced: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setRevResponse({ RevenueProduced: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if ((fullScreen && refetchCount === 1 && view) || (!fullScreen && view)) {
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, view, isUpdate , leaderboardFilter]);

    React.useEffect(()=>{
        if(selectValue?.revenue_produced?.group_by){
            setView(selectValue?.revenue_produced?.group_by)
        }
    },[selectValue?.revenue_produced?.group_by]);

    React.useEffect(() => {
        const RevenueProducedApicall = async () => {
            setLoading(true);
            const RevenueProduced = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, RevenueProduced: { data: RevenueProduced?.data, total: RevenueProduced?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            RevenueProducedApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, isUpdate]);


    React.useEffect(() => {
            const modifiedData = revResponse?.RevenueProduced?.data?.slice(0, selectValue?.visible_count || 2);
            setGroupedData(modifiedData);
    }, [revResponse?.RevenueProduced?.data, selectValue?.visible_count]);

    const LeaderboardhandleChange = (key:string, val) => {
        const config = { ...selectValue, [key]: { ...selectValue[key], group_by: val } }
        handleSelect(config)
        setView(val);
    };

    const contractPriceMissing = response.RevenueProduced.data?.filter((el)=>el?.job_id && !el?.job_id_obj?.revenue);
    const uniqueJobs = response?.RevenueProduced?.data?.reduce((unique, item) => {
        const id = item?.job_id_obj?.id;
        const revenue = item?.job_id_obj?.revenue;
            if (item?.job_id && revenue <= 0 && !unique.some(job => job.id === id)) {
            unique.push({
                id: id,
                name: item?.job_id_obj?.full_name,
                revenue: ''
            });
        }
        return unique;
    }, []);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
        {contractPriceMissing?.length ? <div className='contract-price-alert'>
        {NO_ENOUGH_ICON()} Revenue missing from jobs.
        <Link onClick={handleClickOpen}>Fix it</Link>
        </div> : <></>}
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
            Revenue Produced
            <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Revenue is allocated based on approved and closed ProPays. Each employee gets credit for the revenue according to their hours worked.
                    </Typography>
            </InfoLabel>
            </div>
            {showPermission ?
            <>
            <div className="group-show-select">
                <div className="group-show-label">
                    Group by:
                </div>
                <FormControl
                    className="ld-visible-select"
                    fullWidth
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={view}
                        onChange={
                            (e)=>LeaderboardhandleChange('revenue_produced', e?.target?.value)
                        }
                        displayEmpty
                        inputProps={{
                            'aria-label':
                                'Without label',
                        }}
                        size="small"
                    >
                        <MenuItem value='manager'>
                            Manager
                        </MenuItem>
                        <MenuItem value='crewLead'>
                            Crew Lead
                        </MenuItem>
                        <MenuItem value='worker'>
                            None
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!fullScreen ? <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'revenue_produced'}
                />
            </div> : <></>}
            </> :
            groupByLabel[view] ?
            <div className="group-show-select">
                <div className="group-show-label group-by-label">
                    <strong>Grouped by:</strong> {groupByLabel[view]}
                </div>
            </div>
            : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {
                        !loading ?
                        (groupedData?.length ? groupedData?.map((item, index) => {
                            return(
                            <div className="lb-list-li no-translate">
                                <div className="lb-list-no">
                                    #{index<9 ? `0${index+1}` : index+1}
                                </div>
                                <div className="lb-list-user">
                                    <LabelAvatars name={item?.name}/>
                                    <div className="lb-list-user-name">
                                        <Tooltip id='unique-tooltip' title={item?.name} placement="top" arrow>
                                        <span className="no-translate">
                                        {item?.name}
                                        </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="lb-list-hour">
                                    {formatter.format(item?.value)}
                                </div>
                            </div>
                        )}):
                        <div className='lb-no-result'>
                            {REVENUE_PRODUCED_ICON()}
                            There is no revenue data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
        {open && <FixRevenueDialog open={open} handleClose={handleClose} jobData={uniqueJobs} setRevenueUpdated={setIsUpdate} />}
        </>
    )
}

const RevenuePerHour = ({ isUpdate, setIsUpdate, filter, selectValue, handleSelect, showPermission, fullScreen, refetchCount , leaderboardFilter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({RevenuePerHour:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [revResponse, setRevResponse] = React.useState({RevenuePerHour:{data:[],total:NUMBER.NEGATIVE_ONE}});
    const [view, setView] = React.useState(selectValue?.saved_hours?.group_by);
    const [groupedData, setGroupedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const resource = 'Propay__allPropaysRevenueQuery';
    const paidFilter = { parent_id: { _is_null:true }, status: { _in: ['approved', 'paid'] }, job_id_obj: {revenue: {_eq: 0}}}

    React.useEffect(()=>{
        if(selectValue?.revenue_per_hour?.group_by){
            setView(selectValue?.revenue_per_hour?.group_by)
        }
    },[selectValue?.revenue_per_hour?.group_by]);

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            setLoading(true);
            try {
                const payload = {
                    group_by: groupByFields[view] ? groupByFields[view] : '', start_date: filter?.start_date?._gte, end_date: filter?.to_date?._lte,
                    ...leaderboardFilter,
                };
                const resp = await (post('/api/leaderboard/revenue_per_hour', payload)) as any;
                if (resp?.status === 'success') {
                    setRevResponse({ RevenuePerHour: { data: resp?.revenue_per_hr_list, total: resp?.revenue_per_hr_list?.length } });
                    setLoading(false);
                } else {
                    setRevResponse({ RevenuePerHour: { data: [], total: 0 } });
                    setLoading(false);
                }
            } catch (error) {
                setRevResponse({ RevenuePerHour: { data: [], total: 0 } })
                setLoading(false);
            }
        }
        if ((fullScreen && refetchCount === 1 && view) || (!fullScreen && view)) {
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, view, isUpdate , leaderboardFilter]);

    React.useEffect(() => {
        const RevenuePerHourApicall = async () => {
            setLoading(true);
            const RevenuePerHour = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, RevenuePerHour: { data: RevenuePerHour?.data, total: RevenuePerHour?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            RevenuePerHourApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount, isUpdate]);

    React.useEffect(() => {
            const modifiedData = revResponse?.RevenuePerHour?.data?.slice(0, selectValue?.visible_count || 2);
            setGroupedData(modifiedData);
    }, [revResponse?.RevenuePerHour?.data, selectValue?.visible_count]);

    const LeaderboardhandleChange = (key:string, val) => {
        const config = { ...selectValue, [key]: { ...selectValue[key], group_by: val } }
        handleSelect(config)
        setView(val);
    };

    const contractPriceMissing = response.RevenuePerHour.data?.filter((el)=>el?.job_id && !el?.job_id_obj?.revenue);
    const uniqueJobs = response?.RevenuePerHour?.data?.reduce((unique, item) => {
        const id = item?.job_id_obj?.id;
        const revenue = item?.job_id_obj?.revenue;
            if (item?.job_id && revenue <= 0 && !unique.some(job => job.id === id)) {
            unique.push({
                id: id,
                name: item?.job_id_obj?.full_name,
                revenue: ''
            });
        }
        return unique;
    }, []);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        {contractPriceMissing?.length ? <div className='contract-price-alert'>
        {NO_ENOUGH_ICON()} Revenue missing from jobs.
        <Link onClick={handleClickOpen}>Fix it</Link>
        </div> : <></>}
        <div className="leader-board-item">
        <div className="indicators-bonus-item">
            <div className="indicator-budget-heading">
            Revenue per Hour
            <InfoLabel
                    className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Calculated by dividing total revenue produced by the hours worked.
                    </Typography>
            </InfoLabel>
            </div>
            {showPermission ?
            <>
            <div className="group-show-select">
                <div className="group-show-label">
                    Group by:
                </div>
                <FormControl
                    className="ld-visible-select"
                    fullWidth
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={view}
                        onChange={
                            (e)=>LeaderboardhandleChange('revenue_per_hour', e?.target?.value)
                        }
                        displayEmpty
                        inputProps={{
                            'aria-label':
                                'Without label',
                        }}
                        size="small"
                    >
                        <MenuItem value='manager'>
                            Manager
                        </MenuItem>
                        <MenuItem value='crewLead'>
                            Crew Lead
                        </MenuItem>
                        <MenuItem value='worker'>
                            None
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!fullScreen ? <div className="group-show-select">
                <ShowToTagPicker
                selectedTags={selectValue}
                setSelectedTags={handleSelect}
                fieldName={'revenue_per_hour'}
                />
            </div> : <></>}
            </> :
            groupByLabel[view] ?
            <div className="group-show-select">
                <div className="group-show-label group-by-label">
                    <strong>Grouped by:</strong> {groupByLabel[view]}
                </div>
            </div>
            : <></>}

            <div className="leader-board-row">
                <div className="leader-board-item">
                    <div className="lb-list-ul">
                    {
                        !loading ?
                        (groupedData?.length ? groupedData?.map((item, index) => {
                            return(
                            <div className="lb-list-li no-translate">
                                <div className="lb-list-no">
                                    #{index<9 ? `0${index+1}` : index+1}
                                </div>
                                <div className="lb-list-user">
                                    <LabelAvatars name={item?.name}/>
                                    <div className="lb-list-user-name">
                                        <Tooltip id='unique-tooltip' title={item.name} placement="top" arrow>
                                        <span className="no-translate">
                                        {item.name}
                                        </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="lb-list-hour">
                                    {formatter.format(item?.value)}{' '}
                                </div>
                            </div>
                        )}):
                        <div className='lb-no-result'>
                            {REVENUE_PER_HOUR_ICON()}
                            There is no revenue data to analyze
                        </div>
                        ):
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={200} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
        {open && <FixRevenueDialog open={open} handleClose={handleClose} jobData={uniqueJobs} setRevenueUpdated={setIsUpdate}/>}
        </>
    )
}

const FixRevenueDialog = ({ open, handleClose, jobData = [], setRevenueUpdated }) => {

    const [jobsData, setjobsData] = React.useState([]);

    React.useEffect(() => {
        setjobsData(jobData);
    }, [jobData])

    const [mutate] = useMutation();
    const updateJobRevenue = () => {
        return mutate(
            {
                type: 'update',
                resource: 'jobs',
                payload: { id: jobData.length ? jobData[0]?.id : null, action: 'updateJobRevenue', job_data: JSON.stringify(jobsData) },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    toast.success('Contract price updated Successfully.');
                    handleClose();
                    setRevenueUpdated((prev)=> !prev);
                },
                onFailure: (error) => {
                    toast.error(`Failure ! ${error.message}`);
                },
            }
        )
    }

    const handleChangeRevenue = (id, e) => {
        const inputValue = e.target.value;
        if (inputValue === "" || (/^\d*$/.test(inputValue) && parseInt(inputValue) >= 0)) {
            setjobsData(prev => {
                const index = prev.findIndex(job => job.id === id);
                if (index !== -1) {
                    return [...prev.slice(0, index), {...prev[index], revenue: inputValue === "" ? "" : parseInt(inputValue)}, ...prev.slice(index + 1)];
                }
                return prev;
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="revenue-leaderboard-title"
            className='common-dialog-modal revenue-job-modal'
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    Contract Price Update
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="revenue leaderboard modal"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <TableContainer className="bonuses-table-paper">
                    <Table aria-label="revenue leaderboard table" className='RaDatagrid-table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Job Name</TableCell>
                                <TableCell>Contract Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {jobsData?.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item?.name}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    size='small'
                                                    onChange={(e)=>handleChangeRevenue(item?.id,e)}
                                                    value={item?.revenue}
                                                    defaultValue={null}
                                                    id='percentage-input-goal'
                                                    className='contract-input'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                $
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button onClick={() => handleClose()} className="cancel-button">Cancel</Button>
                <Button className="update-button" onClick={() => updateJobRevenue()}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}