export const RESOURCES = {
    PROPAYS: 'propays',
    PAYROLLS: 'payrolls',
    PERIODS: 'periods',
    COMPANIES: 'companies'
};

export const PROPAY_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    PAID: 'paid',
    CANCELLED: 'cancelled',
};

export const PAYROLL_STATUS = {
    OPEN: 'open',
    PAID: 'paid',
};

export const PERIOD_STATUS = {
    OPEN: 'open',
    CLOSED: 'closed',
};

export const PROPAY_STATUS_LIST = {
    PENDING: 'pending',
    APPROVED: 'approved',
    PAID: 'paid',
    CANCELLED: 'cancelled',
    CLOSED: 'closed',
};
export const BONUS_ACTION ={
    deduction:'deduction',
    bonus:'bonus',
    with_propay:'withPropay',
    no_propay:'noPropay'
}
export const PROPAY_CONSTANT={
    appliedFilter:'propayAppliedFilters',
    mobileAppliedFilter:'propayMobileAppliedFilters',
    search:'propaySearch',
    propayDetailFilter: 'propayDetailAppliedFilters',
    dateTimeFilter:'propayFromDateFilter',
    perPage:'propayPerPage',
    page:'propayPage',
    sort:'propaySort'
}
export const BONUS_BULK_ACTION_API={
    pay_bonus:'/api/propay/bulk_paid_statement',
    mark_pending:'/api/propay/bulk_pending_statement',
    add_deduction:'/api/propay/additional_deduction',
    add_bonus:'/api/propay/additional_bonus'
}
export const GENERAL_INFO={
    soonNotify:'This action may take time. You will be notified soon.'
}
export const TIME_CONSTANTS={
    appliedFilter:'',
    mobileAppliedFilter:'timeAppliedFilterMobile',
    search:'timeSearch',
    dateTimeFilter:'timeFromDateFilter',
    propayDetailTimeFilter: 'propayDetailTimeSearch'
}
export const CUSTOM_PROPAY_STATUS = {
    PENDING: 'Pending',
    STARTED:'Started',
    COMPLETE:'Completed',
    APPROVED: 'Approved',
    CLOSED: 'Closed',
};

export const PROPAY_STATUS_CLASS = {
    pending: 'pending-status',
    started:'started-status',
    completed:'complete-status',
    approved: 'approved-status',
    paid: 'closed-status',
    cancelled:'cancelled-status'
};

export const PROPAY_MUTATION = {
    pending: 'markPendingPropay',
    complete:'markCompleted',
    approved: 'approvePropay',
}
export const PROPAY_CREATE_TAB = {
    required_setting:'Required Settings',
    additional_setting:'Additional Settings'
}
export const EMPLOYEE_ADDITIONAL_SETTING = {
    restrict_access: { heading: 'Restrict access', info: 'Disables user access and notifications. The user will remain accessible to management.' },
    exclude_from_auto_assign_propays: {
        heading: 'Exclude from all ProPays',
        info: 'Automatically remove any bonus earned from ProPays. Users hours will still be added to the ProPay. Can manually change per ProPay.',
    },
    removed_bonus_for_all_propays: {
        heading: 'Remove bonuses from all ProPays',
        info: 'Automatically remove a user from all ProPays. Users can be manually added.',
    },
};

export const TOAST_MESSAGES = {
    autoHideDuration : 4000
}

export const LEADERBOARD_FILTER_OPTIONS={user_status:'User Status'};

export const LEADERBOARD_USER_FILTER_STATUS = [
    { id: 'active', name: 'Active' },
    { id: 'all', name: 'Active and Inactive' }
];